.free-shipping-message {
    font-weight: 700;
    color: $bs-green;
    display: none;

    &.visible {
        display: block;
    }

    .checkout-cart-index & {
        padding: .8rem 1.6rem;
        border: 1px solid $bs-green;
        background-color: rgba($bs-green, .1);
        margin-bottom: 2.4rem;
    }
}
