.amcard-page-product {
    .product-info-main {
        .product-add-form {
            width: 100%;

            .amcard-title {
                font-weight: 700;
                margin: 0.5rem 0;
            }

            .amcard-form-container {
                margin: 0.5rem 0;

                .amcard-carousel-container {

                    .slick-slider {
                        .slick-slide {
                            width: 15rem !important;
                            margin-right: 0.5rem;
                        }
                    }
                }

                .amcard-field-container {
                    .amcard-field-block {
                        margin-bottom: 0.5rem;
                    }

                    .amcard-price-container {
                        display: flex;
                        align-items: center;
                        flex-direction: row;

                        .amcard-field {
                            margin-left: 0;
                            margin-right: 0;
                        }

                        .amcard-label-block {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            margin-right: 0.5rem;

                            &:last-of-type {
                                margin-right: 0;
                            }

                            .amcard-radio {
                                order: -1;
                                margin: 0 0.5rem 0 1.2rem;

                                &:first-of-type {
                                    margin: 0 0.5rem 0 0;
                                }
                            }
                        }
                    }
                }
            }

            .amcard-preview-container {
                .amcard-button {
                    border: none;
                    padding: 1rem;
                    border-radius: 0.5rem;
                    background-color: $bs-red;
                    color: $color-white;
                }
            }
        }
    }
}

.cart-discount {
    display: flex;
    flex-direction: column;

    .cart-summary & {
        padding-left: 5px;
    }
}

.amcard-codes-list {
    clear: both;

    > div {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        box-sizing: border-box;
        margin: 0 0 1rem;

        span[data-bind="text: $data"] {
            background: #f0f0f0;
            height: 3.8rem;
            align-items: center;
            display: flex;
            padding: 0 3rem;
            border-radius: 0.5rem;

            .cart-summary & {
                background-color: transparent;
                padding: 0;
                flex: 1 1 auto;
            }

            &:before {
                font-family: "Font Awesome 5 Pro";
                content: fa-content($fa-var-check);
                color: $bs-green;
                margin-right: 1.5rem;
            }
        }
    }

    .messages {
        .message {
            width: 100%;
        }
    }
}


.amcard-codes-list,
.amcard-field-container {
    .amcardacc-template-container & {
        //max-width: 36.0rem;
    }

    &.-paypal {
        margin: 2.0rem 0;
    }

    .table .action,
    .amcard-check {
        position: relative;
        align-self: flex-start;
        background: none;
        box-shadow: none;
        cursor: pointer;
        border-radius: 3px;
        min-height: 3.8rem;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        color: inherit;
        border: 1px solid #d1d1d1;
        transition: all .1s;

        &:hover {
            box-shadow: 0 .4rem 1.0rem -.9rem #000;
        }
    }

    .amcard-list-btn-container {
        padding: 0 1.0rem;
        display: inline-block;

        &:not(:last-child) {
            border-right: 2px solid #ccc;
        }
    }

    .amcard-check {
        margin-top: 1.0rem;
    }

    .amcard-delete {
        font-size: 0;
        border: 0;
        padding: 0;

        &:after {
            font-size: 1.4rem;
            margin: 0;
        }
    }

    &.-cart {
        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .cart-summary & {
            background-color: transparent;
            padding: 0;
        }

        .amcard-title {
            font-size: inherit;
        }

        .amcard-field-block {
            display: block;

            .amcard-button {
                float: left;
                margin-top: 1rem;
                font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                // overriding firecheckout
                font-weight: 700 !important;
                line-height: 1.15 !important;
                padding: 7px !important;
                border-radius: 3px !important;

                .checkout-payment-method & {
                    width: auto !important;
                    height: auto !important;
                }
            }
        }

        .amcard-check {
            float: right;
            margin-top: 1rem;
            padding: 7px;
            min-height: unset;

            &:after {
                display: none;
            }

            .opc-payment-additional & {
                float: none;
            }
        }
    }
}

.amcard-codes-list {
    .table-wrapper {
        margin: 0;
        box-sizing: border-box;
    }

    .code-items {
        margin: 0;
        padding: 1rem 0 0;

        &,
        li {
            list-style: none;
        }

        li {
            border-top: 1px solid $border-color__base;
            margin-top: 1rem;
            padding-top: 1rem;
        }

        p {
            margin: 0;
        }
    }
}

.amcard-field-block {
    gap: 1.5rem;

    &.-code {
        margin-top: 1.0rem;
    }

    .amcard-button {
        transition: opacity .3s ease-out;

        &:after {
            font-family: "Font Awesome 5 Pro";
            margin-left: 1.5rem;
            font-weight: 100;
            content: fa-content($fa-var-plus);
        }

        &:hover {
            opacity: .9;
        }


    }

    .amcard-button.-account {
        background: #3a77bd;
        color: #fff;
    }
}

.amcard-card-info {
    display: flex;
    margin-bottom: 1.0rem;
    padding: 1.0rem;
    background: #e8f6ff;


    .amcard-field:not(:last-child) {
        margin-right: 2.0rem;
    }
}

.amcardacc-messages-container {
    display: none;


    &.-visible {
        display: block;
    }
}

.amcard-form-container .amcard-field.-validate {
    position: absolute;
    width: 0;
    opacity: 0;
}

.amgcard-datalist-container {
    position: relative;
    width: 100%;


    &.-active .amgcard-datalist-ul {
        display: block;
    }

    .amcard-input-arrow:after {
        position: absolute;
        top: 1.4rem;
        right: 1.0rem;
        width: 0;
        height: 0;
        border: .6rem solid;
        border-color: #333 transparent transparent transparent;
        content: '';
    }

    &.-active .amcard-input-arrow:after {
        top: .7rem;
        border-color: transparent transparent #333 transparent;
    }

    .amcard-datalist-message {
        font-style: italic;
        padding: 0.5rem 0;
    }

    .amcard-field.-datalist {
        height: 3.8rem !important; // overriding heavy selectors from firecheckout
    }
}

.amgcard-datalist-ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    display: none;
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    max-height: 20.0rem;
    width: 100%;
    border-radius: 2px;
    background: #fff;
    list-style: none;
    border-color: $color-gray82;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    box-sizing: border-box;
    box-shadow: 0 .4rem 1.0rem -.9rem #000;

    .amcard-datalist-li {
        margin-bottom: 0;
        color: #333;
        cursor: pointer;
        transition: background-color .3s ease-in;
        min-height: 3.8rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;


    }

    .amcard-datalist-li:hover {
        background-color: rgba(239, 239, 239, .9);
    }
}

@media (min-width: $screen__m_min) {
    .amcard-codes-list {
        .table {
            .actions {
                width: 2.0rem;
                text-align: right;
            }
        }

        .messages {
            margin: 0;
        }
    }

    .amcard-field-container {
        background: $color-white-smoke;
        padding: 2rem;
        width: 100%;
        box-sizing: border-box;

        &.-code {
            .actions-toolbar {
                text-align: right;

                .primary {
                    float: none;

                    .action {
                        margin: 0;
                    }
                }

                .secondary {
                    .action {
                        margin: 0 0 0 .7rem;
                    }
                }
            }
        }
    }
}


@media (max-width: $screen__s_max) {
    .amcard-card-info {
        flex-wrap: wrap;
        justify-content: space-between;

        .amcard-field {
            flex: 0 50%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}

// copied from cart.scss
.amcard-field-container {
    // margin-top: 2rem;

    .amcard-field-block {
        display: flex;
        flex-direction: row;

        .amcard-field {
            margin-right: 1rem;
        }

        .amcard-button {
            background: $bs-red !important; // overriding heavy selectors from firecheckout
            border: none !important; // overriding heavy selectors from firecheckout
            color: $color-white;
            border-radius: 0.3rem;
            font-size: 14px;
            padding: 7px;
            font-weight: 700;

            &:hover {
                box-shadow: 0 .4rem 1.0rem -.9rem #000;
            }

            .opc-payment-additional & {
                padding: 7px 15px !important; // overriding heavy selectors from firecheckout
                height: 38px !important; // overriding heavy selectors from firecheckout
                width: auto !important; // overriding heavy selectors from firecheckout
            }
        }
    }

    .amcard-check {
        margin: 1.5rem 0 0 0;
        background: $bs-dark-gray;
        border: none;
        color: $color-white;

        &:hover {
        }
    }
}

// account page adjustments (toolbar)
.account {
    .amcard-field-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        input, button {
            height: 3.8rem;
        }

        span.amcard-title {
            width: 100%;
        }

        .amcard-field-block.-code {
            margin-top: 0;
        }
    }
}

.amcard-title {
    font-weight: 700;
    font-size: 1.8rem;
}

.span.gift-card.coupon { // checkout summary item
    width: 100%;
    display: block;
}

// button fancyness
.amcard-delete:after {
    font-family: "Font Awesome 5 Pro";
    margin-left: 1.5rem;
    font-weight: 100;
    content: fa-content($fa-var-trash-alt);
}

.amcard-check:after {
    font-family: "Font Awesome 5 Pro";
    margin-left: 1.5rem;
    font-weight: 100;
    content: fa-content($fa-var-search);
}
