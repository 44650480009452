// Mixins
//@import "../scss_brakesshop/brakesshop/base/mixins/media-queries";

// Vendor libs
//@import '../scss_brakesshop/vendor/magento-ui/lib'; // Magento UI

// Theme variables
//@import "../scss_brakesshop/brakesshop/variables";
//@import "../scss_brakesshop/brakesshop/base/variables";

//@import 'brakesshop/variables';
//@import 'brakesshop/base/variables';

// Inherit Brakesshop
//@import "../scss_brakesshop/styles-base";
@import 'hardrace/variables';

@import '../../../Brakesshop/src/scss/brakesshop/base/mixins/media-queries';
@import '../../../Brakesshop/src/scss/vendor/magento-ui/lib';

@import 'hardrace/variables';
@import 'hardrace/base/variables';

@import '../../../Brakesshop/src/scss/styles-base';

@import "brakesshop/base";
@import "brakesshop/brakesshop-custom";
@import "brakesshop/category";
@import "brakesshop/checkout";
@import "brakesshop/footer";
//@import "brakesshop/header";
@import "brakesshop/modals";
@import "brakesshop/product";

// Hardrace changes
@import "hardrace/widgets";
@import "hardrace/header";
@import "hardrace/navigation";
@import "hardrace/custom";
@import "hardrace/cart";
@import "hardrace/account";
@import "hardrace/fitments";
@import "hardrace/listings-hardrace";

// Wholesale changes
@import "wholesale/wholesale";
@import "wholesale/header";
@import "wholesale/listings";
@import "wholesale/bundled";

// Extensions
@import "plugins/amasty/quick-order/module"; // MVN: todo / investigate: other scss files in this folder seem redundant

// Lang flags
@import "flags/variables";
@import "flags/flag-icon-base";
@import "flags/flag-icon-list";
@import "flags/flag-icon-more";
