.page-products .columns {
    z-index: auto;
}

.floating-header {
    display: none;
//    .details {
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        width: 1280px;
//
//        .image {
//            display: block;
//            content: '';
//            width: 50px;
//            height: 50px;
//            background-size: contain;
//            background-position: center center;
//            background-repeat: no-repeat;
//            margin-right: 25px;
//
//            @include max-screen($screen__s) {
//                margin-right: 10px;
//            }
//        }
//
//        .product-title {
//            text-transform: uppercase;
//            font-size: 16px;
//
//            @include max-screen($screen__s) {
//                font-size: 12px;
//            }
//        }
//
//        .price-information {
//            margin-right: 25px;
//            margin-left: auto;
//            font-size: 18px;
//            font-weight: bold;
//
//            @include max-screen($screen__s) {
//                font-size: 14px;
//                margin-right: 10px;
//                white-space: nowrap;
//            }
//        }
//
//        .order-now {
//            margin-right: 0;
//
//            a.action {
//                background: #1AB67C;
//                border-radius: 3px;
//                padding: 10px 20px;
//                color: #fff;
//                text-transform: uppercase;
//
//                @include max-screen($screen__s) {
//                    padding: 10px 5px;
//                    font-size: 12px;
//                    white-space: nowrap;
//                }
//            }
//        }
//    }
}

.catalog-product-view {
    .page-main {
        padding-top: 35px;
    }

    .page-title-wrapper {
        order: -1;
        width: 100%;
        margin-bottom: 0px;
        display: grid;

        h1 {
            font-size: 30px;
            margin: 0;
            order: 1;
        }

        .manufacturer {
            font-weight: bold;
        }
    }
    .amshopby-option-link {
        order: 3;
    }

    .reviews-sku {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-style: italic;
        margin-bottom: 30px;
        order: 2;

        @include max-screen($screen__m) {
            flex-direction: column;
            align-items: flex-start;

            .product.attribute.sku {
                .type {
                    &:before {
                        content: none;
                        margin: 0;
                    }
                }
            }
        }
    }

    .product-reviews-summary {
        margin: 0;

        .rating-summary {
            left: 0;
        }
    }

    .product.attribute.sku {
        display: flex;

        strong {
            font-weight: normal;
            margin-right: 5px;

            &:before {
                content: '/';
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .value {
            font-weight: bold;
        }
    }

    .column.main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .stock {
        margin-top: 10px;
        margin-bottom: 38px;

        &:before {
            content: '';
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 100%;
        }

        span {
            font-weight: normal;
            font-style: italic;
            font-size: 1.4rem;
            text-transform: none;

            &:first-child {
                margin-left: 5px;
            }
        }

        &.in-stock:before {
            background: #1AB67C;
        }
        &.partial-stock:before {
            background: $bs-color-partial-stock;
        }
        &.out-of-stock:before {
            background: #CCC;
        }
    }

    .product-options-wrapper {
        .fieldset {
            > .field {
                display: flex;
                margin-bottom: 20px;
                align-items: flex-start;

                > .label {
                    width: 150px;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: normal;
                    margin-bottom: 0;
                    line-height: 38px;
                }

                .control {
                    width: auto;
                    flex-basis: 100px;
                    flex-grow: 1;
                }
            }
        }
    }

    .product-options-bottom {
        .label[for="qty"] {
            display: none;
        }

        .box-tocart {
            display: flex;
            .fieldset {
                display: flex;
                width: 100%;

                .actions {
                    display: flex;
                    flex-grow: 1;

                    .tocart {
                        @extend .button;
                        background: #1AB67C;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                @include max-screen($screen__m) {
                    display: flex;
                    align-items: center;

                    .actions {
                        padding-top: 3px;
                    }
                }
            }

            @include max-screen($screen__m) {
                margin-bottom: 0;
            }
        }
    }

    .product.media {
        width: 40%;
        order: 1;
        margin-right: 10%;

        @include max-screen($screen__m) {
            width: 100%;
            padding: 0;
        }
    }

    .fotorama__thumb {
        background: none;
    }

    .fotorama__caption {
        display: none;
    }

    .fotorama__thumb-border {
        border: 1px solid $bs-red;
    }

    .amshopbybrand-link {
        img {
            max-width: 150px;
            max-height: 150px;
        }
        margin-bottom: 40px;
    }

    .product-info-main {
        flex-grow: 1;
        max-width: 60%;
        order: 2;
        margin-top: 0;

        @include max-screen($screen__m) {
            width: 100%;
        }

        .box-tocart {
            width: 100%;
            .actions {
                width: 100%;
            }
            .action.tocart {
                @extend .button;
                background: #1AB67C;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                height: 52px;
            }

            @include max-screen($screen__s) {
                .fieldset {
                    display: flex;
                    align-items: flex-end;

                    .field.qty {
                        padding-right: 15px;
                        margin: 0;
                    }
                }
            }
        }
    }

    .product-info-main .price-box .price-wrapper .price,
    .product-options-bottom .price-box .price-wrapper .price {
        font-size: 32px;
    }

    .product-info-main .price-box {
        display: flex;
        align-items: revert;
        flex-direction: column;

        .special-price {
            margin-left: 15px;
        }
    }

    &.page-product-configurable {
        .product-info-main .price-box {
            .price-container {
                display: flex;
                flex-direction: column;


                .price-label {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    &:not(.page-product-configurable) {
        .product-info-main .price-box {
            .price-label {
                display: none;
            }
        }
    }

    .product-info-main .price-box .special-price {
        margin-bottom: 10px;
    }

    .product-info-main .price-box .special-price .price {
        color: #1AB67C;
    }

    .product-social-links {
        a.action.towishlist {
            background: none;
            border: 0;
            padding: 0;
            font-size: 12px;
            font-weight: normal;
            text-decoration: underline;
        }

        @include max-screen($screen__m) {
            .product-addto-links {
                margin-top: 0;
            }
        }
    }

    .product_usps_container {
        margin: 40px 0;

        ul {
            list-style: none;
            padding-left: 0;
            margin: 0;

            li {
                display: flex;
                align-items: flex-start;
                text-transform: uppercase;
                margin: 15px 0;

                &:before {
                    content: url('../images/svg-icons.svg#use-icon-check');
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    overflow: hidden;
                }
            }
        }
    }

    .need_assistance {
        border: 2px solid $bs-light-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        font-size: 16px;
        margin: 40px 0;

        .icon-telemarketer {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }

        p.need_assistance_label {
            margin-bottom: 0;
        }

        a {
            color: $color-blue1;

            @include max-screen($screen__m) {
                display: block;
            }
        }
    }

    .product.info.detailed {
        width: 100%;
        order: 3;

        .product.data.items {
            margin-top: 15px;
            .item.title {
                margin-right: 12px;

                @include max-screen($screen__l) {
                    margin: 0 5px 10px 0;
                }

                a {
                    border: 1px solid $bs-mlt-gray;
                    color: $bs-xdark-gray;
                    background: none;
                    font-weight: normal;
                }

                &.active {
                    a {
                        border-bottom: 2px solid #000;
                        padding-bottom: 7px;
                    }
                }
            }

            .data.item.content {
                border: 0;
                padding: 20px 30px;
                @include max-screen($screen__l) {
                    padding: 50px 0 0;
                }
                img {
                    max-width: 100%;
                }

                .h3 {
                    font-weight: 300;
                    line-height: 1.1;
                    font-size: 1.8rem;
                    margin-top: 1.5rem;
                    margin-bottom: 1rem;

                    &.subtitle {
                        margin-top: 30px;
                        font-weight: 400;
                        border-left: 7px solid #ff0016;
                        padding-left: 5px;
                    }
                }

            }
        }
    }
}

.hardrace_nl {
    .page-wrapper {
        .page-main {
            .columns {
                .column.main {
                    .products-grid {
                        .product-item {
                            .cat-stock-indicator {
                                .stock.partial-stock {
                                    &:before {
                                        background: $bs-color-partial-stock;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .stock.partial-stock:before {
            background: $bs-color-partial-stock;
        }

        .widget {
            &.package-deals {
                .products {
                    .product {
                        .stock {
                            .stock-bullet {
                                &.partial-stock {
                                    &:before {
                                        background: $bs-color-partial-stock;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.dealer-pricing {
    display: flex;
    flex-direction: column;

    .excl-tax {
        margin-left: 5px;
        font-size: 13px;
    }

    .retail-price {
        margin-top: 5px;
        font-size: 13px;

        .price-container {
            .price-wrapper {
                .price {
                    font-size: 13px;
                }
            }
        }
    }
}
