//chevron fix fitments selector
.search-fitments {
    svg {
        path {
            fill: white;
        }
    }
}

.fitments-fitments-index {
    .title-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 20px 0;

        @include max-screen($screen__m) {
            flex-wrap: wrap;
            justify-content: center;

            .fitment-logo {
                order: 2;
            }

            .button {
                order: 3;
                width: 50%;
            }

            .title {
                order: 1;
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .fitment-logo {
            width: 70px;
            height: 70px;

            background-color: transparent;
            background-position: center center;
            background-size: contain;

            margin: 0 15px 0 35px;

            @include max-screen($screen__m) {
                margin: 0 15px;
            }
        }

        span {
            font-size: 13px;
        }

        .button {
            color: #fff;
            text-decoration: none;
            margin-right: 15px;

            @include max-screen($screen__m) {
                white-space: nowrap;
            }
        }

        .title {
            flex-grow: 1;
            text-transform: uppercase;

            span {
                color: $bs-red;
            }

            h1 {
                color: #000;
                font-size: 26px;
                font-weight: normal;
                margin: 0;

                @include max-screen($screen__l) {
                    font-size: 20px;
                }

                @include max-screen($screen__m) {
                    font-size: 16px;
                }
            }
        }

        .title-bottom {
            display: flex;
            align-items: center;
            margin-top: 5px;

            .fitment-title {
                margin-left: 15px;

                h1 {
                    margin-bottom: 10px;
                }

                p {
                    text-transform: none;

                    a {
                        color: #000;
                        text-decoration: underline;
                    }
                }

                .modal-oem {
                    display: none;
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgba(0, 0, 0, .1);
                    z-index: 10000;

                    &.active {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .modal-content {
                        position: relative;
                        box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, .25);
                        background: #FFF;
                        width: 40vw;
                        padding: 35px;

                        .close-modal-oem {
                            position: absolute;
                            font-size: 22px;
                            color: white;
                            top: 0;
                            right: 0;
                            background: red;
                            width: 50px;
                            height: 50px;
                            vertical-align: middle;
                            line-height: 50px;
                            text-align: center;
                            cursor: pointer;
                            z-index: 400;
                        }

                        h1 {
                            color: red;
                            margin-bottom: 15px;
                        }

                        p {
                            margin-bottom: 25px;
                        }

                        .models {
                            max-height: 50vh;
                            overflow-y: auto;
                        }

                        .tree {
                            position: relative;
                            max-height: 44px;
                            overflow: hidden;
                            transition: none;

                            .oem-tree-toggle {
                                position: absolute;
                                left: 0;
                                top: 0;
                                line-height: 40px;
                                color: #000;
                                cursor: pointer;
                            }

                            &.opened {
                                max-height: 999px;
                                overflow: visible;
                                transition: all 1.34s ease-in-out;
                            }
                        }

                        .oem-system {
                            background: $bs-light-gray;
                            color: $bs-dark-gray;
                            padding: 10px 15px;
                            border-radius: 3px;
                            display: inline-block;
                            margin-bottom: 5px;
                            margin-right: 5px;

                            &:hover {
                                text-decoration: none;
                                background: $bs-dark-gray;
                                color: #fff;
                                cursor: pointer;
                            }
                        }

                        @include max-screen($screen__m) {
                            width: 95%;
                            box-sizing: border-box;

                            .oem-system {
                                display: block;
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }
    }

    .fitment-cat {
        background-color: #DCDBDC;
        margin-bottom: 50px;
        // max-height: 17.5rem;
        // overflow: hidden;

        @include max-screen($screen__s) {
            max-height: inherit;
        }

        .fitment-cat-header {
            width: 100%;
            //height: 17.5rem;
            display: flex;
            position: relative;
            overflow: visible;

            @include max-screen($screen__s) {
                flex-direction: column;
                height: auto;
            }

            .fitment-cat-image {
                width: 40%;
                height: inherit;
                background-size: cover;
                max-width: 825px;

                @include max-screen($screen__s) {
                    width: 100%;
                    height: 100px;
                }
            }

            .fitment-cat-content {
                padding: 20px;
                max-width: 54rem;

                @include max-screen($screen__s) {
                    min-height: 194px;
                }

                .fitment-cat-name {
                    font-weight: 600;
                    color: #000;
                    font-size: 36px;
                    margin: 0 0 10px;
                }

                .fitment-cat-desc {
                    padding-bottom: 40px;

                    h1, h2, h3, h4, h5, h6 {
                        font-size: 18px;
                        font-weight: normal;
                        margin: 0 0 20px;
                    }
                }

                .fitment-cat-toggle {
                    display: block;
                    cursor: pointer;
                    color: #fff;
                    background-color: #FD0021;
                    height: 50px;
                    line-height: 50px;
                    position: absolute;
                    padding: 0 60px 0 10px;
                    right: 0;
                    bottom: 0;
                    min-width: 10rem;

                    span.hide-products {
                        display: none;
                    }

                    span.icon {
                        position: absolute;
                        background-color: #0a0a0a;
                        display: inline-block;
                        height: 50px;
                        width: 50px;
                        text-align: center;
                        vertical-align: middle;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }

        &.opened {

            .fitment-cat-header {
                span.hide-products {
                    display: inherit !important;
                }

                span.show-products {
                    display: none;
                }
            }

            .fitment-cat-toggle {
                span.icon {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }

    }


    .products-grid {
        padding-top: 1.5rem;
        background: white;

        button.action.tocart.primary.am-btn-right a {
            color: white;

            &:hover {
                text-decoration: none;
            }
        }

        ul.fitments-attributes {
            list-style: none;
            padding: 0;
            margin: 0;
            line-height: 1;
        }
    }
}

