@media all and (min-width: 768px), print {
    .ambrands-letters-list {
        display: flex
    }
    .ambrands-letters-list .ambrands-letter .ambrands-content {
        margin: 0 -10px
    }
    .ambrands-letters-list .ambrands-brand-item {
        margin: 0 10px 20px
    }
    .ambrands-letters-list .ambrands-brand-item.-no-logo {
        max-width: 156px
    }
    .ambrands-letters-list .ambrands-letter {
        padding-right: 10px;
        width: inherit
    }
}

@media all and (min-width: 1024px), print {
    .ambrands-letters-filter .ambrands-letter {
        margin: 0 7px 7px 0
    }
    .ambrands-letters-list .ambrands-letter {
        padding-right: 30px;
        width: inherit
    }
    .ambrands-list-popup {
        position: absolute;
        width: 600px;
        height: 415px;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0 2px 7px rgba(131, 136, 141, .13), 0 10px 15px rgba(131, 136, 141, .13);
        opacity: 0;
        transition: all .5s ease;
        pointer-events: none
    }
    .ambrands-menu-item:hover .ambrands-list-popup, .ammenu-item:hover .ambrands-list-popup {
        display: block;
        opacity: 1;
        pointer-events: auto
    }
    .ambrands-list-container {
        display: flex;
        height: 100%;
        font-size: 0
    }
    .ambrands-list-popup .ambrands-letters-filter {
        display: block;
        overflow: auto;
        box-sizing: border-box;
        padding: 20px 14px 0;
        min-width: calc(((34px + 12px) * 4) + (14px * 2));
        width: calc(((34px + 12px) * 4) + (14px * 2));
        height: 100%;
        background: #fbfbfb;
        font-size: 0
    }
    .ambrands-list-popup .ambrands-letters-filter .ambrands-letter {
        display: inline-flex;
        margin: 0 6px 12px
    }
    .ambrands-list-popup .ambrands-letters-filter .ambrands-letter.-letter-all {
        margin-bottom: 30px;
        width: calc(100% - 12px)
    }
    .ambrands-popup-items {
        display: inline-block;
        overflow: auto;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        height: 100%;
        font-size: 14px
    }
    .ambrands-popup-items .ambrands-letters-list {
        width: 100%
    }
    .ambrands-popup-items .ambrands-letters-list .ambrands-letter {
        margin-bottom: 30px;
        padding-right: 0
    }
    .ambrands-popup-items .ambrands-letters-list:last-child .ambrands-letter {
        margin-bottom: 0
    }
    .ambrands-popup-items .ambrands-letters-list .ambrands-brand-item {
        min-width: 100px
    }
    .ambrands-popup-items .ambrands-letters-list .ambrands-brand-item {
        min-width: 100px
    }
    .ambrands-popup-items .ambrands-letters-list .ambrands-brand-item .ambrands-label {
        max-width: 130px
    }
    .ambrands-popup-items .ambrands-letters-list .ambrands-brand-item.-no-logo {
        max-width: 102px
    }
    .ambrands-popup-items .ambrands-letter .ambrands-brand-item .ambrands-empty {
        font-weight: 400;
        font-size: 40px
    }
}

.ambrands-word-break {
    word-wrap: break-word;
    word-break: break-word
}

.ie11 .ambrands-word-break {
    word-break: break-all
}

.amrelated-grid-wrapper .amshopby-option-link {
    margin-bottom: 10px
}

.amshopby-brand-tooltip.-no-double ~ .amshopby-brand-tooltip.-no-double {
    visibility: hidden;
    opacity: 0
}

.ambrands-letters-filter {
    display: flex;
    flex-wrap: wrap
}

.ambrands-letters-filter .ambrands-letter {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 12px 12px 0;
    padding: 6px;
    min-width: 34px;
    min-height: 34px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #fff;
    color: #333;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    cursor: pointer
}

.ambrands-letters-filter .ambrands-letter:hover {
    border-color: #006bb4;
    background: #fff;
    text-decoration: none
}

.ambrands-letters-filter .ambrands-letter.-disabled {
    border-color: #ececec;
    background: #ececec;
    box-shadow: none;
    color: #ccc;
    pointer-events: none
}

.ambrands-letters-filter .ambrands-letter.hide {
    display: none
}

.ambrands-letters-filter .ambrands-letter.-active {
    border-color: #006bb4;
    background: #dff1ff;
    color: #006bb4
}

.ambrands-letters-filter .ambrands-letter.-letter-all {
    padding: 6px 11px
}

.ambrands-filters-block {
    display: inline-block;
    margin: 20px 0 53px;
    width: 100%
}

.ambrands-search-wrapper {
    margin-bottom: 30px;
    max-width: 460px
}

.ambrands-search-block {
    position: relative;
    display: block
}

.ambrands-search-block:before {
    position: absolute;
    top: calc(50% - 20px / 2);
    left: 15px;
    display: flex;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDcuNjkyYTcuNjkyIDcuNjkyIDAgMTAxNS4zODUgMEE3LjY5MiA3LjY5MiAwIDAwMCA3LjY5MnptMS41MzggMGE2LjE1NCA2LjE1NCAwIDExMTIuMzA4IDAgNi4xNTQgNi4xNTQgMCAwMS0xMi4zMDggMHoiIGZpbGw9IiNDN0M3QzciLz48cGF0aCBkPSJNMTkuMjMyIDIwYS43Ni43NiAwIDAxLS41NDQtLjIyNmwtNi42MzYtNi42NDZhLjc3Ljc3IDAgMTExLjA3Ny0xLjA5N2w2LjY0NyA2LjY0NmMuMy4zLjMuNzg3IDAgMS4wODdhLjc2Ljc2IDAgMDEtLjU0NC4yMzZ6IiBmaWxsPSIjQzdDN0M3Ii8+PC9zdmc+) center no-repeat;
    content: '';
    pointer-events: none
}

.ambrands-search-block .ambrands-clear {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 10px;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 0 6px 6px 0;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjIzIDQuMjNhLjc4My43ODMgMCAwMTEuMTA2IDBMMTAgOC44OTJsNC42NjQtNC42NjRhLjc4My43ODMgMCAwMTEuMTA3IDEuMTA3TDExLjEwNyAxMGw0LjY2NCA0LjY2NGEuNzgzLjc4MyAwIDAxLTEuMTA3IDEuMTA3TDEwIDExLjEwNyA1LjMzNiAxNS43N2EuNzgzLjc4MyAwIDAxLTEuMTA3LTEuMTA3TDguODkzIDEwIDQuMjMgNS4zMzZhLjc4My43ODMgMCAwMTAtMS4xMDd6IiBmaWxsPSIjMzMzIi8+PC9zdmc+) center no-repeat;
    cursor: pointer
}

.ambrands-search-block .ambrands-clear:not(.-active) {
    display: none
}

.ambrands-search-block .ambrands-input {
    display: block;
    box-sizing: border-box;
    margin: 0 0 10px;
    padding: 10px 40px;
    max-width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #fff;
    color: #333;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
}

.ambrands-search-block .ambrands-input::-webkit-input-placeholder {
    color: #575757;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
}

.ambrands-search-block .ambrands-input:-moz-placeholder {
    color: #575757;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 1
}

.ambrands-search-block .ambrands-input::-moz-placeholder {
    color: #575757;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 1
}

.ambrands-search-block .ambrands-input:-ms-input-placeholder {
    color: #575757;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
}

.ambrands-search-block .ambrands-input:focus::-webkit-input-placeholder {
    color: #c7c7c7
}

.ambrands-search-block .ambrands-input:focus:-moz-placeholder {
    color: #c7c7c7
}

.ambrands-search-block .ambrands-input:focus::-moz-placeholder {
    color: #c7c7c7
}

.ambrands-search-block .ambrands-input:focus:-ms-input-placeholder {
    color: #c7c7c7
}

.ambrands-search-block .ambrands-input:hover, .ambrands-search-block .ambrands-input:focus {
    border-color: #006bb4
}

._keyfocus .ambrands-search-block .ambrands-input:focus, .ambrands-search-block .ambrands-input:not([disabled]):focus {
    box-shadow: none
}

.ambrands-livesearch-block {
    position: absolute;
    z-index: 99;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 250px;
    width: 460px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(190, 199, 219, .21), 0 6px 25px rgba(190, 199, 219, .28)
}

.ambrands-livesearch-block:not(.-active) {
    display: none
}

.ambrands-livesearch-block > .ambrands-item {
    display: block;
    float: left;
    box-sizing: border-box;
    padding: 5px 10px;
    width: 100%;
    color: #000
}

.ambrands-livesearch-block > .ambrands-item:hover {
    background-color: #e8e8e8;
    text-decoration: none
}

@supports (-webkit-touch-callout:none) {
    ._keyfocus .ambrands-search-block .ambrands-input:not(:focus), .ambrands-search-block .ambrands-input:not(:focus) {
        border-top-width: .5px
    }
}

.ambrands-letters-list .ambrands-letter {
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    vertical-align: top
}

.ambrands-letters-list .ambrands-letter .ambrands-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    padding: 0
}

.ambrands-letters-list .ambrands-letter .ambrands-title {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    color: #333;
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    line-height: 1
}

.ambrands-letter .ambrands-brand-item {
    position: relative;
    margin: 0 8px 16px;
    min-width: 100px
}

.ambrands-letter .ambrands-brand-item.-no-logo {
    max-width: calc(50% - (8px * 2));
    width: 100%
}

.ambrands-letter .ambrands-brand-item.-no-logo .ambrands-label {
    padding-top: 10px
}

.ambrands-letter .ambrands-brand-item .ambrands-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(74, 83, 94, .03), 0 4px 10px rgba(104, 118, 139, .13);
    color: #333
}

.ambrands-letter .ambrands-brand-item .ambrands-inner:hover, .ambrands-letter .ambrands-brand-item .ambrands-inner:focus {
    border-color: #006bb4;
    text-decoration: none
}

.ambrands-letter .ambrands-brand-item .ambrands-inner:active {
    border-color: #006bb4;
    background: rgba(65, 173, 255, .19)
}

.ambrands-letter .ambrands-brand-item .ambrands-empty {
    color: #e2e2e2
}

.ambrands-letter .ambrands-brand-item .ambrands-count {
    color: #c7c7c7
}

.ambrands-letter .ambrands-brand-item .ambrands-count:before {
    content: '('
}

.ambrands-letter .ambrands-brand-item .ambrands-count:after {
    content: ')'
}

.ambrands-letter .ambrands-brand-item .ambrands-label {
    word-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
    margin: auto;
    padding: 0 10px 10px;
    width: 100%;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 19px
}

.ie11 .ambrands-letter .ambrands-brand-item .ambrands-label {
    word-break: break-all
}

.ambrands-brand-item .ambrands-image-block {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px
}

.ambrands-brand-item .ambrands-image-block > .ambrands-image {
    max-width: 100%;
    max-height: 100%
}

.ambrands-letter .ambrands-image {
    display: block;
    margin: 0 auto;
    pointer-events: none
}

.ambrands-letters-list .ambrands-brand-item {
    list-style-type: none;
    text-align: center
}

.ambrands-list-popup, .ambrands-menu-item .ambrands-arrow {
    display: none
}

.ambrand-index-index {
    .page-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 2rem;

        .page-title {
            margin: 0 2rem 0 0 !important;
        }

        .category-image {
            margin: 0;

            .image {
                max-height: 4rem;
            }
        }
    }
}
