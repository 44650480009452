.fitments-search-wrapper {
    background: $bs-light-gray;
    flex-basis: 100%;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 4;
    padding: 2rem;

    @media screen and (min-width: $screen__m_min) {
        background: $bs-dark-gray;
    }

    @media screen and (min-width: $screen__l_min) {
        padding: 0 2rem;
    }

    .search-switcher {

        @media screen and (min-width: $screen__m_min) {
            display: none;
        }

        .search-fitment {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: $bs-black;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 600;
            position: relative;
            padding: 1rem 0;
            box-sizing: border-box;

            &.acive {
                font-size: 1.6rem;
                padding: 1.6rem 1.2rem;
            }

            @media screen and (max-width: $screen__s_max) {
                width: 100%;
            }

            @media screen and (min-width: $screen__m_min) {
                display: none;
            }

            .icon-wrapper {
                margin-left: 1.6rem;
                display: flex;
                align-items: center;

                .icon {
                    width: 6.2rem;
                    height: 2.1rem;
                    display: inline;
                }
            }

            .icon-double-chevron {
                width: 3rem;
                height: 3rem;
                position: absolute;
                right: 1.2rem;
                transform: rotateX(180deg);
                transition: transform 300ms;
            }

            &:not(.active) {
                .icon-double-chevron {
                    transform: rotateX(0deg);
                }
            }
        }

        .search-fitment,
        .search-part {
            &.active {
                cursor: default;
            }
        }

        #search-tab-header {
            display: none;
        }
    }

    .search-fitments {
        .fitments-filter-container {
            font-size: 1.5rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: column;

            @media screen and (min-width: $screen__m_min) {
                position: relative;
                padding: 0;
                flex-direction: row;
                align-items: center;
                gap: 0 1rem;
            }

            @media screen and (min-width: $screen__l_min) {
                flex-wrap: nowrap;
            }
        }

        .fitments-filter-selector {
            position: relative;
            margin-top: .5rem;
            flex: 1 1 0;

            @media screen and (min-width: $screen__m_min) {
                position: static;
                margin: .5rem 0;
                flex-flow: column nowrap;
                min-width: 0;
            }

            @media screen and (max-width: $screen__s_max) {
                max-width: 100%;
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .fitments-filter-toolbar {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $screen__m_min) {
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 1rem 0;
            }
            @media screen and (min-width: $screen__l_min) {
                width: auto;
                padding:0;
                margin-left: 2rem;
            }

            .fitments-filter-button {
                text-transform: uppercase;
                padding: 1rem 2rem;
                margin: .5rem 0 0 0;
                background: $bs-red;
                border: none;
                color: white;

                @media screen and (min-width: $screen__m_min) {
                    margin: 0;
                }

                @media screen and (min-width: $screen__l_min) {
                    padding: 0 2rem;
                }
            }

            .fitments-filter-links {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                margin-top: 1rem;

                @media screen and (min-width: $screen__l_min) {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    margin-top: 0;
                }

                @media screen and (min-width: $screen__l_min) {
                    margin-left: 1rem;
                }


                .fitments-filter-link-clear,
                .fitments-filter-link {
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    font-weight: 500;
                    text-decoration: none;
                    flex: 1;

                    @media screen and (min-width: $screen__m_min) {
                        font-size: 1.1rem;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .fitments-filter-link-clear {
                    color: white;

                    @media screen and (max-width: $screen__s_max) {
                        width: 100%;
                        padding: 1.2rem 2.4rem;
                        background: $bs-black;
                        text-align: center;
                        font-weight: 500;
                        border-radius: .4rem;
                    }
                }

                .fitments-filter-link {
                    color: $bs-dark-gray;
                    text-decoration: underline;

                    @media screen and (min-width: $screen__m_min) {
                        color: $bs-light-gray;
                        text-decoration: none;
                    }
                }
            }
        }

        .native-dropdown {
            position: relative;

            svg {
                position: absolute;
                top: 1.25rem;
                right: 1.5rem;
            }

            select {
                padding-right: 4rem;
            }
        }
    }

    .block-search {
        &.active {
            padding: 0;
            margin: 0;
        }

        .search-toggle-container {
            display: none;
        }

        > .block-content {
            position: initial;
            transform: initial;
            background: initial;
        }

        div.field.search {
            margin: 0;
        }

        .control {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 0;
        }

        .amsearch-wrapper-input {
            width: 100%;
        }

        #search-fitments {
            margin-bottom: 1.6rem;
            margin-top: .5rem;

            @media(max-width: $screen__s_max) {
                height: 4.5rem;
                border: 2px solid rgb(204, 204, 204);
            }

            @media screen and (min-width: $screen__m_min) {
                margin-bottom: 2.1rem;
                height: 4.5rem;
            }
        }

        div.actions {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            position: static;

            button.action.search {
                text-transform: uppercase;
                padding: 1rem 2rem !important;
                margin: 0 !important;
                background: $bs-red;
                border: none;
                color: white;
                font-weight: 700;
                height: 4.5rem;
                width: 100%;
                border-radius: 3px;

                @media screen and (min-width: $screen__m_min) {
                    margin: 0 0 0 1rem;
                    width: initial;
                }

                @media screen and (min-width: $screen__l_min) {
                    padding: 0 2rem;
                }
            }
        }
    }
}

.fitments-search-wrapper {
    .search-switcher {
        display: flex;
        align-items: center;
    }

    .fitments-search-inner {
        max-width: 1280px;
        margin: 0 auto;
        min-height: 78px;
        display: flex;
        align-items: center;
        padding: 0;

        @include max-screen($screen__s_max) {
            display: none;

            .no-mobile-switcher & {
                display: flex;
            }

            &.active {
                display: flex;
            }
        }

        .loading-mask {
            display: none;
            align-items: center;
            justify-content: center;
            z-index: 9999;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.5);
            overflow: hidden;

            &.active {
                display: flex;
            }
        }
    }

    .search-fitments,
    .block-search {
        display: none;

        &.active {
            display: block;
        }
    }

    .search-fitments {
        width: 100%;

        .fitments-container {
            position: relative;
            display: flex;
            align-items: center;

            @include max-screen($screen__l) {
                align-items: flex-start;
            }

            @include max-screen($screen__m) {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
            }

            .fitments-mobile {
                display: none;
            }

            @include max-screen($screen__s) {
                flex-direction: column;

                .fitments-not-mobile {
                    display: none;
                }

                .fitments-mobile {
                    display: block;

                    .select-wrap {
                        width: 100%;
                        position: relative;

                        select {
                            margin: 0 0 15px;
                            background-color: #a2a3a3;
                            border-color: #a2a3a3;
                            border-radius: 2px;
                            -moz-appearance: none;
                            /* for Chrome */
                            -webkit-appearance: none;
                        }

                        &::after {
                            display: block;
                            position: absolute;
                            content: '';
                            width: 18px;
                            height: 18px;
                            background: url(../images/svg-icons.svg#use-icon-chevron-down);
                            right: 15px;
                            top: 10px;
                        }
                    }
                }
            }

            .fitments-menu-wrapper {
                position: relative;
                display: flex;
                flex-grow: 1;
                z-index: 999;

                .fitment-selections {
                    display: flex;
                    justify-content: space-between;
                    flex-grow: 1;

                    @include max-screen(1130px) {
                        flex-wrap: wrap;
                        padding: 8px 0;
                    }

                    a.button-fitment-select {
                        padding: 0 15px;
                        display: flex;
                        align-items: center;
                        background: #a2a3a3;
                        color: #000;
                        flex-grow: 1;
                        flex-basis: 100px;
                        margin-right: 25px;
                        position: relative;
                        border: 2px solid #a2a3a3;
                        text-transform: none;
                        min-width: 190px;

                        &:not([data-selected-category-id]) {
                            &:not([data-selector-role="brand"]) {
                                pointer-events: none;
                            }
                        }

                        input {
                            background: #a2a3a3;
                            color: #000;
                            border: 0;
                            margin: 0 -15px;
                            height: 34px;
                            outline: none;

                            &:hover,
                            &:focus,
                            &:active {
                                text-decoration: none;
                                //background: rgba(255, 255, 255, 0.7);
                                background: #fff;
                                box-shadow: none;
                            }

                            &::-webkit-input-placeholder {
                                color: #000;
                            }

                            &:-moz-placeholder {
                                color: #000;
                                opacity: 1;
                            }

                            &::-moz-placeholder {
                                color: #000;
                                opacity: 1;
                            }

                            &:-ms-input-placeholder {
                                color: #000;
                            }

                            &::-ms-input-placeholder {
                                color: #000;
                            }
                        }

                        @include max-screen(1130px) {
                            min-width: 45%;
                            margin: 0 8px;

                            &:nth-child(n+3) {
                                margin-top: 8px;
                            }
                        }

                        @include max-screen($screen__s) {
                            margin: 10px;
                        }

                        &:hover,
                        &.active, &:first-of-type {
                            text-decoration: none;
                            background: #fff;
                            border: 2px solid #c7c8c8;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;

                            input {
                                background: #fff;
                            }
                        }

                        &.active {
                            text-decoration: none;
                        }

                        &:hover {
                            border-bottom: 2px solid #c7c8c8;
                        }

                        &.active {
                            border-bottom: 2px solid #fff;
                        }

                        &:after {
                            display: block;
                            position: absolute;
                            content: '';
                            width: 18px;
                            height: 18px;
                            background: url('../images/svg-icons.svg#use-icon-chevron-down');
                            right: 15px;
                            top: 8px;
                        }
                    }
                }

                @include max-screen($screen__m) {
                    display: none;
                }
            }

            .fitment-menus {
                top: 100%;
                position: absolute;
                background: #fff;
                width: 100%;
                overflow: hidden;
                z-index: 100;

                max-height: 800px;
                overflow: auto;

                -webkit-overflow-scrolling: touch;

                @include max-screen($screen__s) {
                    top: 0%;
                }

                .fitment-menu {
                    display: none;
                    padding: 70px 25px 30px;
                    column-count: 7;
                    column-fill: auto;
                    -moz-column-fill: balance;

                    border: 2px solid #c7c8c8;
                    border-top: none;

                    @include max-screen($screen__m) {
                        column-count: 1;
                    }

                    &.no-index {
                        column-count: 1;
                    }

                    &.show {
                        display: flex;
                        max-height: 60vh;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        min-height: 100px;
                        background: #fff;

                        &:after {
                            content: "";
                            background: rgba(0, 0, 0, .4);
                            left: 0;
                            right: 0;
                            bottom: 0;
                            top: 0;
                            position: fixed;
                            z-index: -1;
                        }
                    }

                    .search {
                        position: absolute;
                        width: 95%;
                        top: 25px;

                        @include max-screen($screen__m) {
                            display: none;
                        }
                    }

                    section {
                        width: 12%;
                        padding: 0 1rem 0 1rem;

                        h5 {
                            color: $bs-red;
                            text-transform: uppercase;
                            font-size: 18px;
                        }

                        ul {
                            padding: 0;
                            list-style: none;

                            li {
                                a {
                                    color: #000;
                                }
                            }
                        }
                    }
                }

                .fitment-select-wrap {
                    display: none;
                }

                @include max-screen($screen__m) {
                    background: none;
                    position: relative;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    .fitment-menu {
                        display: block;
                        border: none;
                        padding: 0 25px;

                        section {
                            display: none;
                        }

                        .fitment-select-wrap {
                            position: relative;
                            width: 100%;
                            display: block;
                            column-count: inherit;

                            select {
                                background-color: #a2a3a3;
                                border-radius: 2px;
                                border: 0;
                                height: 34px;
                                outline: none;
                                width: 100%;
                                margin: 10px 0;
                                -moz-appearance: none;
                                /* for Chrome */
                                -webkit-appearance: none;
                            }

                            &:after {
                                display: block;
                                position: absolute;
                                content: '';
                                width: 18px;
                                height: 18px;
                                background: url(../images/svg-icons.svg#use-icon-chevron-down);
                                right: 15px;
                                top: 18px;
                            }
                        }
                    }
                }
            }
        }

        .fitment-select {
            color: #000;
            width: 220px;
            margin-right: 20px;
            border: 0;
            border-radius: 2px;
            background: $bs-mlt-gray url('../images/svg-icons.svg#use-icon-chevron-down') no-repeat calc(100% - 10px) 50% / 18px 18px;
        }

        .fitment-select {
            color: #000;
            width: 220px;
            margin-right: 20px;
            border: 0;
            border-radius: 2px;
            background: $bs-mlt-gray url('../images/svg-icons.svg#use-icon-chevron-down') no-repeat calc(100% - 10px) 50% / 18px 18px;
        }

        .action.primary {
            background: $bs-red;
            border: none;
            height: 38px;
            margin-right: 30px;
            padding: 7px 25px;
            min-width: 190px;

            &.disabled {
                opacity: 1;
                background: $bs-light-gray;
            }

            span {
                text-transform: uppercase;
                font-weight: normal;
                font-size: 15px;
            }

            @include max-screen($screen__l) {
                min-width: 230px;

                span {
                    font-size: 13px;
                }
            }
        }

        .nested {
            position: relative;
            padding: 0;
            margin-left: auto;
            display: flex;
            flex-direction: column;

            .action.listed {
                color: $bs-mlt-gray;
            }

            a {
                font-size: 11px;
                text-transform: uppercase;
                color: #fff;

                @include max-screen($screen__m) {
                    margin: 12px 0 20px;
                    display: block;
                }
            }
        }

        .actions-wrapper {
            display: flex;
            align-items: center;

            @include max-screen($screen__l) {
                padding: 8px 0;
            }

            @include max-screen(1130px) {
                flex-direction: column;
                margin-right: 8px;

                .action.primary {
                    margin: 0;
                    margin-bottom: 5px;
                }

                .nested {
                    margin: 0;
                }
            }
        }

        &.active {
            .fitments-container {
                .fitments-menu-wrapper {
                    z-index: 999;
                }
            }
        }
    }

    .block-search {
        width: 100%;
        padding: 0;

        @include max-screen($screen__m) {
            padding: 0 15px;
        }

        .field.search {
            display: flex;
            flex-grow: 1;
            margin-right: 20px;
        }

        .control {
            padding: 0;
            //padding-right: 9px;
            //padding-left: 9px;
            flex-grow: 1;
        }

        .form.minisearch {
            display: flex;
            align-items: center;

            .mst-searchautocomplete__close {
                display: none;
            }

            .mst-searchautocomplete__wrapper {
                position: absolute;
                background: #fff;
                margin-top: 20px;
                padding: 20px;

                .mst-searchautocomplete__index {
                    ul {
                        padding: 0;
                        margin: 14px 0;

                        li.mst-searchautocomplete__item.magento_catalog_product {
                            list-style-type: none;
                            margin: 0 0 20px;
                            padding: 0;
                            display: flex;
                            flex-grow: 0;
                            align-items: flex-start;
                            position: relative;

                            img {
                                margin-right: 20px;
                            }

                            .store {
                                min-width: 65px;
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        .input-text {
            padding: 0 9px;
            position: relative;
            left: 0;
        }

        .action.search {
            @extend .button;
            position: relative;
            right: 0;

            &[disabled] {
                opacity: 1;
                background: $bs-light-gray;
            }

            &:before {
                display: none;
            }

            span {
                clip: inherit;
                height: inherit;
                margin: inherit;
                overflow: inherit;
                padding: 0;
                position: inherit;
                width: inherit;
            }
        }

        .nested {
            position: relative;
            padding: 0;
            margin-left: 25px;

            a {
                font-size: 11px;
                text-transform: uppercase;
                color: #fff;
            }
        }

        .searchautocomplete__autocomplete {
            position: absolute;
            background: #fff;
        }

        @include max-screen($screen__m) {
            .label {
                display: none;
            }

            .control {
                border-top: none;
            }

            .action.search {
                display: block;
            }
        }
    }
}


.fitments-search-wrapper--full {

    @media screen and (min-width: $screen__m_min) {
    }

    .fitments-search-inner {
        @media screen and (max-width: $screen__s_max) {
            display: flex;
        }
    }

    .search-switcher {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1.6rem;
        flex-wrap: wrap;

        @media screen and (min-width: $screen__m_min) {
            flex-direction: row;
        }

        .search-fitment,
        #search-tab-header {
            display: flex;
            font-size: 1.6rem;
            font-weight: 400;
            color: $bs-black;
            text-decoration: underline;
            text-transform: initial;
            flex-direction: row-reverse;
            padding: 1rem 0;

            @media screen and (min-width: $screen__m_min) {
                color: white;
                font-size: 1.6rem;
                padding: 0;
            }

            @media screen and (max-width: $screen__s_max) {
                width: 100%;
                justify-content: flex-start;
                padding-left: 0;
                padding-bottom: 0;
            }

            &.active {
                order: -1;
                font-size: 2.8rem;
                text-decoration: none;
                flex-direction: row;

                .icon-wrapper {
                    margin-right: 0;
                }
            }

            .icon-wrapper {
                margin-right: 1.2rem;

                .icon {
                    width: 4.6rem;
                    height: 1.6rem;

                    @media screen and (min-width: $screen__m_min) {
                        width: 6.2rem;
                        height: 2.1rem;
                        color: white;
                        fill: white;
                    }
                }
            }
        }

        .search-fitment {
            .icon-double-chevron {
                display: none;
            }
        }

        #search-tab-header {
            display: flex;
            align-items: center;

            font-size: 1.6rem;

            .icon-wrapper {
                margin-left: 1.2rem;
                display: flex;
                align-items: center;

                @media screen and (min-width: $screen__m_min) {
                    margin-left: 1.6rem;
                }

                .icon {
                    display: inline;
                }
            }
        }
    }

    .search-fitments {
        .fitments-filter-container {
            flex-wrap: wrap;
            justify-content: flex-end;

            @media screen and (min-width: $screen__m_min) {
                margin-right: -1rem;
            }
        }

        .fitments-filter-selector {

            @media screen and (min-width: $screen__m_min) {
            }

            &:first-of-type {
            }

            &:last-of-type {
            }
        }

        .fitments-filter-toolbar {
            margin-top: 1.6rem;
            align-items: center;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-areas:
        'search'
        'clear'
        'link';
            grid-column-gap: 1.2rem;
            grid-row-gap: 1.2rem;

            @media screen and (min-width: $screen__xs_min) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-areas:
        'clear search'
        'link link';
            }

            @media screen and (min-width: $screen__m_min) {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-start;
            }

            .fitments-filter-button {
                padding: 1.2rem 1.6rem;
                line-height: 2.1rem;

                @media screen and (min-width: $screen__m_min) {
                }

                @media screen and (min-width: $screen__l_min) {
                }
            }

            .fitments-filter-button-search {
                grid-area: search;
                margin: 0;
            }

            .fitments-filter-link {
                grid-area: link;
                text-align: right;
                width: 100%;

                @media screen and (min-width: $screen__m_min) {
                    text-align: initial;
                    width: initial;
                }
            }

            .fitments-filter-link-clear {
                grid-area: clear;
            }

            .fitments-filter-links {
                display: contents;

                @media screen and (min-width: $screen__m_min) {

                }

                @media screen and (min-width: $screen__l_min) {

                }


                .fitments-filter-link-clear,
                .fitments-filter-link {
                    display: inline-block;
                    box-sizing: border-box;
                    flex: 0 0 auto;
                    height: 4.5rem;

                    @media screen and (min-width: $screen__m_min) {

                    }

                    &:hover {

                    }
                }

                .fitments-filter-link-clear {
                    padding: 1.2rem 3rem;
                    border-radius: .4rem;
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    grid-area: clear;

                    @media screen and (min-width: $screen__m_min) {
                        background: none;
                        border: 1px solid white;
                    }

                    @media screen and (max-width: $screen__s_max) {

                    }
                }

                .fitments-filter-link {
                    margin-right: auto;
                }
            }
        }
    }
}

.fitments-search-wrapper:not(.fitments-search-wrapper--full) {
    .fitments-filter-toolbar {
        .fitments-filter-button {
            padding: 1.4rem 2rem 1.5rem;
        }
    }
}

.fitments-search-wrapper .search-fitments .fitments-filter-toolbar {
    @media(min-width: $screen__m_min) {
        margin-right: 0;
    }
}

.fitments-filter-selector .column-data {
    display: block;
    max-height: unset;
    overflow: unset;
    column-count: 1;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    padding-bottom: 2rem;

    @media(min-width: $screen__xs_min) {
        column-count: 2 !important;
    }
    @media(min-width: $screen__s_min) {
        column-count: 3 !important;
    }
    @media(min-width: $screen__m_min) {
        column-count: 4 !important;
    }

    .p-6 {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
        // Firefox fix:
        display: table;
        width: 100%;
    }
}
