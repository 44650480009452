body {
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
}

.page-wrapper {
    background: rgb(31,30,30);
    background: linear-gradient(0deg, rgba(31,30,30,1) 0%, rgba(19,17,18,1) 100%);
}

h1.subtitle,
h2.subtitle,
h3.subtitle,
h4.subtitle {
    font-size: 1.8rem;
    margin-top: 30px;
    font-weight: 400;
    border-left: 7px solid $bs-red;
    padding-left: 5px;
}

svg path {
    fill: inherit;
}

.button {
    background: $bs-red;
    color: #fff;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
    padding: 10px 20px;
    height: 38px;
    border: 0;
    border-radius: 2px;
    box-sizing: border-box;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    border: 2px solid $bs-light-gray;
    border-radius: 0;
    height: 38px;
}

textarea {
    border: 2px solid $bs-light-gray;
    border-radius: 0;
}

select {
    line-height: normal;
    box-shadow: none;
    border: 2px solid $bs-light-gray;
    height: 38px;
    padding: 5px 25px 5px 10px;
}

.breadcrumbs {
    font-size: 13px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;

    .items {
        color: $bs-md-gray;
    }

    a,
    a:visited {
        color: $bs-md-gray;
    }
}

.page-main {
    background: #fff;
    margin-bottom: 40px;
    padding-top: 1px;
}

.rating-summary .rating-result > span:before {
    color: $bs-yellow;
}

.sidebar.sidebar-additional {
    margin-bottom: 25px;
}

@include max-screen($screen__m - 1px) {
    .sidebar.sidebar-additional {
        display: none;
    }
}

@include max-screen($screen__l) {
    .page-layout-2columns-right {
        .column {
            &.main {
                width: 66%;
            }
        }
    }

    .sidebar.sidebar-additional {
        width: 33%;
    }
}

.columns {
    padding: 20px 0;
    .column.main {
        padding: 0;
    }
}

.page-with-filter:not(.page-layout-category-fitments) {
    .columns {
        .column.main {
            padding: 20px 0 0 20px;
        }
    }
}
