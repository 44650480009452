.page-products .columns {
    z-index: auto;
}

body.fotorama__fullscreen {
    .fotorama__fullscreen-icon {
        top: -13px !important;
        right: 50px !important;
    }
}

.floating-header {
    display: none;
    //    position: fixed;
    //    top: -100px;
    //    left: 0;
    //    right: 0;
    //    background: #fff;
    //    z-index: 5000;
    //    padding: 5px 0;
    //
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //
    //    transition-property: top;
    //    transition-duration: 0.5s;
    //    //max-height: 3rem;
    //
    //    box-shadow: 2px 0 2px 2px rgba(0, 0, 0, 0.2);
    //
    //    &.show {
    //        top: 0;
    //    }
    //
    //    .details {
    //        display: flex;
    //        justify-content: space-between;
    //        align-items: center;
    //        width: 1280px;
    //
    //        .image {
    //            display: block;
    //            content: '';
    //            width: 50px;
    //            height: 50px;
    //            background-size: contain;
    //            background-position: center center;
    //            background-repeat: no-repeat;
    //            margin-right: 25px;
    //
    //            @include max-screen($screen__s) {
    //                margin-right: 10px;
    //            }
    //        }
    //
    //        .product-title {
    //            text-transform: uppercase;
    //            font-size: 16px;
    //
    //            @include max-screen($screen__s) {
    //                font-size: 12px;
    //            }
    //        }
    //
    //        .price-information {
    //            margin-right: 25px;
    //            margin-left: auto;
    //            font-size: 18px;
    //            font-weight: bold;
    //
    //            @include max-screen($screen__s) {
    //                font-size: 14px;
    //                margin-right: 10px;
    //                white-space: nowrap;
    //            }
    //        }
    //
    //        .order-now {
    //            margin-right: 0;
    //
    //            a.action {
    //                background: $bs-red;
    //                border-radius: 3px;
    //                padding: 10px 20px;
    //                color: #fff;
    //                text-transform: uppercase;
    //
    //                @include max-screen($screen__s) {
    //                    padding: 10px 15px;
    //                    font-size: 12px;
    //                    white-space: nowrap;
    //                }
    //            }
    //        }
    //    }
    //
    //    @include max-screen($screen__m) {
    //        padding: 10px 15px;
    //    }
}

.catalog-product-view {

    #oe_number_tab {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding-inline-start: 0;

        li {
            margin: 0 0.5rem;
        }
    }

    .page-main {
        padding-top: 35px;
    }

    .page-title-wrapper {
        order: -1;
        width: 100%;
        margin-bottom: 0px;
        display: grid;

        h1 {
            font-size: 30px;
            margin: 0;
            order: 1;
        }

        .manufacturer {
            font-weight: bold;
        }
    }

    .amshopby-option-link {
        order: 3;
    }

    .reviews-sku {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-style: italic;
        margin-bottom: 30px;
        order: 2;

        @include max-screen($screen__s) {
            display: none;
        }
    }

    .product-reviews-summary {
        margin: 0;

        .rating-summary {
            left: 0;
        }
    }

    .product.attribute.sku {
        display: flex;

        strong {
            font-weight: normal;
            margin-right: 5px;

            &:before {
                content: '/';
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .value {
            font-weight: bold;
        }
    }

    .column.main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .stock {
        margin-top: 10px;
        margin-bottom: 38px;

        span {
            font-weight: normal;
            font-style: italic;
            font-size: 1.4rem;
            text-transform: none;

            &:first-child {
                margin-left: 5px;
            }

            &.danger {
                color: $bs-red;
                font-weight: 600;
            }
        }

        &.available:before {
            background: $bs-green;
        }

        &.unavailable:before {
            background: $bs-yellow;
        }

        @include max-screen($screen__m) {
            margin-bottom: 0;
        }
    }

    .product-options-wrapper {
        .fieldset {
            > .field {
                display: flex;
                margin-bottom: 20px;
                align-items: flex-start;

                > .label {
                    width: 150px;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: normal;
                    margin-bottom: 0;
                    line-height: 38px;
                }

                .control {
                    width: auto;
                    flex-basis: 100px;
                    flex-grow: 1;
                }
            }
        }
    }

    .product-options-bottom {
        .label[for="qty"] {
            display: none;
        }

        .box-tocart {
            display: flex;

            .fieldset {
                display: flex;
                width: 100%;

                .actions {
                    display: flex;
                    flex-grow: 1;

                    .tocart {
                        @extend .button;
                        background: $bs-lt-green;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 52px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                @include max-screen($screen__m) {
                    display: flex;
                    align-items: center;

                    .actions {
                        padding-top: 3px;
                    }
                }
            }

            @include max-screen($screen__m) {
                margin-bottom: 0;
            }
        }
    }

    .product.media {
        width: 40%;
        order: 1;
        margin-right: 10%;

        @include max-screen($screen__m) {
            width: 100%;
        }

        @include max-screen($screen__s) {
            margin-right: 0;
            margin-bottom: 25px;
        }
    }

    .fotorama__caption {
        display: none;
    }

    .fotorama__thumb {
        background: none;
    }

    .fotorama__thumb-border {
        border: 1px solid $bs-red;
    }

    .amshopbybrand-link {
        img {
            max-width: 150px;
            max-height: 150px;
        }

        margin-bottom: 40px;
    }

    .product-info-main {
        flex-grow: 1;
        max-width: 60%;
        order: 2;
        margin-top: 0;

        @include max-screen($screen__m) {
            width: 100%;
            margin-top: 0;
        }

        @include max-screen($screen__s) {
            max-width: 100%;
        }

        .product-add-form {
            max-width: 450px;

            @include max-screen($screen__s) {
                max-width: 100%;
                width: 100%;
            }
        }

        .box-tocart {
            width: 100%;

            .fieldset {
                align-items: flex-end;
                display: flex;

                .field.qty {
                    margin: 0;
                }
            }


            .actions {
                width: 100%;
                padding-top: 0;
            }

            .action.tocart {
                @extend .button;
                background: $bs-lt-green;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                height: 52px;
            }
        }
    }

    .product-info-main .price-box .price-wrapper .price,
    .product-options-bottom .price-box .price-wrapper .price {
        font-size: 32px;
    }

    .product-info-main {
        .product-info-price {
            max-width: 45rem;
        }
        .price-box {
            display: flex;
            align-items: revert;
            flex-direction: column;

            .dealer-tier-prices {
                border-top: 1px solid black;
                margin-top: 1rem;
                padding-top: 1rem;
            }

            .special-price {
                margin-left: 15px;
            }
        }
    }

    &.page-product-configurable {
        .product-info-main .price-box {
            .price-container {
                display: flex;
                flex-direction: column;

                .price-label {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    &:not(.page-product-configurable) {
        .product-info-main .price-box {
            .price-label {
                display: none;
            }
        }
    }

    .product-info-main .price-box .special-price {
        margin-bottom: 10px;
    }

    .product-info-main .price-box .special-price .price {
        color: #1AB67C;
    }

    .product-social-links {
        a.action.towishlist, a.action.tocompare {
            border: none;
            background: none;
            font-size: 1.3rem;
            text-decoration: none;
            margin: 0 2rem 0 0;
            padding: 0;
            line-height: 2rem;

            &:hover {
                color: #1AB67C;
            }

            &:before {
                font-family: "Font Awesome 5 Pro";
                font-size: 1.4rem;
                margin-right: 1rem;
            }
        }

        a.action.towishlist {
            &:before {
                content: fa-content($fa-var-heart);
            }
        }

        a.action.tocompare {
            &:before {
                content: fa-content($fa-var-chart-bar);
            }
        }
    }

    .product_usps_container {
        margin: 40px 0;

        ul {
            list-style: none;
            padding-left: 0;
            margin: 0;

            display: flex;
            flex-wrap: wrap;

            li {
                display: flex;
                align-items: flex-start;
                text-transform: uppercase;
                margin: 8px 0;
                width: 50%;

                @include max-screen($screen__s) {
                    width: 100%;
                }

                &:before {
                    content: url('../images/svg-icons.svg#use-icon-check');
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    overflow: hidden;
                }
            }
        }
    }

    .need_assistance {
        border: 2px solid $bs-light-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        font-size: 16px;
        margin: 40px 0;

        .icon-telemarketer {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }

        p.need_assistance_label {
            margin-bottom: 0;
        }

        a {
            color: $color-blue1;
        }
    }

    .product.info.detailed {
        width: 100%;
        order: 3;

        .product.data.items {
            .data.item.title {
                margin-right: 6px;

                @include max-screen($screen__s) {
                    margin-bottom: 0;
                }

                a {
                    border: none;
                    color: #464646;
                    background: #afafaf;
                    font-weight: normal;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 10px 20px;

                    @include max-screen($screen__s) {
                        border-bottom: 1px solid #DADADA;
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                    }
                }

                &.active {
                    a {
                        background: #464646;
                        color: #FFF;
                    }
                }
            }

            .data.item.content {
                background: #F2F2F2;
                color: #464646;
                border: 0;
                padding: 20px 30px;
                margin-top: 40px;
                outline: 1px solid #DADADA;

                @include max-screen($screen__s) {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                ul,
                .table-wrapper {
                    margin-bottom: 0;
                }

                img {
                    max-width: 100%;
                }

                .h3 {
                    font-weight: 300;
                    line-height: 1.1;
                    font-size: 1.8rem;
                    margin-top: 1.5rem;
                    margin-bottom: 1rem;

                    &.subtitle {
                        margin-top: 30px;
                        font-weight: 400;
                        border-left: 7px solid #ff0016;
                        padding-left: 5px;
                    }
                }

            }
        }
    }
}


#confirmBox {
    width: 90%;

    @include max-screen($screen__s) {
        #confirmButtons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2rem;

            > * {
                width: 100%;
            }

            button {
                margin: 5px auto;
                display: block;
            }

            a {
                margin: 0 auto;
            }
        }
    }

    .added-item {
        @media(max-width: $screen__s_max) {
            font-size: 1.8rem;
        }
    }

    .block.related {
        width: 100% !important;
        margin-bottom: 0;

        .am-related-title, .am-subtitle {
            height: auto !important;
            font-size: 15px;
            line-height: 2rem;
            display: block;
            position: relative;
            background: none;
            padding: 0.5rem;
            margin: 0;
        }

        .am-subtitle {
            display: none;
        }
    }


}

.box-tocart {

    .fieldset {
        display: flex;
        width: 100%;

        .actions {
            display: flex;
            flex-grow: 1;

            .tocart {
                @extend .button;
                background: $bs-lt-green;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 52px;
                font-size: 16px;
                font-weight: bold;
            }

        }

        @include max-screen($screen__m) {
            display: flex;
            align-items: center;

            .actions {
                padding-top: 3px !important;
            }
        }
    }

    @include max-screen($screen__m) {
        margin-bottom: 0;
    }
}

.dealer-pricing {
    display: flex;
    flex-direction: column;

    .excl-tax {
        margin-left: 5px;
        font-size: 13px;
    }

    .retail-price {
        margin-top: 5px;
        font-size: 13px;

        .price-container {
            .price-wrapper {
                .price {
                    font-size: 13px;
                }
            }
        }
    }
}


.quickview-override {
    #launcher {
        display: none;
    }

    #maincontent {
        padding-top: 0;
        padding-right: 6rem;
        margin-bottom: 0;
    }
}

.pdf-installation-wrapper {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-weight: bold;

    .pdf-installation-label {
        display: flex;
        align-items: center;
        margin-right: 0.8rem;
    }

    .pdf-installation-link {
        text-decoration: none;
        margin: 0;
        display: flex;
        background: #1979c3;
        border-radius: 4px;
        color: white;
        padding: 0.2rem 0.8rem;
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        align-items: baseline;

        &:before {
            font-family: "Font Awesome 5 Pro";
            content: fa-content($fa-var-download);
            margin-right: .8rem;
        }
    }
}

.page-product-bundle {
    .legend.title {
        display: none;
    }
}

input.hide-qty-input {
    border: none !important;
}

input.hide-qty-input {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    &:hover {
        border: none !important;
        cursor: default !important;
    }
}
