.fitments-banner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: $screen__s_max) {
    background-size: 0;
  }

  &-background {

  }

  .fitments-banner--inner {
    max-width: $layout__max-width;
    margin: 0 auto;

    @media screen and (min-width: $screen__l_min) {
      padding: 6.4rem 9.6rem;
    }
  }

  .fitments-search-wrapper {
    padding: 1.8rem 1.2rem;
    max-width: 110rem;

    @media screen and (min-width: $screen__xs_min) {
      padding: 4rem 3.2rem;
    }

    @media screen and (min-width: $screen__m_min){
      background: rgba($color-black, .85);
    }
  }

  .fitments-filter-container {
    .fitments-filter-selector {

    }

    .fitments-filter-toolbar {

    }

    .fitments-filter-button {

    }

    .fitments-filter-links {

    }

    .fitments-filter-link {

    }
  }

}


.fitments-banner-usps {
  background: $bs-light-gray;
}

.fitments-banner-usps-inner {
  max-width: $layout__max-width;
  margin: 0 auto;
  padding: 1.6rem;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1.2rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;

  @media screen and (min-width: $screen__xs_min){
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }
  @media screen and (min-width: $screen__m_min){
    grid-template-columns: repeat(4, 1fr);
    padding: 1.6rem;
  }
    @media screen and (min-width:1315px) {
        padding: 1.6rem 0;
    }
}

.fitments-usp {
  display: grid;
  grid-template-columns: 3.2rem 1fr;
  grid-column-gap: .8rem;

  @media screen and (min-width: $screen__l_min){
    grid-template-columns: 3.6rem 1fr;
    grid-column-gap: 1.2rem;
  }
}

.fitments-usp-icon {
  border: .2rem solid $bs-md-gray;
  background: $bs-green;
  border-radius: 999rem;
  color: white;
  width: 3.0rem;
  height: 3.0rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $screen__l_min){
    width: 3.2rem;
    height: 3.2rem;
  }

  &:after {
    font-family: "Font Awesome 5 Pro";
    content: fa-content($fa-var-check);
    font-weight: 600;
    font-size: 1.8rem;
  }
}

.fitments-usp-details {
  display: flex;
  flex-direction: column;
}
