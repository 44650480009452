.widget {
    box-sizing: border-box;

    &.login-banner {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @include max-screen($screen__l) {
            flex-direction: column;
        }

        .widget.banner {
            width: 75%;
            margin-right: 25px;

            @include max-screen($screen__l) {
                width: 100%;
                height: 200px;
                margin-bottom: 25px;
                margin-right: 0;
            }
        }

        .widget.login {
            display: flex;
            flex-direction: column;

            padding: 20px;
            padding-bottom: 0;
            width: 25%;
            background: #fff;

            @include max-screen($screen__l) {
                width: 100%;
                margin-right: 0;
            }

            h2, .h2 {
                border-left: 10px solid red;
                padding-left: 10px;
                text-transform: uppercase;
                font-size: 2.6rem;
                margin-top: 25px;
                margin-bottom: 20px;
                margin-block-start: 25px;
                margin-block-end: 20px;
            }

            input {
                margin-bottom: 10px;
            }

            .actions {
                margin: 0 0 15px 0;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                button {
                    margin-right: 1rem;
                    height: auto;
                    &:hover {
                        border: none;
                    }
                }

                a {
                    color: #000;
                    font-size: 11px;
                    text-decoration: underline;
                    text-align: right;
                }
            }

            .b2b {
                margin-top: auto;
                margin-bottom: 0;
                margin-left: -20px;
                background: #464847;
                color: #fff;
                width: 100%;
                padding: 20px;
                text-transform: uppercase;
                font-size: 17px;

                &:hover {
                    text-decoration: none;
                }

                span {
                    text-decoration: underline;
                }
            }
        }
    }

    &.usps {
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(-180deg, #E3E3E3 0%, #F4F4F4 100%);
        border-radius: 2px;
        margin-bottom: 25px;
        padding: 15px 0;

        @include max-screen($screen__m) {
            flex-direction: column;
            align-items: center;
            padding: 0;
        }

        .usp {
            display: flex;
            align-items: center;
            width: 25%;
            padding: 0 30px;

            &:not(:first-child) {
                border-left: 2px solid #e0e0e0;
            }

            @include max-screen($screen__l) {
                padding: 0 15px;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            @include max-screen($screen__m) {
                width: 100%;
                border-left: 0;
                border-right: 0;
                border-top: 0;
                border-bottom: 2px solid #e0e0e0;
                align-items: center;
                flex-direction: row;
                //padding: 10px 25px;

                padding: 0;

                padding: 8px 0;
                text-align: left;

                &:not(:first-child) {
                    border-left: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }

            h4 {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
                margin: 0;
            }

            h5 {
                text-transform: uppercase;
                font-weight: normal;
                margin: 5px 0 0 0;

              @include max-screen($screen__m) {
                margin-top: 4px;
              }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                content: '';
                width: 40px;
                height: 40px;
                min-width: 40px;
                background: #1AB67C;
                border: 2px solid #464847;
                margin-right: 15px;
                border-radius: 50%;
                color: #fff;
                font-size: 24px;

                @include max-screen($screen__l) {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    font-size: 18px;
                    border: 2px solid #464847;
                    margin-right: 0;
                    margin-bottom: 5px;
                }

                @include max-screen($screen__m) {
                  margin-left: 25px;
                  margin-right: 15px;
                  min-width: 25px;
                  width: 25px;
                  height: 25px;
                  font-size: 14px;
                  margin-bottom: 0;
                }
            }
        }
    }

    &.package-deals {
        position: relative;
        margin: 25px 0;
        padding: 20px;
        background: #fff;

        h2, .h2 {
            border-left: 10px solid red;
            padding-left: 10px;
            text-transform: uppercase;
            font-size: 2.6rem;
            margin-top: 25px;
            margin-bottom: 20px;
            margin-block-start: 25px;
            margin-block-end: 20px;
        }

        .more-sale {
            position: absolute;
            right: 20px;
            top: 40px;
            text-transform: uppercase;
            color: #000;
            font-weight: bold;
            text-decoration: underline;

            &:hover {
                color: red;
            }
        }

        .products {
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            @include max-screen($screen__m) {
                flex-direction: column;
            }

            .product {
                display: flex;
                flex-direction: column;
                width: 19%;

                @include max-screen($screen__m) {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .image,
                .stock,
                .name,
                .price {
                    text-align: center;
                }

                .stock {
                    .stock-bullet {
                        &:before {
                            content: '';
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            border-radius: 100%;
                        }
                        &.in-stock:before {
                            background: #1AB67C;
                        }
                        &.partial-stock:before {
                            background: $bs-color-partial-stock;
                        }
                        &.out-of-stock:before {
                            background: #CCC;
                        }
                    }
                }

                .name {
                    color: #000;
                    text-decoration: none;
                    text-transform: uppercase;
                    margin: 15px 0;
                }

                .price {
                    font-weight: bold;
                    margin-top: auto;
                    margin-bottom: 15px;

                    .price-label {
                        display: none !important;
                    }

                    .price-box {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: center;
                        align-items: center;
                    }

                    .special-price {
                        margin-left: 10px;
                    }
                }

                .button {
                    margin-top: auto;
                    margin-bottom: 0;
                    text-align: center;

                    background: #e0e0e0;
                    color: #000;
                    text-decoration: none;

                    @include max-screen($screen__l) {
                        padding: 12px 10px;
                    }
                }
            }
        }
    }

    &.new-events-lister {
        .upcoming-events {
            width: 100%;
            margin-right: 0;

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;
                    display: block;

                    @include max-screen($screen__m) {
                        width: 50%;
                    }

                    @include max-screen($screen__s) {
                        width: 100%;
                    }

                    .single-event {
                        padding: 20px;

                        .content {
                            display: flex;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    &.widget-trustpilot {
        background-color: #fff;
        padding: 15px;
        box-sizing: border-box;
    }

    &.widget-category-lister {
        background: #fff;
        padding: 15px;
        box-sizing: border-box;

        .category-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .category {
                width: 24%;
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include max-screen($screen__m) {
                    width: 50%;
                }

                @include max-screen($screen__s) {
                    width: 100%;
                }


                .image {
                    height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-height: 100%;
                    }
                }

                .cat-name {
                    background-color: #d4d4d4;
                    color: #000;
                    padding: 5px 20px;
                    text-align: center;
                    text-transform: uppercase;
                    border-radius: 4px;
                    margin-top: 20px;
                    max-width: 250px;
                    width: 100%;
                    box-sizing: border-box;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

// app2-224 changing headers for seo
.h2 {
    font-weight: 300;
    line-height: 1.1;
}
