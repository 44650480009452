.block.block-myaccount {
    margin-bottom: 0;
    grid-area: myaccount;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .dropdown {
        display: flex ;
        flex-direction: column;
        padding: 0;
        position: fixed;
        left: 2rem;
        right: 2rem;
        top: 6rem;
        transition: all .3s ease;

        &.loginopened {
            opacity: 1;
        }

        &:has(.customer-representative) {
            @media (min-width: $screen__m_min) {
                display: grid ;
                grid-template-columns: max-content 30rem;
            }
        }

        @media (min-width: $screen__m_min) {
            position: absolute;
            right: 0;
            top: 3rem;
            left: unset;
            min-width: 30rem;
        }

        h3 {
            margin: 0 0 2rem 0;
            padding-bottom: 0.5rem;
            color: $text__color__muted;
        }

        .customer-account-menu, .customer-representative {
            padding: 2rem;

            > *:not(h3) {
                line-height: 2.4rem;
                padding: 0;
            }
        }

        .customer-account-menu {
            @media(max-width: $screen__s_max) {
                order: 1;
            }
        }

        .customer-representative {
            box-shadow: none;

            @media(max-width: $screen__s_max) {
                order: 2;
                border-top: 1px solid #ccc;
                border-right: none;
            }

            border-right: 1px solid #aaa;
            background: #fafafa;
            padding: 2rem 4rem;
            display: grid;
            grid-template-areas:
                'title title'
            'image name'
            'image function'
            'image phone'
            'image email'
            'commentline commentline';
            font-size: 1.3rem;
            grid-template-columns: auto 1fr;
            grid-auto-rows: min-content; // fix height of grid
            align-items: start; // height of grid
            grid-column-gap: 2.4rem;
            cursor: initial;

            a {
                cursor: pointer;
            }

            h3 {
                grid-area: title;
            }

            .name {
                font-weight: bold;
                grid-area: name;
                font-size: 1.5rem;
            }

            .function {
                font-style: italic;
                display: inline-flex;
                grid-area: function;
            }

            .thumbnail-container {
                grid-area: image;
                $imagewidth: 100px;
                $imageheight: 100px;

                width: $imagewidth;
                height: $imageheight;
                max-width: $imagewidth;
                max-height: $imageheight;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: $imagewidth;
                    height: $imageheight;
                    border-radius: 50%;
                    box-shadow: inset 0 0px 6px 5px rgba(0, 0, 0, 0.2);
                }

                img {
                    object-fit: cover;
                    border-radius: 50%;
                    width: $imagewidth;
                    height: $imageheight;
                }
            }


            .phone:before, .email:before {
                font-family: "Font Awesome 5 Pro";
                margin-right: 1rem;
                font-weight: 600;
            }

            .phone {
                grid-area: phone;
                margin-top: 0.8rem;

                &:before {
                    content: fa-content($fa-var-phone-alt);
                }
            }

            .email {
                grid-area: email;

                &:before {
                    content: fa-content($fa-var-envelope);
                }
            }

            .commentline {
                grid-area: commentline;
                margin-top: 2rem;
                font-style: italic;
            }
        }

        .customer-account-menu {
            padding: 2rem 4rem;

            .nav.items li {
                padding: 0;

                a {
                    display: block;
                    margin-bottom: 3px;
                    padding: 3px;
                }
            }

            li.nav.item {
                display: flex;
                line-height: 2.4rem;
                margin-bottom: 0.3rem;




                &.current {
                    cursor: initial;

                    strong {
                        border-left: 0;
                        padding: 0;
                    }

                    &:before {
                        display: none !important; // don't animate
                    }
                }

                a {
                    padding: 0;
                    transition: all .3s ease;
                    width: 100%;
                    border-left: 0;

                    &:hover {
                        background: none;
                    }
                }

                &:before {
                    font-family: "Font Awesome 5 Pro";
                    content: fa-content($fa-var-arrow-right);
                    opacity: 0;
                    margin-left: -40px;
                    transition: all .3s ease;
                    position: absolute;
                }

                &:hover {
                    a {
                        background: none;
                        font-weight: 600;
                    }

                    &:before {
                        opacity: 0.3;
                        margin-left: -20px;
                    }
                }

            }

        }
    }
}

.app-dropdown {
    @include lib-dropdown(
        $_icon-font-text-hide: true,
        $_icon-font-size: 2.2rem,
        $_icon-font-line-height: 2.2rem,
        $_dropdown-list-min-width: 16rem,
        $_dropdown-list-background: #fff,
        $_dropdown-list-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .5),
        $_dropdown-list-border: none
    );

    @include max-screen($screen__m) {
        position: inherit;
    }

    .action {
        &.toggle {
            color: white;
            display: flex;
            align-items: center;


            .icon {
                &.loggedin {
                    display: inline-block;
                    width: 1.5rem;
                    height: 1.5rem;
                    -moz-border-radius: 0.75rem;
                    -webkit-border-radius: 0.75rem;
                    border-radius: 0.75rem;
                    background-color: green;
                    z-index: 99;
                    position: relative;
                    margin-right: -3.5rem;
                    margin-top: -2rem;
                    @media screen and (min-width: $screen__m_min) {
                        margin-right: -2.5rem;
                        margin-top: -2rem;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }

            .icon-user {
                width: 3.2rem;
                height: 3.2rem;
                fill: white;

                @media screen and (min-width: $screen__m_min) {
                    margin-right: 1rem;
                    height: 2.3rem;
                    width: 2.3rem;
                }
            }

            .text {
                user-select: none;
                font-weight: normal;
                color: white;
                display: none;
                margin-left: 0.3rem;

                @media screen and (min-width: $screen__m_min) {
                    display: initial;
                }

            }

            &:after {
                display: none;
            }
        }
    }

    & > ul.dropdown {
        display: block;
        visibility: hidden;
        border-radius: .2rem;
        opacity: 0;
        transform: translateY(-1.5rem);
        transition: all 150ms cubic-bezier(0.2, 0.79, 0.44, 1);
        right: 0;
        padding: 0.5rem;
        margin-top: 1.7rem;

        @media screen and (min-width: $screen__m_min) {
            right: initial;
        }

        &:before, &:after {
            content: none;
        }

        li {
            a {
                font-weight: 300;
                color: #000;
            }

            &:hover {
                background: none;

                a {
                    font-weight: 300;
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }

    &.active {
        ul.dropdown {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.noselect, .noselect:hover {
    cursor: initial;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}
