.gallery-placeholder {
    .loading-mask {
        position: static;
        padding: 0 0 50%;
    }

    .loader img {
        position: absolute;
    }
}

.gallery-placeholder:not(._block-content-loading) {
    // Hide placeholder when gallery is loaded
    .gallery-placeholder__image {
        display: none;
    }
}
