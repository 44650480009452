//
//  Quick Order Page Styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../_mixins';

//
//  Common
//  --------------------------------------------

@media all {
    .amasty_quickorder-index-index {
        .amqorder-grid-block {
            margin: $amqorder__indent__l * 2 0 0 0;
        }

        .amqorder-grid-block .amqorder-header {
            display: none;
        }
    }
}

//
//  Tablet
//  --------------------------------------------

@mixin media-width($extremum, $break) {
    @if ($extremum = 'min') and ($break = $screen__l) {
    .amasty_quickorder-index-index {
        .amqorder-grid-block .amqorder-header {
            display: flex;
        }

        .amqorder-header .amqorder-item.-item {
            min-width: 315px;
        }

        .amqorder-items-block .amqorder-item.-name {
            min-width: 210px;
        }
    }
}
}

//
//  Tablet +
//  --------------------------------------------

@mixin media-width($extremum, $break) {
    @if ($extremum = 'min') and ($break = $screen__xl) {
    .amasty_quickorder-index-index .amqorder-grid-block {
        margin: 0 $amqorder__indent__m * 2 0 0;
    }
}
}
