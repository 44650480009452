#social-login-popup {
    margin-top: 5rem;
    border-radius: 0;
    #amsl-popup-dialog {
        display: none;
    }

    .amsl-close {
        padding: 0.5rem;
        background-size: cover;
    }

    .amsl-tabs-wrapper {
        .amsl-tablist {
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 0.5rem solid transparent;
            -webkit-border-image: url('../images/separator_bg.svg') 30 round;
            -moz-border-image: url('../images/separator_bg.svg') 30 round;
            -o-border-image: url('../images/separator_bg.svg') 30 round;
            border-image: url('../images/separator_bg.svg') 30 round;
            .amsl-title {
                a {
                    color: #000000;
                }

                &.active {
                    a {
                        border-bottom: 1px solid #000;
                    }
                }
            }
        }
    }

    .action.primary {
        background: #ff0016;
        border: none;
        height: 38px;
        margin-right: 30px;
        padding: 7px 25px;
        min-width: 190px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        box-sizing: border-box;
        -webkit-border-radius: 0.2rem;
        -moz-border-radius: 0.2rem;
        border-radius: 0.2rem;
        vertical-align: middle;

        &:hover {
            background: #e2e2e2;
            border: 1px solid #cdcdcd;
            color: #555;
        }
    }

    .field {
        .control {
            width: 100%;
            input {
                border: 2px solid #e0e0e0;
                border-radius: 0;
                height: 38px;
                background: #fff;
                background-clip: padding-box;
                font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 14px;
                line-height: 1.42857;
                padding: 0 9px;
                vertical-align: baseline;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }

    //REGISTER TAB
    #amsl-register-content {
        .amsl-form {
            margin: 10px 40px;
        }
        .form-create-account {

            .actions-toolbar {
                margin: .5rem 0 .5rem 0;
                display:flex;
                flex-direction: row;
            }

            .field {
                .label {
                    width: auto;
                    color: #000;
                }

                &.choice.newsletter {
                    &:before {
                        padding: 0;
                        float: none;
                    }
                }

                #password-error {
                    white-space: initial;
                }
            }
        }

        .amsl-social-wrapper {
            display:none;
        }
    }

    // LOGIN TAB
    #amsl-login-content {
        .amsl-form {
            margin: 0 4rem;
        }
        .form.form-login {
            .fieldset.login {
                .secondary {
                    .action.remind {
                        color: #000000;
                    }
                }

                .field.choice {
                    display: none
                }
            }
        }
    }

    .form.password.forget {
        .fieldset {
            label {
                text-align: initial;
            }
        }
    }
}
