.block {
    &.block-minicart {
        border-radius: 3px;
        border: none;
        padding-bottom: 0;

        .subtotal {
            margin-top: 15px;

            .amount {
                .price {
                    font-size: 22px;
                }
            }
        }

        .product-item-details {
            .product-item-name {
                a {
                    color: #000;
                    text-decoration: underline;
                }
            }

        }

        .action {
            &.viewcart {
                text-transform: uppercase;
                color: #000;
                text-decoration: underline;
            }
        }

        .action.primary.checkout {
            background: #1AB67C;
            border: none;
        }
    }
}

.checkout-cart-index {
    .page-title-wrapper {
        margin: 0 0 2rem 0;
        padding-top: 4rem;

        h1 {
            margin: 0;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 26px;
        }
    }

    .columns {
        @include max-screen($screen__l) {
            .cart-summary {
                width: 33%;
            }
            .form-cart {
                width: 64%;
            }
        }

        @include max-screen($screen__m) {
            padding: 0;
            .column {
                &.main {
                    .cart-container {
                        display: flex;
                        flex-direction: column;

                        .cart-summary {
                            order: 8;
                            width: 100%;
                        }

                        .form-cart {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .cart-container {
        margin-bottom: 4rem;

        .cart.table-wrapper .actions-toolbar > .action {
            border: 2px solid $bs-dark-gray;
            border-radius: 3px;
            background: #fff;
            color: $bs-dark-gray;
            appearance: none;

            &:hover {
                background: $bs-dark-gray;
                color: #fff;
            }

            &.action-delete {
                background: #fff;
                border: 2px solid $bs-red;
                color: $bs-red;

                &:hover {
                    background: $bs-red;
                    color: #fff;
                }
            }
        }

        .col {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cart.table-wrapper {
            .items {
                > .item {

                    @include max-screen($screen__l) {
                        position: relative;
                        display: block;
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .cart.item {
            border: none;

            .item-info {
                border: 1px solid #dadada;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-around;
                color: black;

                .toggle-actions {
                    @media(min-width: $screen__l_min) {
                        display: none !important;
                    }
                }

                .col.item {
                    //padding: 15px 8px 10px 8px;
                    //max-width: 460px;
                }

                @include max-screen($screen__l) {
                    > .col.qty {
                        display: none;
                    }

                    .col.item {
                        max-width: inherit;
                        width: 100%;
                    }
                }
            }

            .product-item-name {
                padding: 0.5rem 0;
                a {
                    color: #000;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                font-size: 18px;
            }

            .product-item-photo {
                .product-image-container {
                    .product-image-photo {
                        // position: static;
                        max-height: unset;
                        width: 100%;
                        max-width: 7.5rem;
                        margin-top: 0.5rem;
                        margin-left: 0.5rem;

                        @include max-screen($screen__m) {
                            max-height: 60px;
                        }
                    }
                }
            }

            .product-item-details {
                vertical-align: middle;

                .stock {
                    font-size: 12px;
                    color: #6a6a6a;

                    .cart-item-sku {
                        margin-right: 10px;
                    }

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 11px;
                        height: 11px;
                        border-radius: 100%;
                    }

                    &.in-stock:before {
                        background: #1AB67C;
                    }

                    &.partial-stock:before {
                        background: $bs-color-partial-stock;
                    }

                    &.out-of-stock:before {
                        background: #CCC;
                    }
                }
            }

            .col.price {
                display: flex;
                align-items: center;
                min-width: 125px;
                justify-content: center;
                text-align: left;

                .price-including-tax + .price-excluding-tax {
                    color: #5a5a5a;
                }
            }

            .col.qty {
                display: flex;

                .qty-wrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .input-text {
                        margin-top: 0;
                        border: 1px solid #dadada;
                        width: 65px;
                    }

                    &:before {
                        display: none;
                    }

                    span.less {
                        border-color: #e0e0e0;
                        height: 36px;
                        width: 36px;
                        border-left: 1px solid #e0e0e0;
                        border-top: 1px solid #e0e0e0;
                        border-bottom: 1px solid #e0e0e0;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        background-color: #fff;
                        border-radius: 5px 0 0 5px;
                        transition: all .14s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            background-color: #e0e0e0;
                        }
                    }

                    span.add {
                        border-color: #e0e0e0;
                        height: 36px;
                        width: 36px;
                        border-right: 1px solid #e0e0e0;
                        border-top: 1px solid #e0e0e0;
                        border-bottom: 1px solid #e0e0e0;
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        background-color: #fff;
                        border-radius: 0 5px 5px 0;
                        transition: all .14s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            background-color: #e0e0e0;
                        }
                    }
                }
            }

            .item-actions {
                display: flex;
                padding: 0 20px 0 0;

                .actions-toolbar {
                    display: flex;

                    .action {
                        border: none;
                        font-weight: 400;
                        padding: 0;
                        width: inherit;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: transparent;
                        margin: 0;
                        font-size: 44px;

                        &.action-edit {
                            order: 3;
                            border: none;

                            span {
                                display: none;
                            }

                            &:before {
                                content: '';
                                background-image: url("../images/pen-to-square-regular.svg");
                                width: 2rem;
                                height: 2rem;
                            }

                            &:hover {
                                background: transparent;
                            }
                        }

                        &.action-delete {
                            order: 1;
                            border: none;

                            span {
                                display: none;
                            }

                            &:before {
                                content: "";
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                font-size: 44px;
                                line-height: 55px;
                                color: #2d2d2d;
                                font-family: "icons-blank-theme";
                                vertical-align: middle;
                                display: inline-block;
                                font-weight: normal;
                                overflow: hidden;
                                speak: none;
                                text-align: center;
                            }

                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                }
            }

            @include max-screen($screen__l) {
                .item-actions {
                    display: none;
                }

                .item-info {
                    display: flex;
                    flex-wrap: wrap;

                    .col {
                        &.item {
                            min-height: inherit;
                            order: 1;
                            margin: 0;
                            //padding: 10px;
                            display: flex;
                            align-items: center;

                            .product-item-photo {
                                display: block;
                                left: 0;
                                max-width: 60px;
                                padding: 0;
                                position: relative;
                                top: 0;
                                width: 100%;
                            }

                            .product-item-details {
                                white-space: normal;
                                padding: 0.5rem 2.5rem 0.5rem 1rem;

                                .stock-text {
                                    width: 11px;
                                    overflow: hidden;
                                    height: 20px;
                                }
                            }

                            .toggle-actions {
                                color: #2d2d2d;
                                transition: all .34s ease-in-out;
                                cursor: pointer;
                                position: absolute;
                                right: 10px;
                                top: 20px;

                                &.open {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        &.price {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            justify-content: flex-start;
                            order: 3;
                            background: #dadada;
                            font-size: 11px;
                            position: relative;
                            padding-top: 11px;
                            padding-bottom: 10px;

                            &:before {
                                padding: 0;
                                line-height: 1;
                            }

                            .total-price {
                                font-size: 11px;

                                .price-including-tax, .price-excluding-tax {
                                    display: block;
                                    font-size: 11px;
                                    line-height: 1;
                                    padding-left: 3px;
                                }

                                .price-including-tax + .price-excluding-tax {
                                    display: block;
                                    font-size: 11px;
                                    position: absolute;
                                    right: 10px;
                                    top: 11px;
                                }
                            }
                        }

                        &.subtotal {
                            order: 4;
                            background: #dadada;
                            float: none;
                            display: flex;
                            font-size: 13px;
                            align-items: center;
                            padding: 10px;
                            width: 100%;
                            font-weight: 800;

                            span {
                                font-size: 13px;
                                text-align: left;
                                order: 1;

                                &.price-including-tax {
                                    order: 2;
                                    text-align: right;
                                    margin-left: auto;
                                }
                            }

                            &:before {
                                display: none;
                            }
                        }

                    }
                }
                .item-actions {
                    display: flex;
                    width: 100%;
                    order: 10;
                    border-top: 1px solid #dadada;
                    background-color: #eaeaea;
                    border-bottom: 1px solid #dadada;
                    order: 2;
                    max-height: 0;
                    transition: all .34s ease-in-out;
                    overflow: hidden;
                    visibility: hidden;
                    opacity: 0;
                    padding: 0 5px;

                    &.open {
                        max-height: 1000px;
                        opacity: 1;
                        visibility: visible;
                        padding: 10px 5px;
                    }

                    .actions-toolbar {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;

                        .action {
                            &.action-edit {
                                order: 3;
                                border: none;
                                width: 50px;
                                height: 24px;
                            }

                            &.action-delete {
                                order: 1;
                                border: none;
                                width: 50px;
                                height: 24px;
                            }
                        }

                        .col.qty {
                            display: flex;
                            order: 2;
                            width: inherit;
                            margin: 0;
                            padding: 0;

                            &:before {
                                display: none;
                            }

                            .qty-wrap {
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }

        .form-cart {
            .action {
                border: none;

                &.continue {
                    background: #1AB67C;
                    color: #fff;
                }

                &.clear {
                    background: $bs-dark-gray;
                    color: #fff;
                    margin-left: 0;

                    @include min-screen($screen__s_min) {
                        float: right;
                    }
                }

                &.update {
                    background: $bs-dark-gray;
                    color: #fff;
                }

                &:after {
                    content: '';
                    clear: both;
                }
            }

            @include max-screen($screen__l) {
                .actions {
                    text-align: left;
                }
                .action {
                    &.continue {
                        background: none;
                        color: #000;
                        font-weight: 400;
                        margin: 0;
                    }

                    &.update {
                        background: none;
                        color: #000;
                        margin-left: 0;
                        font-weight: 400;
                        padding-left: 0;
                    }
                }
            }
        }

        .cart-summary {
            padding: 25px 15px;

            .title {
                margin-top: 0;
            }

            .actions-toolbar {
                .action {
                    border: none;
                    background: $bs-dark-gray;
                    color: #fff;
                    border-radius: 3px;
                }
            }

            .checkout.methods {
                li.item {
                    margin: 0;
                }

                button.action.primary.checkout {
                    background: #1AB67C;
                    border: none;
                    font-weight: normal;
                }

                a.action.multicheckout {
                    display: none;
                }
            }
        }
    }

    .checkout-method-items {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 9999;
        padding: 25px 0;
        background: #f5f5f5;
        border-top: 1px solid #dadada;

        button {
            width: 90%;
        }
    }

    .cart-container .cart-summary {
        margin-top: 70px;

        .cart-totals {
            border-top: none;

            .grand.totals.excl {
                display: none;
            }

            .grand.totals.incl {
                border-bottom: 1px solid #ddd;

                .mark {
                    padding-top: 5px;
                }

                .amount {
                    padding-right: 4px;
                    text-align: right;
                    font-size: 18px;
                    padding-top: 0;
                }
            }

            .totals-tax-summary {
                display: none;
            }

            //TICKET ID: 10417 CREATED BY Rick Bouma AT: 2020-06-30
            .totals-tax-details {
                display: none;
            }

            .totals .discount.coupon {
                display: none;
            }
        }

        .checkout-methods-items {
            margin-top: 20px;
            text-align: center;
            position: absolute;
            width: 100%;
            top: -90px;
            left: 0;

            .action.primary.checkout {
                span {
                    position: relative;
                    padding-right: 10px;

                    &::after {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 32px;
                        line-height: 25px;
                        color: inherit;
                        content: "";
                        font-family: "icons-blank-theme";
                        position: absolute;
                        right: -25px;
                        text-align: right;
                        top: 0;
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .products-crosssell {
        .secondary-addto-links {
            display: none;
        }

        .action.primary {
            background: $bs-green;
            border: none;;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-weight: 700;
            padding: 7px 15px;
            font-size: 1.4rem;
            box-sizing: border-box;
            vertical-align: middle;

            &:hover {
                background: $bs-lt-green;
            }
        }
    }

}

@include max-screen($screen__m) {
    .checkout-cart-index {
        .cart-container {
            .cart.item {
                .item-info {
                    .col.item {
                        display: grid;

                        .product-item-details {
                            white-space: normal;
                            padding: 0.5rem 2rem 0.5rem 1rem;

                            .product-item-name {
                                font-size: 14px;
                            }
                        }

                        .toggle-actions {
                            right: 15px;
                            top: 8px;
                        }
                    }
                }

                .stock-text {
                    position: relative;

                    span.stock-label {
                        position: absolute;
                        background-color: #2a2a2a;
                        color: #fff;
                        padding: 2px 10px;
                        width: 200px;
                        bottom: -28px;
                        left: -100px;
                        border-radius: 28px;
                        font-size: 10px;
                        text-align: center;
                        z-index: 99999;
                        display: none;

                        &::before {
                            z-index: -1;
                            height: 15px;
                            width: 15px;
                            position: absolute;
                            left: 98px;
                            top: -5px;
                            transform: rotate(45deg);
                            background: #2a2a2a;
                            content: "";
                        }
                    }

                    &:hover {
                        span.stock-label {
                            display: block;
                        }
                    }
                }
            }

            .cart-summary {
                margin-top: 15px;

                .checkout.methods.checkout-methods-items {
                    position: fixed;
                    width: 100%;
                    top: auto;
                    bottom: 0;
                    left: 0;
                    z-index: 999;
                    padding: 15px 0;
                    height: 55px;
                    background: #f5f5f5;
                    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.18);

                    li.item {
                        margin: 0 auto;
                        width: 90%;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .block.crosssell {
        .products-grid {
            .product-items {
                grid-auto-flow: row;
                grid-auto-columns: 100%;
            }
        }
    }
}

@include min-screen($screen__m) {
    .checkout-cart-index {
        .cart-container {
            .cart.item {
                .item-info {
                    .col.item {
                        display: grid;
                    }
                }
            }
        }
    }
}
