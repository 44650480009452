.navigation {
  li.level0 {
    position: relative;

    &:first-of-type > ul.level0.submenu {
      //display: block !important;
    }

    > ul.level0.submenu {
      min-width: initial;
      width: initial;
      padding: 1.2rem 0;

      > li.level1 {
        display: block;
        a {
          white-space: nowrap;
        }
      }
    }
  }
}
