body.page-with-filter {
    .filter-options-content {
        .items {
            li.item {
                cursor: pointer;

                a {
                    color: $color-gray-darken3;
                    font-weight: 400;
                    padding-left: 2rem;

                    .label {
                        position: relative;
                    }

                    &.am_shopby_link_selected {
                        .label {
                            color: green;

                            &:before {
                                font-family: "Font Awesome 5 Pro";
                                content: fa-content($fa-var-check);
                                color: red;
                                line-height: 0.5;
                            }
                        }
                    }
                }
            }

            &.-am-singleselect {
                .item a {
                    padding: 0;
                }
            }

        }

        .amshopby-flyout-block .items.amshopby-fly-out-view .item {
            padding: .5rem 2rem;

            a {
                padding: 0;
            }

            > .amshopby-choice {
                position: relative;
                top: inherit;
                left: inherit;
                margin: 0;
                padding-left: 1rem;
            }
        }

    }

    .filter-title strong {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: normal;
    }

    .filter-current .items {
        &:first-of-type {
            padding: 0;
        }

        li.item {
            border: 1px solid $bs-mlt-gray;
            border-radius: 4px;
            padding: 4px 10px;

            .amshopby-filter-name {
                font-weight: bold;
                padding-left: 1rem;

                &:after {
                    content: ':';
                    margin-right: 0.2rem;
                }
            }

            .amshopby-remove {
                justify-content: initial;
                position: initial;

                &:before {
                    content: '\000d7';
                    font-size: 2.5rem;
                    line-height: normal;
                    color: $bs-red;
                    transform: none;
                    height: initial;
                    background-color: initial;
                    margin: 0;
                }

                &:hover {
                    text-decoration: none;

                    &:before {
                        color: $bs-dark-gray;
                    }
                }

                &::after {
                    content: none;
                }
            }
        }
    }

    .block.filter {
        .block-subtitle.filter-subtitle {
            display: none;
        }
    }

    @media(max-width: $screen__s_max) {
        .close.toggle-layered-navigation {
            position: absolute;
            top: 0;
            right: 2rem;

            &:before {
                content: '\000d7';
                font-size: 4.2rem;
                color: #000;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .filter-options-content {
        .item {
            display: flex;
            align-items: center;
            padding: 0;

            > .amshopby-choice {
                margin: 0 !important;
            }

            &:hover {
                background: none !important; // amshopby-flyout-view override
            }
        }

        .-am-singleselect .amshopby-link-selected span.label {
            padding-left: 1.5rem;
            color: $color-blue1;

            &:before {
                content: '\000d7';
                font-size: 1.6rem;
                line-height: normal;
                color: #ff0016;
                transform: none;
                height: initial;
                background-color: initial;
                margin: 0 0.5rem 0 -1.5rem;
                position: absolute;
            }
        }

    }

    $checkbox-size: 1.5rem;

    .filter-options {
        input[type='checkbox'] + .amshopby-choice {
            width: $checkbox-size;
            height: $checkbox-size;
            min-height: $checkbox-size;
            top: 0;
        }

        input[type='checkbox'] + .amshopby-choice:before, input[type='checkbox'] + .amshopby-choice:after {
            background-size: $checkbox-size;
            width: $checkbox-size;
            height: $checkbox-size;
            top: unset;
            border: 1px solid $color-gray-darken3 !important; // ui-state-focus override
            box-sizing: border-box;
        }

        .count {
            white-space: pre;
        }
    }

}

