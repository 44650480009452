//
//  Uploader
//  _____________________________________

//
//  Imports
//  -------------------------------------

@import '../_mixins';

//
//  Common
//  -------------------------------------

@media all {
    .amfile-uploader-block {

            @include amqorder-flex(center, center, wrap);

            position: relative;
            height: 80px;
            border: 1px dashed #ccc;
            border-radius: 1px;
            color: $amqorder__color__primary;
            font-size: 12px;
            transition: .1s all;


        &:hover,
        &.-active {
            border-color: $amqorder__hover__color__primary;
        }

        &.-error {
            border-color: $amqorder__color__error;
            background: #fff6f6;
        }

        &:after,
        &:before {
            @include amqorder-flex(none, center);

            display: inline-flex;
            content: '';
            line-height: 20px;
            font-size: 1.6rem;
            font-weight: bold;
        }

        &:before {
            content: attr(data-label);
        }

        &:after {
            margin: 0 0 0 $amqorder__indent / 2;
            content: attr(data-file);
            font-size: 20px;
        }

        .amfile-input {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}
