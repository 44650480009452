//
//  Pager
//  _____________________________________

//
//  Imports
//  -------------------------------------

@import '../_mixins';

//
//  Common
//  -------------------------------------

@media all {
    .amqorder-pager-block {

            @include amqorder-flex();


        .amqorder-items {
            @include amqorder-flex(center, center);

            margin: 0;
            padding: 0 $amqorder__indent;
            list-style: none;
        }

        .amqorder-item:not(.-dotted) {

                @include amqorder-flex(center, center);
                @include amqorder-transition(.1);

                box-sizing: border-box;
                margin: 0;
                width: 40px;
                height: 40px;
                border: 1px solid transparent;
                cursor: pointer;


            &:hover {
                border: 1px solid $amqorder__color__primary;
                background: $bs-lt-green;
                color: #ff0016;
            }

            &:active {
                border-color: $amqorder__focus__color__primary;
                background: $amqorder__focus__color__primary;
            }
        }

        .amqorder-item.-dotted {
            @include amqorder-flex(center, center);

            margin: 0;
            width: 40px;
            height: 40px;
        }

        .amqorder-item.-selected {
            background: $amqorder__color__primary;
            color: #fff;
            font-weight: 700;
            pointer-events: none;
            user-select: none;
        }
    }
}
