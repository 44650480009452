.catalog-category-view:not(.page-layout-category-fitments),
.fitments-fitments-hardrace,
.fitments-fitments-index,
.page-products,
.page-with-filter,
.catalogsearch-result-index {
    .search__result-tabs {
        display: none;
    }

    .page-main {
        .columns {
            display: flex;

            .sidebar-left {
                padding-top: 20px;
                @include max-screen($screen__l) {

                }
                @include max-screen($screen__m) {
                    width: 100%;
                    padding-right: 0;
                }


                .sidebar-main {
                    width: 260px;
                    float: none;
                    @include max-screen($screen__m) {
                        width: 100%;
                    }
                    @media(min-width: $screen__m_min) {
                        padding-right: 20px;
                    }
                }

                .numberofproducts {
                    padding: 9px 20px;
                    margin: 30px -20px;
                    background: $bs-light-gray;
                    font-size: 16px;
                }

                .category-description-title {
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                .category-description p {
                    line-height: 1.6;
                }
            }

            .column.main {
                float: none;
                flex-basis: 100px;
                flex-grow: 1;
                padding: 0;
                border-left: none;

                @media(min-width: $screen__m_min) {
                    &:not(.oem-main) {
                        border-left: 2px solid $bs-light-gray;
                        padding: 2rem;
                    }
                    margin-right: -20px;
                }

                h1 {
                    font-size: 26px;
                    text-transform: uppercase;
                    font-weight: normal;
                    margin: 0 0 20px;
                }

                .toolbar {
                    display: grid;
                    align-items: center;
                    padding: 0;
                    position: relative;
                    grid-template-areas: 'sorter' 'pages' 'limiter';
                    grid-template-columns: 1fr;
                    grid-gap: 1.6rem;
                    justify-content: space-between;

                    @media(min-width: $screen__xs_min) {
                        grid-template-areas: 'sorter limiter' 'pages pages';
                        grid-template-columns: max-content auto;
                    }


                    @media(min-width: $screen__l_min) {
                        grid-template-areas: 'sorter limiter pages';
                        grid-template-columns: max-content max-content 1fr;
                    }

                    &:after {
                        position: absolute;
                        height: 6px;
                        bottom: -20px;
                        width: 100%;
                        background: url('../images/svg-icons.svg#use-icon-hr') repeat;
                        background-size: 6px 7px;
                    }

                    .modes {
                        display: none;
                    }

                    .toolbar-sorter {
                        padding-top: 0;
                    }

                    .limiter {
                        padding: 0;
                        margin: 0;
                        display: block;
                        grid-area: limiter;
                    }

                    .sorter {
                        padding: 0;
                        margin: 0;
                        display: block;
                        grid-area: sorter;
                    }

                    .pages {
                        padding: 0;
                        margin: 0;
                        display: block;
                        grid-area: pages;
                        @media(min-width: $screen__l_min) {
                            margin-left: auto;
                        }

                        .item {
                            &.current,
                            a {
                                color: #333;
                                padding: 0;
                                width: 30px;
                                height: 30px;
                                border: 2px solid $bs-light-gray;
                                margin: 0 0 0 10px;
                                font-size: 12px;
                                font-weight: normal;
                            }

                            &.pages-item-next,
                            &.pages-item-previous {
                                a {
                                    &:before {
                                        width: 6px;
                                        height: 10px;
                                        margin: 10px 0 0 0;
                                        overflow: hidden;
                                        content: url('../images/svg-icons.svg#use-icon-caret-right');
                                    }
                                }
                            }

                            &.pages-item-previous {
                                a {
                                    &:before {
                                        margin-top: 11px;
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }

                        @include max-screen($screen__m) {
                            margin: 15px auto 0;
                        }

                    }
                }

                .products.wrapper ~ .toolbar {
                    &:after {
                        display: none;
                    }

                    .sorter {
                        display: block;
                    }
                }
            }
        }
    }
}

.catalog-category-view,
.fitments-fitments-hardrace,
.catalogsearch-result-index,
.page-with-filter {
    .page-wrapper {
        .page-main {
            .page-title-wrapper {
                padding-top: 2rem;

                .page-title {
                    margin-bottom: 2rem;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.catalog-category-view, .fitments-fitments-hardrace {
    .page-wrapper {
        .page-main {
            .category-view {
                .category-cms {
                    h4.subtitle {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .bbk-cat-box {
                        @include max-screen($screen__l) {
                            width: 49%;
                        }
                        @include max-screen($screen__m) {
                            width: 100%;
                        }
                    }

                    .cta-button {
                        a {
                            font-size: 16px;
                        }

                        @include max-screen($screen__m) {
                            width: 90%;
                            max-width: 300px;
                            padding: 15px 0;

                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.category-big-brake-kits, .category-performance-pads, .category-rotor-kits {
    .page-main {
        .category-cms {
            padding: 15px 0;
        }

        .columns {
            //display: none !important;
        }
    }
}

.action.primary.toggle-layered-navigation {
    display: none;
    background-color: $bs-red;
    border: 0px solid $bs-red;
    color: #FFF;
    text-transform: uppercase;
    padding: 10px 20px;
    height: 38px;
    border-radius: 2px;
    font-weight: normal;
    line-height: 15px;
    font-size: 15px;
    width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: 0;

    &:first-child {
        display: none !important; // don't show if there's no filters block
    }

    @include max-screen($screen__m) {
        display: block;
        margin-bottom: 15px;
        margin-right: auto;
        width: 100%;
    }

    &:hover {
        background: #e2e2e2;
        border: 1px solid #cdcdcd;
        color: #555;
    }
}

.block.filter {
    @include max-screen($screen__m) {
        display: none;
    }
}

.sidebar-left {
    .category-description-title, .category-description {
        @include max-screen($screen__m) {
            display: none;
        }
    }
}
