//
//  Variables
//  _____________________________________________

// Global variables
@import 'variables/colors';
@import 'variables/typography';
@import 'variables/icons';

// All variables
@import 'variables/actions-toolbar';
@import 'variables/breadcrumbs';
@import 'variables/buttons';
@import 'variables/components';
@import 'variables/dropdowns';
@import 'variables/forms';
@import 'variables/layout';
@import 'variables/loaders';
@import 'variables/messages';
@import 'variables/navigation';
@import 'variables/pages';
@import 'variables/popups';
@import 'variables/rating';
@import 'variables/responsive';
@import 'variables/sections';
@import 'variables/structure';
@import 'variables/tables';
@import 'variables/tooltips';
