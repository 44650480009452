.fitments-search-wrapper {
  background: $bs-dark-gray;
  flex-basis: 100%;
  width: 100%;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;

  .fitments-search-inner {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @include max-screen($screen__s) {
      //display: none;

      &.active {
        display: flex;
      }
    }

    .loading-mask {
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      overflow: hidden;

      &.active {
        display: flex;
      }
    }
  }

  .search-fitments,
  .block-search {
    display: none;

    &.active {
      display: block;
      z-index: 2;
    }
  }



  .search-fitments {
    width: 100%;

    .fitments-container {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 9;

      @include max-screen($screen__m) {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .fitments-mobile {
        display: none;
      }

      @include max-screen($screen__s) {
        flex-direction: column;

        .fitments-not-mobile {
          display: none;
        }

        .fitments-mobile {
          display: block;

          .select-wrap {
            width: 100%;
            position: relative;

            select {
              margin: 0 0 15px;
              background-color: #a2a3a3;
              border-color: #a2a3a3;
              border-radius: 2px;
              -moz-appearance: none;
              /* for Chrome */
              -webkit-appearance: none;
            }
            &::after {
              display: block;
              position: absolute;
              content: '';
              width: 18px;
              height: 18px;
              background: url(../images/svg-icons.svg#use-icon-chevron-down);
              right: 15px;
              top: 10px;
            }
          }
        }
      }

      .fitments-menu-wrapper {
        position: relative;
        display: flex;
        flex-grow: 1;
        z-index: 999;

        .fitment-selections {
          display: flex;
          justify-content: space-between;
          flex-grow: 1;

          @include max-screen(1130px) {
            flex-wrap: wrap;
            padding: 8px 0;
          }

          a.button-fitment-select {
            padding: 0 15px;
            display: flex;
            align-items: center;
            background: #a2a3a3;
            color: #000;
            flex-grow: 1;
            flex-basis: 100px;
            margin-right: 25px;
            position: relative;
            border: 2px solid #a2a3a3;
            text-transform: none;
            min-width: 190px;

            &:not([data-selected-category-id]) {
              &:not([data-selector-role="brand"]) {
                pointer-events: none;
              }
            }

            input {
              background: #a2a3a3;
              color: #000;
              border: 0;
              margin: 0 -15px;
              height: 34px;
              outline: none;

              &:hover,
              &:focus,
              &:active {
                text-decoration: none;
                //background: rgba(255, 255, 255, 0.7);
                background: #fff;
                box-shadow: none;
              }

              &::-webkit-input-placeholder {
                color:    #000;
              }
              &:-moz-placeholder {
                color:    #000;
                opacity:  1;
              }
              &::-moz-placeholder {
                color:    #000;
                opacity:  1;
              }
              &:-ms-input-placeholder {
                color:    #000;
              }
              &::-ms-input-placeholder {
                color:    #000;
              }
            }

            @include max-screen(1130px) {
              min-width: 45%;
              margin: 0 8px;

              &:nth-child(n+3) {
                margin-top: 8px;
              }
            }

            @include max-screen($screen__s) {
              margin: 10px;
            }

            &:hover,
            &.active, &:first-of-type {
              text-decoration: none;
              //background: rgba(255, 255, 255, 0.7);
              background: #fff;
              border: 2px solid #c7c8c8;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              input {
                background: #fff;
              }
            }

            &:hover {
              border-bottom: 2px solid #c7c8c8;
            }

            &.active {
              border-bottom: 2px solid #fff;
            }

            &:after {
              display: block;
              position: absolute;
              content: '';
              width: 18px;
              height: 18px;
              background: url('../images/svg-icons.svg#use-icon-chevron-down');
              right: 15px;
              top: 8px;
            }
          }
        }

        @include max-screen($screen__m) {
          display: none;
        }
      }

      .fitment-menus {
        top: 100%;
        position: absolute;
        background: #fff;
        width: 100%;
        overflow: hidden;
        z-index: 100;

        max-height: 700px;
        overflow: auto;
        left: 0;

        -webkit-overflow-scrolling: touch;

        @include max-screen($screen__s) {
          top: 0%;
        }

        .fitment-menu {
          display: none;
          padding: 30px 25px;
          column-count: 7;
          column-fill: auto;
          -moz-column-fill: balance;

          border: 2px solid #c7c8c8;
          border-top: none;

          @include max-screen($screen__m) {
            column-count: 1;
          }

          &.no-index {
            column-count: 1;
          }

          &.show {
            display: flex;
            max-height: 120vh;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-top: 70px;
            min-height: 100px;
            background: #fff;

            &[data-selector-role="brand"] {
              max-height: 60vh;
            }



            > * {
              z-index: 99;
            }

            &:after {
              content: "";
              background: rgba(0,0,0,.4);
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              position: fixed;
              z-index: -1;
            }
          }

          //.search {
          //    position: absolute;
          //    width: 90%;
          //    top: 25px;
          //
          //    @include max-screen($screen__m) {
          //        display: none;
          //    }
          //}

          .close-fitments-menu {
            position: absolute;
            right: 40px;
            top: 30px;
            font-size: 20px;
            color: $bs-light-gray;

            &:hover {
              cursor: pointer;
              color: $bs-md-gray;
            }
          }

          .search {
            position: absolute;
            width: 95%;
            top: 25px;

            @include max-screen($screen__m) {
              display: none;
            }
          }

          section {
            //width: 12%;
            padding: 0 1rem 0 1rem;

            &.fullwidth {
              width: 100%;
            }

            h5 {
              color: $bs-red;
              text-transform: uppercase;
              font-size: 18px;
            }

            ul {
              padding: 0;
              list-style: none;

              li {
                margin-bottom: .4rem;

                @include max-screen($screen__s) {
                  margin-bottom: .8rem;
                }

                a {
                  color: #000;
                }
              }
            }
          }
        }

        .fitment-select-wrap {
          display: none;
        }
        @include max-screen($screen__m) {
          background: none;
          position: relative;
          margin-top: 15px;
          margin-bottom: 15px;

          .fitment-menu {
            display: block;
            border: none;
            padding: 0 25px;

            section {
              display: none;
            }

            .fitment-select-wrap {
              position: relative;
              width: 100%;
              display: block;
              column-count: inherit;

              select {
                background-color: #a2a3a3;
                border-radius: 2px;
                border: 0;
                height: 34px;
                outline: none;
                width: 100%;
                margin: 10px 0;
                -moz-appearance: none;
                /* for Chrome */
                -webkit-appearance: none;
              }

              &:after {
                display: block;
                position: absolute;
                content: '';
                width: 18px;
                height: 18px;
                background: url(../images/svg-icons.svg#use-icon-chevron-down);
                right: 15px;
                top: 18px;
              }
            }
          }
        }
      }
    }

    .fitment-select {
      color: #000;
      width: 220px;
      margin-right: 20px;
      border: 0;
      border-radius: 2px;
      background: $bs-mlt-gray url('../images/svg-icons.svg#use-icon-chevron-down') no-repeat calc(100% - 10px) 50% / 18px 18px;
    }

    .action.primary {
      background: $bs-red;
      border: none;
      height: 38px;
      margin-right: 30px;
      padding: 7px 25px;
      min-width: 190px;

      &.disabled {
        opacity: 1;
        background: $bs-light-gray;
      }

      span {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 15px;
      }
    }

    .nested {
      position: relative;
      padding: 0;
      margin-left: auto;
      display: flex;
      flex-direction: column;

      .action.listed {
        color: $bs-mlt-gray;
      }

      a {
        font-size: 11px;
        text-transform: uppercase;
        color: #fff;

        @include max-screen($screen__m) {
          margin: 20px 0;
          display: block;
        }
      }
    }

    .actions-wrapper {
      display: flex;
      align-items: center;

      @include max-screen(1130px) {
        flex-direction: column;
        margin-right: 8px;

        .action.primary {
          margin: 0;
          margin-bottom: 5px;
        }

        .nested {
          margin: 0;
        }
      }

      @include max-screen($screen__m) {
        margin-right: 0;
        width: 100%;
        padding: 0 25px;
        box-sizing: border-box;

        button {
          width: 100%;
        }
      }
    }
  }

  .block-search {
    width: 100%;
    padding: 0;

    @include max-screen($screen__m) {
      padding: 0 15px;
    }

    .field.search {
      display: flex;
      flex-grow: 1;
      margin-right: 20px;
    }
    .control {
      padding: 0;
      flex-grow: 1;
    }

    .form.minisearch {
      display: flex;
      align-items: center;

      .mst-searchautocomplete__close {
        display: none;
      }

      .mst-searchautocomplete__wrapper {
        position: absolute;
        background: #fff;
        margin-top: 20px;
        padding: 20px;
        left: 0;
        right: 0;
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.23);;

        .mst-searchautocomplete__index {
          ul {
            padding: 0;
            margin: 14px 0;

            li.mst-searchautocomplete__item.magento_catalog_product {
              list-style-type: none;
              margin: 0 0 20px;
              padding: 0;
              display: flex;
              flex-grow: 0;
              align-items: center;
              position: relative;
              justify-content: flex-start;

              img {
                margin-right: 20px;
              }

              .store {
                min-width: 65px;
                font-weight: 700;
                font-size: 16px;
                margin-left: auto;
              }

              .title {
                margin-bottom: 0;
                margin-left: 15px;
                margin-right: auto;
                a {
                  text-transform: uppercase;
                  color: #333;
                  font-size: 16px;
                }
              }

              .description {
                display: none;
              }
            }
          }
        }
      }
    }

    .input-text {
      padding: 0 9px;
      position: relative;
      left: 0;
    }

    .action.search {
      @extend .button;
      position: relative;
      right: 0;

      &[disabled] {
        opacity: 1;
        background: $bs-light-gray;
      }

      &:before {
        display: none;
      }

      span {
        clip: inherit;
        height: inherit;
        margin: inherit;
        overflow: inherit;
        padding: 0;
        position: inherit;
        width: inherit;
      }
    }

    .nested {
      position: relative;
      padding: 0;
      margin-left: 25px;

      a {
        font-size: 11px;
        text-transform: uppercase;
        color: #fff;
      }
    }

    .searchautocomplete__autocomplete {
      position: absolute;
      background: #fff;
    }

    @include max-screen($screen__m) {
      .label {
        display: none;
      }

      .control {
        border-top: none;
      }

      .action.search {
        display: block;
      }
    }
  }
}
