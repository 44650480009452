.country-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;

    h2, .h2 {
        font-size: 3.6rem;
        text-align: center;
        font-weight: 300;
        line-height: 1.1;
    }

    .country-modal-content {
        width: 400px;
        background: #FFF;
        border-radius: 3px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            max-height: 65px;
            margin: 0 0 15px 0;
            border-radius: 5px;
        }

        h2, .h2 {
            margin: 5px 0 10px 0;
        }

        p {
            display: block;
        }

        .actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 20px 0 0 0;

            > a {
                color: #FFF;
                text-decoration: none;
                margin-bottom: 5px;
                font-size: 14px;
                height: auto;
                padding: 18px 24px;
            }

            &:hover {
                color: #000;

            }


            .stay {
                color: #000;
                text-decoration: underline;
                font-size: 14px;
                margin-top: 10px;

                a {
                    color: #000;
                }
            }
        }
    }
}
