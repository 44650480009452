.cms-page-view {
    .page-title-wrapper {
        h1.page-title {
            font-weight: normal;
            font-size: 28px;
            text-transform: uppercase;
            margin: 20px 0 20px 0;
        }
    }

    h1 {
        font-weight: normal;
        font-size: 28px;
        text-transform: uppercase;
        margin: 20px 0 20px 0;
    }

    h3 {
        font-weight: normal;
        text-transform: uppercase;
        margin: 15px 0 5px 0;
    }

    .columns {
        .main {
            a {
                color: #333;
                text-decoration: underline;
            }
        }
    }

    &.page-layout-2columns-left {
        .columns {
            flex-direction: column;

            @media (min-width: $screen__m_min) {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                flex-wrap: nowrap;
            }

            .column {
                &.main {
                    padding: 0;
                    flex-grow: 1;
                    min-width: 75%;

                    @media (min-width: $screen__m_min) {
                        padding: 0 0 0 2rem;
                    }
                }

                &.main + .sidebar {
                    border-right: 2px solid $bs-light-gray;
                }
            }
        }
    }

    &.page-layout-2columns-right {
        .columns {
            flex-direction: column;

            @media (min-width: $screen__m_min) {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                flex-wrap: nowrap;
            }

            .column.main {
                display: flex;
                order: 1;
                flex-direction: column;
                width: 100%;

                @media (min-width: $screen__m_min) {
                    width: 70%;
                }
            }

            .sidebar, .sidebar-additional {
                display: flex;
                order: 2;
                min-width: 100%;
                width: 100%;
                flex-basis: unset;
                margin-top: 3rem;

                @media (min-width: $screen__m_min) {
                    min-width: 30%;
                    width: 30%;
                    margin-top: 0;
                }

                .widget.home-sidebar {
                    padding: 0;
                    display: block;
                    width: 100%;

                    h3 {
                        font-weight: 600;
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    .cms-menu {
        margin: 20px 0;

        h3 {
            color: #000;
            font-weight: normal;
            margin: 0 0 10px 0;
            font-size: 1.8rem;
        }

        ul {
            list-style: none;
            padding: 0 0 0 10px;
            margin: 0 0 25px 0;

            li {
                padding: 0 0 0 15px;
                position: relative;

                &:before {
                    position: absolute;
                    content: '-';
                    left: 0;
                }

                a {
                    color: #333;
                }
            }
        }
    }
}

.sitemap-index-index {
    .page-title {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .columns {
        padding: 0 0 50px;
    }
}

body[class*="cms-support"] {
    .sidebar {
        @media (max-width: $screen__m_max) {
            border-right: none !important;
            order: 0;
        }
    }

    .cms-menu-title {
        padding: 1rem 0;
        border-bottom: 0.1rem solid #898989;
        border-top: 0.1rem solid #898989;
        position: relative;
        text-align: center;

        @media(min-width: $screen__m_min) {
            display: none;
        }

        &:before {
            font-family: "Font Awesome 5 Pro";
            content: fa-content($fa-var-bars);
            font-size: 1.4rem;
            position: absolute;
            left: 0;
        }

        &:after {
            font-family: "Font Awesome 5 Pro";
            content: fa-content($fa-var-chevron-right);
            font-size: 1.4rem;
            position: absolute;
            right: 0.6rem;
            transform: rotate(90deg);
            transition: all .2s;
        }
        &[aria-selected="true"] {
            border-bottom: 0;
            &:after {
                transform: rotate(-90deg);
            }
        }
    }

    .cms-menu {
        &[aria-hidden="false"] {
            border-bottom: 0.1rem solid #898989;
        }

        @media(min-width: $screen__m_min) {
            display: block !important; // fix to avoid empty sidebar
        }
    }
}
