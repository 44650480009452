@include flag-icon(ad);
@include flag-icon(ae);
@include flag-icon(af);
@include flag-icon(ag);
@include flag-icon(ai);
@include flag-icon(al);
@include flag-icon(am);
@include flag-icon(ao);
@include flag-icon(aq);
@include flag-icon(ar);
@include flag-icon(as);
@include flag-icon(at);
@include flag-icon(au);
@include flag-icon(aw);
@include flag-icon(ax);
@include flag-icon(az);
@include flag-icon(ba);
@include flag-icon(bb);
@include flag-icon(bd);
@include flag-icon(be);
@include flag-icon(bf);
@include flag-icon(bg);
@include flag-icon(bh);
@include flag-icon(bi);
@include flag-icon(bj);
@include flag-icon(bl);
@include flag-icon(bm);
@include flag-icon(bn);
@include flag-icon(bo);
@include flag-icon(bq);
@include flag-icon(br);
@include flag-icon(bs);
@include flag-icon(bt);
@include flag-icon(bv);
@include flag-icon(bw);
@include flag-icon(by);
@include flag-icon(bz);
@include flag-icon(ca);
@include flag-icon(cc);
@include flag-icon(cd);
@include flag-icon(cf);
@include flag-icon(cg);
@include flag-icon(ch);
@include flag-icon(ci);
@include flag-icon(ck);
@include flag-icon(cl);
@include flag-icon(cm);
@include flag-icon(cn);
@include flag-icon(co);
@include flag-icon(cr);
@include flag-icon(cu);
@include flag-icon(cv);
@include flag-icon(cw);
@include flag-icon(cx);
@include flag-icon(cy);
@include flag-icon(cz);
@include flag-icon(de);
@include flag-icon(dj);
@include flag-icon(dk);
@include flag-icon(dm);
@include flag-icon(do);
@include flag-icon(dz);
@include flag-icon(ec);
@include flag-icon(ee);
@include flag-icon(eg);
@include flag-icon(eh);
@include flag-icon(er);
@include flag-icon(es);
@include flag-icon(et);
@include flag-icon(fi);
@include flag-icon(fj);
@include flag-icon(fk);
@include flag-icon(fm);
@include flag-icon(fo);
@include flag-icon(fr);
@include flag-icon(ga);
@include flag-icon(gb);
@include flag-icon(gd);
@include flag-icon(ge);
@include flag-icon(gf);
@include flag-icon(gg);
@include flag-icon(gh);
@include flag-icon(gi);
@include flag-icon(gl);
@include flag-icon(gm);
@include flag-icon(gn);
@include flag-icon(gp);
@include flag-icon(gq);
@include flag-icon(gr);
@include flag-icon(gs);
@include flag-icon(gt);
@include flag-icon(gu);
@include flag-icon(gw);
@include flag-icon(gy);
@include flag-icon(hk);
@include flag-icon(hm);
@include flag-icon(hn);
@include flag-icon(hr);
@include flag-icon(ht);
@include flag-icon(hu);
@include flag-icon(id);
@include flag-icon(ie);
@include flag-icon(il);
@include flag-icon(im);
@include flag-icon(in);
@include flag-icon(io);
@include flag-icon(iq);
@include flag-icon(ir);
@include flag-icon(is);
@include flag-icon(it);
@include flag-icon(je);
@include flag-icon(jm);
@include flag-icon(jo);
@include flag-icon(jp);
@include flag-icon(ke);
@include flag-icon(kg);
@include flag-icon(kh);
@include flag-icon(ki);
@include flag-icon(km);
@include flag-icon(kn);
@include flag-icon(kp);
@include flag-icon(kr);
@include flag-icon(kw);
@include flag-icon(ky);
@include flag-icon(kz);
@include flag-icon(la);
@include flag-icon(lb);
@include flag-icon(lc);
@include flag-icon(li);
@include flag-icon(lk);
@include flag-icon(lr);
@include flag-icon(ls);
@include flag-icon(lt);
@include flag-icon(lu);
@include flag-icon(lv);
@include flag-icon(ly);
@include flag-icon(ma);
@include flag-icon(mc);
@include flag-icon(md);
@include flag-icon(me);
@include flag-icon(mf);
@include flag-icon(mg);
@include flag-icon(mh);
@include flag-icon(mk);
@include flag-icon(ml);
@include flag-icon(mm);
@include flag-icon(mn);
@include flag-icon(mo);
@include flag-icon(mp);
@include flag-icon(mq);
@include flag-icon(mr);
@include flag-icon(ms);
@include flag-icon(mt);
@include flag-icon(mu);
@include flag-icon(mv);
@include flag-icon(mw);
@include flag-icon(mx);
@include flag-icon(my);
@include flag-icon(mz);
@include flag-icon(na);
@include flag-icon(nc);
@include flag-icon(ne);
@include flag-icon(nf);
@include flag-icon(ng);
@include flag-icon(ni);
@include flag-icon(nl);
@include flag-icon(no);
@include flag-icon(np);
@include flag-icon(nr);
@include flag-icon(nu);
@include flag-icon(nz);
@include flag-icon(om);
@include flag-icon(pa);
@include flag-icon(pe);
@include flag-icon(pf);
@include flag-icon(pg);
@include flag-icon(ph);
@include flag-icon(pk);
@include flag-icon(pl);
@include flag-icon(pm);
@include flag-icon(pn);
@include flag-icon(pr);
@include flag-icon(ps);
@include flag-icon(pt);
@include flag-icon(pw);
@include flag-icon(py);
@include flag-icon(qa);
@include flag-icon(re);
@include flag-icon(ro);
@include flag-icon(rs);
@include flag-icon(ru);
@include flag-icon(rw);
@include flag-icon(sa);
@include flag-icon(sb);
@include flag-icon(sc);
@include flag-icon(sd);
@include flag-icon(se);
@include flag-icon(sg);
@include flag-icon(sh);
@include flag-icon(si);
@include flag-icon(sj);
@include flag-icon(sk);
@include flag-icon(sl);
@include flag-icon(sm);
@include flag-icon(sn);
@include flag-icon(so);
@include flag-icon(sr);
@include flag-icon(ss);
@include flag-icon(st);
@include flag-icon(sv);
@include flag-icon(sx);
@include flag-icon(sy);
@include flag-icon(sz);
@include flag-icon(tc);
@include flag-icon(td);
@include flag-icon(tf);
@include flag-icon(tg);
@include flag-icon(th);
@include flag-icon(tj);
@include flag-icon(tk);
@include flag-icon(tl);
@include flag-icon(tm);
@include flag-icon(tn);
@include flag-icon(to);
@include flag-icon(tr);
@include flag-icon(tt);
@include flag-icon(tv);
@include flag-icon(tw);
@include flag-icon(tz);
@include flag-icon(ua);
@include flag-icon(ug);
@include flag-icon(um);
@include flag-icon(us);
@include flag-icon(uy);
@include flag-icon(uz);
@include flag-icon(va);
@include flag-icon(vc);
@include flag-icon(ve);
@include flag-icon(vg);
@include flag-icon(vi);
@include flag-icon(vn);
@include flag-icon(vu);
@include flag-icon(wf);
@include flag-icon(ws);
@include flag-icon(ye);
@include flag-icon(yt);
@include flag-icon(za);
@include flag-icon(zm);
@include flag-icon(zw);
