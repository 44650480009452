.page-product-bundle {

    * {
        order: unset;
    }

    .bundle-options-wrapper, #bundleSummary {
        float: unset !important;
    }

    .bundle-options-container, .product-add-form {
        display: block;
    }

    .bundle-options-container {
        clear: both;
        margin-bottom: 2rem;
    }

    label[for="qty"] {
        display: none !important; //fy magento
    }

    #bundleSummary {
        .product-addto-links {
            display: block;
        }
    }

    // reset magento default floating and spacing
    .product-add-form,
    .bundle-options-wrapper,
    .product-options-wrapper,
    .block-bundle-summary {
        max-width: unset !important;
        width: unset !important;
    }

    .product-info-main {
        max-width: unset !important; // for mobile
    }

    .product-info-price > .stock {
        display: none;
    }

    .product-options-wrapper {
        .fieldset-bundle-options {
            margin-bottom: 1rem;

            > .field.option {
                all: unset;

                > .control {
                    display: grid;
                    grid-template-columns: auto 1fr auto auto;
                    align-items: center;
                    gap: 1.2rem;

                    .price-notice {
                        display: none; // this maybe added if qty is selectable
                    }
                }

                .nested .field {
                    margin: 0;
                }
            }
        }
    }

    p.required {
        display: none; // not sure
    }

    #bundleSummary {
        all: unset;
        background: #e8e8e8;
        display: block;
        padding: 1rem 2rem;

        .bundle-info {
            display: block;

            .product-details {
                display: flex;
                align-items: center;
                column-gap: 1.6rem;
                width: 100%;
                bottom: 0;

                .available.stock {
                    min-width: fit-content;
                    margin: 0;
                }
            }
        }
    }

    .product-details {
        .box-tocart {
            margin: 0;
        }

        .actions {
            margin: 0 !important; //override tops and bottoms
        }
    }


}
