//
//    Desktop
//--------------------------------------
#search_autocomplete, .search-autocomplete, #searchbox_autocomplete {
    display: none !important;
    height: 0 !important;
    overflow: hidden !important;;
}

// compatibility with porto theme
.block-search {
    // z-index: 10000;
}


//
//    Common
//--------------------------------------
$spinner-color: #999999;
$header-bg: #efefef;
$hr-color: #f6f6f6;
$hover-bg: #f8f8f8;
$mute-color: #777777;
$text-color: #333333;
$higlight-color: #1ba1fc;
$border-color: #c2c2c2;

.mst-searchautocomplete__autocomplete {
    display: none !important;
    border: 1px solid $border-color;
    position: absolute;
    top: 3.1rem;
    left: 0;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 3px;
    min-height: 3.5rem;
    z-index: 1000;
    text-align: left;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    margin-top: .7rem;

    &._active {
        display: block !important;
    }

    .mst-searchautocomplete__wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        max-height: 70vh;
    }

    .mst-searchautocomplete__results {
        height: 100%;
        overflow-y: auto;

        &.__all {
            margin-bottom: 4rem;
        }
    }

    .mst-searchautocomplete__spinner {
        display: none;
        position: absolute;
        top: .5rem;
        right: 1rem;
    }

    &._loading {
        .mst-searchautocomplete__spinner {
            display: block;
        }
    }

    .mst-searchautocomplete__empty-result {
        background: $header-bg;
        text-align: center;
        padding: .8rem 1rem;
        color: $mute-color;
        font-size: 1.2rem;
        display: block;
    }

    .mst-searchautocomplete__show-all {
        border-top: 1px solid $header-bg;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4rem;

        a {
            font-weight: 600;
            padding: 1rem 0;
            color: $mute-color;
            font-size: 1.2rem;
            display: block;
        }
    }

    .mst-searchautocomplete__index-title {
        border-bottom: 1px solid $header-bg;
        padding: 1rem 0 .9rem 0;
        margin: 0 1rem;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $mute-color;
        text-transform: uppercase;
        font-weight: 700;
    }

    .mst-searchautocomplete__close {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            @include lib-clearfix();

            margin: 0;
            padding: .7rem 1rem;
            cursor: pointer;

            a.title {
                font-weight: 500;
                margin-bottom: 0;
                color: $text-color;
                text-decoration: none;
            }

            &._active {
                background: $hover-bg;

                a.title {
                    text-decoration: underline;
                }
            }
        }
    }
}

.mst-searchautocomplete__index {
    &.popular {
        .index-title {
            background: none;
            text-align: left;
            color: #999999;
        }

        ul {
            li {
                float: left;
                padding: 1rem 1rem 1rem 1rem;
                margin: 0;

                a {
                    font-size: 1.3rem;
                    color: #575757;
                    font-weight: 600;
                }

                &:hover {
                    background: none;
                    cursor: pointer;

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &.magento_catalog_product {
        border-left: 1px solid transparent;
    }
}

.mst-searchautocomplete__item {
    &.magento_catalog_product {
        display: flex;
        border-bottom: 1px solid $hr-color;
        align-items: center;
        padding: 1rem;

        &:last-child {
            border-bottom: none;
        }

        .mst-product-image-wrapper {
            height: 8rem;
            width: 8rem;
            margin-right: 1rem;

            img {
                height: 8rem;
                max-width: 8rem;
                display: block;
                margin: auto;
                object-fit: contain;
            }
        }

        .title {
            display: block;
            margin-bottom: 0.5rem;

            a {
                color: $text-color;
            }

            .sku {
                color: $mute-color;
            }
        }

        .meta {
            flex-grow: 1;
            overflow: hidden;
        }

        .description {
            font-size: 1.2rem;
            color: $mute-color;
            height: 3rem;
            line-height: 1.5rem;
            overflow: hidden;
            margin-bottom: 0.5rem;
        }

        .price {
            display: block;
            font-weight: 600;
            margin-left: 1rem;
            white-space: nowrap;
            font-size: 1.3rem;

            .price-label {
                margin-right: 1rem;
            }
        }

        .product-reviews-summary {
            margin: 0;

            .reviews-actions {
                display: none;
            }
        }

        .to_cart_message {
            padding: .5rem 1rem;
            font-size: 1.2rem;

            &.error {
                color: #e02b27;
                background: #fae5e5;
            }

            &.success {
                color: #006400;
                background: #e5efe5;
            }
        }

        .to-cart {
            float: right;;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }

        .inStock {
            color: #76bd76;
        }

        .outOfStock {
            color: #a96565;
        }

        &._active {
            background: $hover-bg !important;

            .title {
                a {
                    text-decoration: underline;
                }
            }
        }

    }

    &.magento_search_query {
        a {
            float: left;
        }

        .num_results {
            float: right;
            color: $mute-color;
        }
    }

    a.title {
        word-break: break-word;

        i {
            color: $mute-color;
            display: inline-block;
            margin: 0 .5rem;
            font-style: normal;

        }

    }
}

.mst-searchautocomplete__highlight {
    font-weight: $font-weight__semibold;
    color: #1ba1fc;
}

.mst-searchautocomplete__spinner {
    $size: 2rem;
    position: relative;
    width: $size;
    height: $size;
    margin: auto;

    .spinner-item {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: $size;
        height: $size;
        margin: 0;
        border: 2px solid #ffffff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $spinner-color transparent transparent transparent;

        &.spinner-item-1 {
            animation-delay: -0.45s;
        }

        &.spinner-item-2 {
            animation-delay: -0.3s;
        }

        &.spinner-item-3 {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


//
//    Desktop
//--------------------------------------
@media (min-width: 76.8rem) {
    .mst-searchautocomplete__autocomplete {
        width: 100%;
        min-width: 48rem;

        &._active {
            display: block !important;

            &.mst-2-cols {
                width: 72rem !important;
                position: absolute;

                .mst-searchautocomplete__index {
                    clear: left;
                    float: left;
                    display: block;
                    position: relative;
                    max-width: 22.3rem;
                    width: 22.3rem;
                    padding-bottom: 2rem;
                    box-sizing: border-box;

                    .mst-searchautocomplete__index-title {
                        margin-right: 0;
                    }
                }

                div.magento_catalog_product {
                    width: 48rem;
                    max-width: 48rem;
                    clear: right;
                    float: right;
                    border-left: 1px solid #f6f6f6;
                    border-right: unset;
                    padding-bottom: 0;
                }

                .mst-searchautocomplete__show-all {
                    clear: both;
                }
            }
        }
    }
}


//
//    Mobile
//--------------------------------------
@media (max-width: 76.8rem) {
    .mst-searchautocomplete__autocomplete {
        .mst-searchautocomplete__close {
            display: block;
            position: absolute;
            right: .6rem;
            top: 0;
            font-weight: bold;
            color: #999999;
            font-size: 1.6rem;
            padding: 1rem;
            line-height: 1rem;
            cursor: pointer;
        }
    }
}


.mst-search-autocomplete__typeahead-overlay {
    color: #aaa;
    position: absolute !important;
    left: 2px !important;
    z-index: -1;
    border: 0 !important;
    max-width: 100%;
}


$selection-color: #ff5501;
$selection-color: #1ba1fc;
$light-gray: rgba(65, 66, 71, 0.08);
$divider: #ececec;

html.mstInPage, html.mstInPage body {
    position: absolute;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.mstInPage__container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    display: none;
    overflow-y: scroll;

    &._visible {
        display: block;
    }
}

.mstInPage__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(240, 243, 246, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1000000;
}

.mstInPage__wrapper {
    position: relative;
    z-index: 1000001;
    box-shadow: 0 0 .4rem rgba(0, 0, 0, 0.25);
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.mstInPage__close {
    height: 3.2rem;
    width: 3.2rem;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='cross'%3E%3Cline class='cls-1' x1='7' x2='25' y1='7' y2='25' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2px'/%3E%3Cline class='cls-1' x1='7' x2='25' y1='25' y2='7' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2px'/%3E%3C/g%3E%3C/svg%3E");
    cursor: pointer;
    flex-shrink: 0;
}

.mstInPage__header {
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    box-sizing: border-box;
    background: #ffffff;

    form {
        width: 100%;
        display: flex;
        align-items: center;

        &:before {
            display: block;
            content: "";
            width: 3rem;
            height: 3rem;
            flex-shrink: 0;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg enable-background='new 0 0 32 32' height='3.2rem' version='1.1' viewBox='0 0 32 32' width='3.2rem' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='play'/%3E%3Cg id='stop'/%3E%3Cg id='pause'/%3E%3Cg id='replay'/%3E%3Cg id='next'/%3E%3Cg id='Layer_8'/%3E%3Cg id='search'%3E%3Cg%3E%3Ccircle cx='14.5' cy='14.5' fill='none' r='11.5' stroke='%23CCCCCC' stroke-miterlimit='10' stroke-width='2'/%3E%3Cline fill='none' stroke='%23CCCCCC' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' x1='29' x2='23' y1='29' y2='23'/%3E%3C/g%3E%3C/g%3E%3Cg id='list'/%3E%3Cg id='love'/%3E%3Cg id='menu'/%3E%3Cg id='add'/%3E%3Cg id='headset'/%3E%3Cg id='random'/%3E%3Cg id='music'/%3E%3Cg id='setting'/%3E%3Cg id='Layer_17'/%3E%3Cg id='Layer_18'/%3E%3Cg id='Layer_19'/%3E%3Cg id='Layer_20'/%3E%3Cg id='Layer_21'/%3E%3Cg id='Layer_22'/%3E%3Cg id='Layer_23'/%3E%3Cg id='Layer_24'/%3E%3Cg id='Layer_25'/%3E%3Cg id='Layer_26'/%3E%3C/svg%3E");
        }
    }

    .mstInPage__close {
        height: 3.2rem;
        width: 3.2rem;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='cross'%3E%3Cline class='cls-1' x1='7' x2='25' y1='7' y2='25' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2px'/%3E%3Cline class='cls-1' x1='7' x2='25' y1='25' y2='7' stroke='%23ccc' stroke-linecap='round' stroke-linejoin='round' stroke-width='2px'/%3E%3C/g%3E%3C/svg%3E");
        cursor: pointer;
        flex-shrink: 0;
    }

    &._loading {
        form {
            &:before {
                width: 2.6rem;
                height: 2.6rem;
                margin-left: 2px;
                margin-right: 2px;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.542 1.487A21.507 21.507 0 00.5 22c0 11.874 9.626 21.5 21.5 21.5 9.847 0 18.364-6.675 20.809-16.072a1.5 1.5 0 00-2.904-.756C37.803 34.755 30.473 40.5 22 40.5 11.783 40.5 3.5 32.217 3.5 22c0-8.137 5.3-15.247 12.942-17.65a1.5 1.5 0 10-.9-2.863z' fill='%23CCCCCC' %3E%3C/path%3E%3C/svg%3E");
                animation: mstInPage--loading .5s linear infinite;
            }
        }
    }

    input[type=search] {
        border: none;
        font-size: 2.3rem;
        color: #575757;
        height: 6rem;
        background: #ffffff;
        margin-right: 3rem;
        padding-left: 3rem;
        width: 100%;

        &::placeholder {
            color: #cccccc;
        }

        &:focus {
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
}

.mstInPage__viewAll {
    white-space: nowrap;
    margin-right: 5rem;
    color: $selection-color;
    text-decoration: none;

    &:hover {
        color: $selection-color;
        text-decoration: underline;
    }
}

.mstInPage__indexList {
    display: flex;
    align-items: center;

    .mstInPage__indexListList {
        display: flex;
    }

    .mstInPage__index {
        padding: .7rem 1rem;
        cursor: pointer;
        border-radius: 2px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        margin-right: 1rem;

        span, i {
            display: block;
        }

        span {
            font-weight: 600;
            line-height: 2rem;
            font-size: 1.4rem;
        }

        i {
            font-style: normal;
            font-size: 1.2rem;
            color: #999999;
            margin-left: 1rem;
            line-height: 2rem;
        }

        &:hover {
        }

        border-color: $selection-color;

        &._active {
            background: $selection-color;
            border-color: $selection-color;
            color: #ffffff;

            i {
                color: #ffffff;
            }
        }

        &._empty {
            display: none;
            opacity: 0.3;
            border-color: transparent !important;
        }
    }
}

.mstInPage__itemList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;

    &._empty {
        background: #fdf0d5;
        color: #6f4400;
        padding: 1.5rem;
        font-size: 90%;
    }
}

.mstInPage__highlight {
    color: #1ba1fc;
    font-weight: 600;
}

.mstInPage__item {
    &.magento_catalog_product {
        display: flex;

        .mst__product-image {
            width: 13rem;
            flex-shrink: 0;
            margin-right: 2rem;
            background: #ffffff;

            img {
                border: 1px solid $divider;
                padding: .5rem;
                max-width: 12rem;
            }
        }

        .mst__product-meta {
            .mst__title {
                color: #333333;
                margin-bottom: .5rem;
                display: block;
            }

            .mst__sku {
                color: #999999;
                margin-bottom: .5rem;
            }

            .mst__description {
                font-size: 1.2rem;
                color: #999999;
                height: 4.5rem;
                line-height: 1.5rem;
                overflow: hidden;
                margin-bottom: .8rem;
            }

            .reviews-actions {
                font-size: 1.2rem;
            }
        }

        .mst__product-cart {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: center;
            flex-shrink: 0;

            .to-cart {
                margin-right: 1rem;
            }

            .price-container {
                display: flex;
                align-items: center;
            }

            .price-label {
                font-size: 1.2rem;
                color: #999999;
                font-weight: 500;
                display: block;
                margin-right: 1rem;
            }

            .price {
                font-weight: 600;
            }
        }
    }

    &.magento_catalog_category, &.magento_cms_page {
        padding: 1rem;
        border: 1px solid $divider;

        .title {
            i {
                font-style: normal;
                color: #999999;
                margin: 0 1rem;
            }
        }
    }
}

.mstInPage__content {
    display: flex;
    background: #ffffff;
    border-top: 1px solid $divider;
    overflow: hidden;
    flex-wrap: nowrap;

    &.disable {
        flex-direction: column;

        .mstInPage__sidebar {
            display: none;
        }
    }

    .mstInPage__sidebar {
        width: 35rem;
        flex-shrink: 0;
        overflow: scroll;
    }

    .mstInPage__main {
        padding: 3rem;
        flex-grow: 1;
        overflow: scroll;
    }

    &._empty {
        display: none;
    }
}

.mstInPage__bucketList {
    &.disable {
        dispalay: none;
    }

    &.listApplied {
        padding: 0 0 2rem 0;
    }

    &.filters_top {
        overflow-x: scroll;
    }

    .mstInPage__bucket {
        border-bottom: 1px solid $divider;
        user-select: none;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }

        .mstInPage__bucketLabel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 1.1rem;
            padding: 2rem 3rem;
            letter-spacing: .5px;
            cursor: pointer;
        }

        strong {
            padding: 2rem 0 2rem 3rem;
            display: none;
        }

        &.listApplied {
            strong {
                padding: 0 0 0 3rem;
            }
        }

        .mstInPage__bucketItemList {
            padding: 0 2.8rem 1rem 2.8rem;
            flex-wrap: wrap;
            display: none;

            .mstInPage__bucketItem {
                margin: 1.1rem 3px;
                display: flex;
                align-items: center;
                cursor: pointer;
                white-space: nowrap;
                user-select: none;
                font-size: 1.4rem;

                &:first-child {
                    margin-top: 0;
                }

                span {
                    max-width: 17.6rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                i {
                    display: block;
                    font-style: normal;
                    margin-left: 1.6rem;
                    background: $light-gray;
                    color: #999999;
                    font-weight: 600;
                    font-size: 1rem;
                    border-radius: 1px;
                    padding: 0 .4rem;
                }

                input[type=checkbox] {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-color: $light-gray;
                    border: none;
                    border-radius: 2px;
                    height: 1.6rem;
                    margin: -.4rem 1.6rem 0 0;
                    position: relative;
                    width: 1.6rem;
                    cursor: pointer;
                    flex-shrink: 0;

                    &:after {
                        background-color: #ffffff;
                        border-radius: .4rem;
                        content: "";
                        height: .4rem;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translateX(-2px) translateY(-2px);
                        width: .4rem;
                        opacity: .1;
                    }
                }


                &._active {
                    input[type=checkbox] {
                        background-color: $selection-color;

                        &:after {
                            opacity: 1;
                        }
                    }

                    span {
                        font-weight: 600;
                    }
                }
            }
        }

        &._expanded {
            .mstInPage__bucketItemList {
                display: block;
            }
        }

        .mstInPage__bucketExpand {
            width: 2rem;
            height: 2rem;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z'/%3E%3Cpath d='M0 0h48v48h-48z' fill='none'/%3E%3C/svg%3E");
            background-size: cover;
        }

        &._expanded {
            .mstInPage__bucketExpand {
                transform: rotate(180deg);
            }
        }
    }
}

.mstInPage__bucketState {
    display: none;
    flex-wrap: wrap;
    padding: 1rem 2.8rem;

    .mstInPage__bucketItemList {
        strong {
            display: none;
        }

        .mstInPage__bucketItem {
            cursor: pointer;
            border: 1px solid $divider;
            border-radius: 2px;
            display: flex;
            align-items: center;
            padding: 3px 1rem;
            margin: 3px;

            .mstInPage__close {
                width: 1.4rem;
                height: 1.4rem;
                margin-left: 1rem;
            }

            &:hover {
                border-color: $selection-color;
            }
        }
    }
}

.mstInPage__stat {
    height: 2rem;
    text-align: right;
    font-size: 1.2rem;
    color: #6d6d6d;
    margin-bottom: 1rem;
    margin-top: -1.8rem;
    transition: all 100ms;

    &._loading {
        opacity: 0.3;
    }
}

.mstInPage__pagination {
    display: flex;
    margin-top: 3rem;

    .mstInPage__page {
        padding: .5rem 1.1rem;
        border-radius: 2px;
        border: 1px solid transparent;
        cursor: pointer;
        margin-right: .5rem;

        &.current {
            background: $selection-color;
            color: #ffffff;
        }

        &:hover {
            border-color: $selection-color;
        }
    }

    .mstInPage__pagination_prev {
        content: "<";
    }

    .mstInPage__pagination_next {
        content: ">";

    }
}

.mstInPage__slider {
    margin: 1rem 4.2rem 2rem 3.5rem;
    height: .5rem;
    background: #d9d9d9;

    .ui-slider-range {
        height: .5rem;
        position: absolute;
        background: $selection-color;
    }

    .ui-slider-handle {
        height: 2.4rem;
        border-radius: 2px;
        width: 1.6rem;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        margin-top: -1rem;
        box-shadow: inset 0 0 1px #ffffff, inset 0 1px .7rem #ebebeb, 0 3px .6rem -3px #bbbbbb;
        cursor: pointer;
    }
}

.mstInPage__sliderFromTo {
    display: flex;
    margin: 0 3rem 2rem 3rem;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
}

@keyframes mstInPage--loading {
    to {
        transform: rotate(1turn)
    }
}

@media (min-width: 76.8rem) {
    .mstInPage__wrapper {
        margin: 1.5rem auto;
        max-width: 142rem;
        max-height: unquote("calc(100vh - 3rem)");
    }
}

//
//    Mobile
//--------------------------------------
@media (max-width: 76.8rem) {
    .mstInPage__wrapper {
        margin: 0;
        max-width: 100%;

        .mstInPage__header {
            padding: 1rem;

            .mstInPage__indexList {
                display: none;
            }
        }

        .mstInPage__main {
            padding: 1rem;

            .mstInPage__itemList {
                grid-template-columns: 1fr;
            }

            .mstInPage__stat {
                display: none;
            }
        }

        .mstInPage__sidebar {
            display: none;
        }
    }
}
