
.items {
    @include lib-list-reset-styles();
}

//
//    Desktop
//--------------------------------------

@include min-screen($screen__m) {
    h1 {
        font-size: $h1__font-size-desktop;
        margin-bottom: $h1__margin-bottom__desktop;
    }
}
