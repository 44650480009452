//
//  Base Styles and Magento styles override
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '_mixins';

//
//  Common
//  --------------------------------------------


.amasty_quickorder-index-index {
    .page-main {
        padding: 0;
        max-width: 100%;
    }

    .column.main {
        background: $bs-black;
    }

    .price-including-tax {
        display: inline-block;
        font-size: 14px;
    }

    .price-excluding-tax {

            display: block;
            color: #adadad;
            font-size: 14px;


        .price {
            color: #adadad;
        }
    }

    .price {
        color: #41362f;
    }

    .page-title-wrapper {
        padding: 0 $amqorder__indent__m;
    }

    .page-title-wrapper,
    .amqorder-wrapper,
    .amqorder-search,
    .page.messages {
        margin-right: auto;
        margin-left: auto;
        max-width: 1270px;
    }

    .page.messages {
        padding: 0 $amqorder__indent__m;
    }

    .messages > .message {
        margin-top: $amqorder__indent__xl;
    }

    .page-title {
        margin: 0;
        font-size: 26px;
    }

    .field.date .control {
        @include amqorder-flex(none, center, wrap);
    }

    textarea,
    .swatch-select,
    .amqorder-input,
    .input-text,
    .amqorder-textarea,
    .amqorder-select,
    .admin__control-select,
    .admin__control-multiselect {

            @include amqorder-transition();

            display: block;
            box-sizing: border-box;
            outline: none;
            border: 1px solid #e2dfdf;
            border-radius: 1px;
            box-shadow: none;
            font-size: 12px;


        &:not([disabled]):focus,
        &:not([disabled]):hover {
            box-shadow: none;
        }

        &:hover {
            border-color: $amqorder__hover__color__primary;
        }

        &:focus,
        &.-active {
            border-color: $amqorder__focus__color__primary;
        }

        &.-disabled {
            pointer-events: none;
            opacity: .6;
        }
    }

    .amqorder-radio,
    .admin__control-radio,
    .admin__control-checkbox {
            outline: none;


        &:focus,
        &:hover {
            outline: none;
            box-shadow: none;
        }
    }

    .amqorder-label {
        display: block;
        margin: 0 0 $amqorder__indent / 2;
        font-weight: 700;
    }

    .amqorder-label.-required:after {
        color: $amqorder__color__error;
        content: '*';
    }

    .amqorder-link {
        color: $amqorder__color__primary;
    }

    .amqorder-overlay-block {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 8;
    }
}

.amqorder-messages-list {

        margin: 0;
        padding: 0;
        color: $amqorder__color__error;
        list-style: none;
        font-size: 12px;


    .amqorder-item {
        margin: $amqorder__indent / 2 0 0 0;
    }
}


//
//  Tablet
//  --------------------------------------------

@mixin media-width($extremum, $break) {
    @if ($extremum = 'min' ) and ( $break=$screen__l) {
        .amasty_quickorder-index-index .page-title {
            font-size: 40px;
        }

    }
}
