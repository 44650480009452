//
//  Category Page Styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../_mixins';

//
//  Common
//  --------------------------------------------


.catalog-category-view,
.catalogsearch-result-index {
    .amqorder-header {
        //padding: 0;
        //width: 49%;
    }

    .amqorder-item-info .amqorder-checkbox-container {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: $amqorder__indent__m;

        @media(min-width: $screen__l_min) {
            position: relative;
        }
    }

    .amqorder-header .amqorder-item:not(.-selectall) {
        display: none;
    }

    .toolbar {
        margin-bottom: $amqorder__indent__l * 2;
    }
}


//
//  Tablet
//  --------------------------------------------

@mixin media-width($extremum, $break) {
    @if ($extremum = 'min') and ($break = $screen__l) {
        .catalog-category-view,
        .catalogsearch-result-index {
            .amqorder-item-info .amqorder-checkbox-container {
                position: inherit;
                padding: 0;
            }

            .amqorder-massaction-toolbar:last-child > .amqorder-selected-toolbar {
                margin: 0 $amqorder__indent__l 0 0;
            }

            .amqorder-massaction-toolbar:first-child {

                justify-content: flex-end;
                width: 100%;


                .amqorder-buttons-block {
                    display: flex;
                }
            }

            .amqorder-header {
                width: 100%;
            }

            .amqorder-header .amqorder-item.-item {
                min-width: 305px;
            }

            .amqorder-header .amqorder-item:not(.-selectall) {
                display: inline-block;
            }

            .amqorder-items-block .amqorder-item.-name {
                min-width: 200px;
            }
        }
    }
}


.toolbar {
    grid-template-areas:
        'modes modes'
        'sorter limiter'
        'pages pages' !important;
    grid-template-columns: max-content auto !important;

    @media (min-width: $screen__l_min) {
        grid-template-areas: 'modes sorter limiter pages' !important;
        grid-template-columns: max-content max-content max-content 1fr !important;
    }

    .modes {
        display: flex !important;
    }

    &:last-child {
        .modes {
            display: none !important;
        }
    }


    .modes-label {
        display: none;
    }

    .modes-mode {
        color: #7d7d7d;
        border: 1px solid #d1d1d1;
        border-right: 0;
        float: left;
        font-weight: 400;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        display: block;
        text-decoration: none;

        &:before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 26px;
            line-height: inherit;
            color: #7d7d7d;
            content: "";
            font-family: "icons-blank-theme";
            vertical-align: middle;
            display: inline-block !important;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }

        &:last-child {
            border-right: 1px solid #d1d1d1;
        }

        span {
            display: none;
        }
    }

    .mode-list:before {
        content: "";
        display: inline-block !important;
    }

    .modes .mode-quick-order:before {
        display: inline-block !important;
        width: 16px;
        height: 16px;
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQiIGhlaWdodD0iNCIgZmlsbD0iIzc1NzU3NSIvPgo8cmVjdCB5PSIxMiIgd2lkdGg9IjQiIGhlaWdodD0iNCIgZmlsbD0iIzc1NzU3NSIvPgo8cmVjdCB5PSI2IiB3aWR0aD0iNCIgaGVpZ2h0PSI0IiBmaWxsPSIjNzU3NTc1Ii8+CjxyZWN0IHg9IjYiIHdpZHRoPSIxMCIgaGVpZ2h0PSI0IiBmaWxsPSIjNzU3NTc1Ii8+CjxyZWN0IHg9IjYiIHk9IjEyIiB3aWR0aD0iMTAiIGhlaWdodD0iNCIgZmlsbD0iIzc1NzU3NSIvPgo8cmVjdCB4PSI2IiB5PSI2IiB3aWR0aD0iMTAiIGhlaWdodD0iNCIgZmlsbD0iIzc1NzU3NSIvPgo8L3N2Zz4K);
    }

    .limiter-text {
        display: none;
    }

}

.amqorder-selected-toolbar {
    margin-bottom: 1rem;
}


.category- {
    .amqorder-items-block {
        padding: 0;
    }




}
