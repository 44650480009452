/*
These mixins will aid in generating the utility classes for coffeefresh
in such a way that they remain ligtweight, and easy to modify.

# Responsive classes:
  For generating responsive classes, refer to the $app-breakpoints variable.
  Each of those will generate a mobile-first class. So keep this minimal to reduce bloat.

# Grid system columns:
  Columns are generated based on the $columns and $app-breakpoints variables to make sure they're
  in line with the rest of them

# Spacings:
  This is a combination of the $spacing-steps and the $spacing-properties and adheres to
  the usual bootstrap/tailwind names.

  The properties (margin-left, padding-right etc) are all defined in the $spacing-properties
  variable. There the class and property get generated.

  The steps it'll use are defined in $spacing-steps. This follow the tailwind principle: 4 = 1rem.
  So a .app-m-4 has a margin of 1rem.

  All the spacing utilities will be reponsive according to that variable as well.



 */

/*
Generates mobile first versions of the utility based on the breakpoint
 */
@mixin generate-reponsive-properties() {
  @content;

  @include generate-app-breakpoints(){
    @content
  };
}

@mixin generate-app-breakpoints(){
  @each $breakpoint, $value in $app-grid-breakpoints {
    @if str-index($breakpoint, 'min-') == 1 {
      &-#{str-slice($breakpoint, 5)} {
        @media screen and (min-width: $value){
          @content;
        }
      }
    }
  }
}

/*
# Grid system columns:
 based on the $columns and $app-breakpoints variables
 */
@mixin build-columns(
  $columns    : $columns,
  $gap        : $column-gap,
  $col-label  : '#{$prefix}col',
  $breakpoints: $app-grid-breakpoints
) {
  .#{$col-label} {
    box-sizing: border-box;
    width: 100%;
    min-height: 1px;
    padding-right: ($gap / 2);
    padding-left: ($gap / 2);
  }

  @for $i from 1 through $columns {
    .#{$col-label}-#{$i}\/12 {
      box-sizing: border-box;
      min-height: 1px;
      padding-right: ($gap / 2);
      padding-left: ($gap / 2);
      width: (100% * $i / $columns);
    }
  }

  @each $breakpoint, $value in $app-grid-breakpoints {
    @if str-index($breakpoint, 'min-') == 1 {
      @for $i from 1 through $columns {
        .#{$col-label}-#{$i}\/12 {
          &-#{str-slice($breakpoint, 5)} {
            @media screen and (min-width: $value){
              min-height: 1px;
              padding-right: ($gap / 2);
              padding-left: ($gap / 2);
              width: (100% * $i / $columns);
            }
          }
        }
      }
    }
  }

}
@include build-columns();

/*
Generates spacings based on the $spacing-steps and $spacing-properties.
 */
@mixin build-spacings(
  $steps    : $spacing-steps,
  $spacings : $spacing-properties
) {

  @each $spacing, $attribute in $spacings {
    @each $step in $steps {
      .#{$prefix}#{$spacing}-#{$step} {
        @include generate-reponsive-properties(){
          #{$attribute}: #{($step/4)}rem;
        }
      }
    }
  }
}
@include build-spacings();



@mixin build-display-utilities(
  $properties : $display-properties
) {

  @each $property in $properties {
    .#{$prefix}d-#{$property} {
      display: #{$property};
    }
  }

  @each $property in $properties {
    .#{$prefix}d-#{$property} {
      @include generate-app-breakpoints(){
        display: #{$property};
      }
    }
  }
}
@include build-display-utilities();


@mixin build-border-utilities(
  $steps    : $border-widths,
  $borders  : $border-directions
) {

  @each $border, $attribute in $borders {
    @each $step in $steps {
      .#{$prefix}#{$border}-#{$step} {
        @include generate-reponsive-properties(){
          #{$attribute}: #{$step}px solid;
        }
      }
    }
  }
}
@include build-border-utilities();



@mixin build-color-utilities(
  $properties : $colors
) {

  @each $classname, $color in $properties {
    .#{$prefix}color-#{$classname} {
      color: #{$color};
    }
    .#{$prefix}bg-#{$classname} {
      background-color: #{$color};
    }
    .#{$prefix}border-color-#{$classname} {
      border-color: #{$color};
    }
  }
}
@include build-color-utilities();


