.checkout-index-index {
    .ampromo-items-add {
        border: none !important;
        position: relative;
        color: white;

        @media (min-width: $screen__l_min) {
            position: absolute;
        }
    }
}

.ampromo-items-add {
    a {
        color: $bs-green;
    }
}

.ampromo-items-content, .ampromo-item {
    a, .alink {
        color: $bs-green;
    }

    .action.primary {
        background: $bs-green;
        border: 0 !important;
    }

    .ampromo-title {
        line-height: 1.5;
        height: 10rem;

        a {
            font-size: 1.6rem;
            font-weight: 400;
            color: $bs-black;
        }
    }
}

.ampromo-items-form .price-new-price .price-wrapper {
    all: unset !important;
    font-size: 1.8rem !important;
}

.ampromo-items-content .ampromo-item:hover {
    background: none !important;
    border: 1px solid $bs-md-gray;
}

.ampromo-items-form .product-info-price {
    display: none;
}
