//
//  Product Grids
//  _____________________________________________


.products-grid {
    * {
    }

    margin-bottom: 3rem;

    .product-items {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-inline-start: 0; // wishlist

        @media(min-width: $screen__l_min) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
        }

        .product-item {
            padding: 1rem 1.5rem;
            border: 1px solid $abbr__border-color;
            box-sizing: border-box;
            list-style: none; // wishlist
            width: 100%;
            max-width: 100%;
            transition-duration: 1s;
            background: linear-gradient(-355deg, #f0f0f0 0%, #fff 30%);

            &:hover {
                box-shadow: 0 4px 10px -9px rgba(0, 0, 0, 50%);
                transition-duration: 0.1s;
                background: white;
            }

            .product-item-info {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .product-item-name {
                display: block;
                height: 100%;
                max-height: 6rem;
                min-height: 4rem;
                line-height: 2rem;
                overflow: hidden;
            }

            .product.description.product-item-description {
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .action.more {
                display: block;
                margin-top: 0.2rem;
            }

            .product-wrapper {
                display: grid;
                grid-template-columns: 40% 1fr;
                grid-gap: 1rem;

                .product-image-container {
                    display: block;
                    max-width: 100%;
                    width: 100% !important;

                    .product-image-wrapper {
                        position: relative;


                        .product-image-photo {
                            position: relative;
                            display: block;
                            margin: 0;
                        }
                    }
                }

                &.media-description {
                    height: 100%;
                    padding: 0.5rem 0;
                    display: flex;
                    flex-direction: column;

                    @media(min-width: $screen__xs_min) {
                        display: grid;
                        grid-template-columns: 40% 1fr;
                        grid-gap: 1.5rem;
                    }

                    .product.details.product-item-details {
                        margin-bottom: 1rem;

                        .product-item-inner {
                        }
                    }

                }

                &.price-action {
                    padding-top: 1rem;
                    border-top: 1px solid $abbr__border-color;
                    grid-template-columns: auto auto;

                    .product-price-stock {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }

            .stock-addto {
                padding: 0.2rem 0;
                display: flex;
                align-content: end;
                justify-content: space-between;
            }

        }
    }
}

// Related, Upsell, Crosssell
.block.related, .block.upsell, .block.crosssell {
    order: 4;
    width: 100%;
    overflow-x: auto;

    .block.title {
        margin-bottom: 10px;
    }

    .products-grid {
        .product-items {
            display: grid;
            grid-auto-flow: column;
            grid-gap: 1.5rem;
            grid-auto-columns: 200px;
            grid-template-columns: unset;

            .product-item {
                width: 100%;

                .product-item-name {
                    height: auto;
                }

                .product-item-info {
                    justify-content: space-between;

                    * {
                        text-align: center;
                    }
                }

                .price-box, .stock {
                    justify-content: center;
                    margin: 1rem 0;
                }

                .product-item-photo {
                    .product-image-container {
                        width: 100% !important;

                        img.product-image-photo {
                            position: relative;
                            width: 100%;
                        }
                    }
                }

                .product-item-actions {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}


// Styling
.products-grid {
    .product-item {
        .cat-stock-indicator {
            color: #b4b3b4;

            span {
                font-size: 13px;
            }

            .spacer {
                margin: 0;
            }

            .stock {
                line-height: 1;
                margin: 0;
                font-weight: 500;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    margin-right: 0.5rem;
                }

                &.available:before {
                    background: $bs-green;
                }

                &.unavailable:before {
                    background: $bs-yellow;
                }
            }
        }

        .product-item-name {
            text-align: left;
            font-size: 1.7rem;
        }

        .product-item-link {
            color: #333;
        }

        .price-box {
            display: flex;
            flex-direction: column;
            align-items: start;

            @media(min-width: $screen__xs_min) {
                flex-direction: row;
                align-items: baseline;
            }


            .retail-price {
            }

            .price-label {
                display: none;
            }

            .price-container.price-final_price,
            & {
                .price {
                    font-weight: 500;
                    font-size: 2rem;
                    @media (min-width: $screen__xs_min) {
                        font-size: 2.4rem;
                    }
                }
            }

            .old-price {
                margin-right: 1.5rem;

                .price-container.price-final_price {
                    order: 1;

                    .price {
                        font-size: 1.4rem;
                        @media (min-width: $screen__xs_min) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }

            .special-price {
                order: 2;
                color: $bs-green;
            }

        }

        .product-item-attributes {
            list-style: none;
            padding: 0.5rem 0 0 0;
            margin: 0;
            line-height: 1.8rem;
            font-size: 1.3rem;

            .product-item-attribute {
                padding: 0;
                margin: 0;

                .label {
                    display: inline;
                    font-weight: 500;
                }

                .value {
                    display: inline;
                    margin-left: 0.6rem;
                }
            }
        }

        .product-item-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            button.tocart.primary, .action.todetails.primary {
                @include btn-primary($size: 'wide', $color: 'green');
            }

            .actions-secondary {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                margin-left: 0.5rem;

                .action {
                    &:before {
                        font-family: "Font Awesome 5 Pro";
                        font-size: 1.4rem;
                        width: unset;
                        line-height: unset;
                        color: #333;
                        font-weight: normal;
                        overflow: unset;
                        position: relative;
                        height: auto;
                    }

                    &:hover {
                        text-decoration: none;

                        &:before {
                            color: $bs-green;
                        }
                    }

                    &.towishlist {
                        &:before {
                            content: fa-content($fa-var-heart);
                        }
                    }

                    &.tocompare {
                        display: flex;

                        span {
                            font-size: 0;
                        }

                        &:before {
                            content: fa-content($fa-var-chart-bar);
                        }
                    }
                }
            }
        }
    }

    a.product.photo.product-item-photo { // we need this to position the discount label
        position: relative;
    }

    label.discount-label { // this label is used in hardrace, but can be enabled for brakesshop
        display: none; // or block if you want to enable
        //z-index: 1;
        //background: red;
        //color: white;
        //padding: 0.5rem 1rem;
        //font-weight: 600;
        //font-size: 1.6rem;
        //left: 0;
        //position: absolute;
    }
}
