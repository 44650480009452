// todo, conversion from less, needs cleanup

.payment-methods .payment-method .radio {
    vertical-align: middle;
    margin-top: 0;
}

.checkout-payment-method .payment-method-przelewy24 .payment-method-content {
    padding-left: 0;
}

.payment-method-icon-przelewy24 {
    height: 24px;
    width: auto;
    vertical-align: middle;
    border: 2px solid #F5F5F5;
    background-color: #F5F5F54D;
    padding: 11px 8px;
    margin-left: 15px;
}

.payment-method-icon-przelewy24_card {
    padding: 16px 6px;
    height: 14px;
}

.payment-method-icon-przelewy24_blik {
    padding: 12px 21px;
    height: 22px;
}

.payment-method-icon-przelewy24_google_pay {
    padding: 9px 21px;
    height: 28px;
}

.payment-method-label-przelewy24 {
    display: inline-block;
    vertical-align: middle;
}

.przelewy24-payment-method-description {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}

.przelewy24-payment-method-title {
    display: block;
    font-size: 15px;
    line-height: 20px;
    color: #4C4C4C;
    font-weight: 700;
    margin-bottom: 6px;
}

.przelewy24-payment-method-subtitle {
    display: block;
    font-size: 13px;
    line-height: 18px;
    color: #4C4C4C;
}

.payment-method-icon-przelewy24_apple_pay {
    padding: 9px 21px;
    height: 28px;
}

.payment-method-przelewy24 {
    &__title {
        font-weight: $font-weight__bold;
        margin-bottom: $indent__base;
    }

    &__methods {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        margin-bottom: $indent__l;
    }

    &__method {
        display: flex;
        flex: 0 0 125px;
        height: 84px;
        padding: 3px;
        cursor: pointer;

        &:hover &-wrapper,
        &--selected &-wrapper {
            border-color: #23AA4A91;
            background-color: #23AA4A1F;
        }

        &-wrapper {
            display: flex;
            padding: 5px 20px;
            border: 2px solid #F5F5F5;
            background-color: #F5F5F54D;
        }

        &-img {
            display: block;
            margin: auto;
            width: 100%;
            height: auto;
            max-height: 100%;
            min-width: 81px;
        }

        &--promoted {
            order: -1;
            flex: 0 0 256px;
            justify-content: center;

            @media (min-width: 408px) {
                margin-left: 65px;
            }

            @media (min-width: 539px) {
                margin-left: 131px;
            }

            @media (min-width: 670px) {
                margin-left: 196px;
            }

            @media (min-width: $screen__m) {
                margin-left: 65px;
            }

            @media (min-width: 872px) {
                margin-left: 131px;
            }

            @media (min-width: 1068px) {
                margin-left: 196px;
            }

            @media (min-width: 1264px) {
                margin-left: 262px;
            }
        }

        &--promoted &-img {
            min-width: 212px;
        }

        &--divider {
            order: -1;
            flex: 0 0 100%;
        }
    }

    &--standalone {
        .payment-method-icon-przelewy24 {
            width: 69px;
        }
    }
}

.payment-method-przelewy24_card {
    .checkout-agreements-block {
        margin-top: 2rem;
    }
}

.P24FormContainer-fake {
    position: relative;

    .przelewy24-pay-with-card-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.5);

        > .przelewy24-pay-with-card {
            //@include lib-button-primary();
            //@include lib-button-l();
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

#P24FormContainer {
    > a {
        //@include lib-button-primary();
        //@include lib-button-l();

        &:hover {
            text-decoration: none;
        }
    }
}

#P24FormContainer,
.P24FormContainer-fake {
    > form {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: flex-end;

        > p {
            display: flex;
            flex: 0 0 100%;
            padding: 0;
            margin-bottom: $indent__s;
            align-items: center;
            max-width: 100%;
            order: 10;
            color: $text__color;

            &:nth-of-type(3),
            &:nth-of-type(4) {
                flex: 0 0 100%;
                max-width: 100%;

                @media (min-width: $screen__l) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }

            &:nth-of-type(4) {
                label {
                    @media (min-width: $screen__l) {
                        margin-left: auto;
                    }
                }
            }
        }

        label {
            //@include lib-css(font-size, $form-field-label__font-size);
            //@include lib-css(line-height, $form-field-label__line-height);
            //@include lib-css(padding, $form-field-type-label-inline__padding);
            //@include lib-css(color, $form-field-label__color);
            //@include lib-css(margin, $form-field-type-label-inline__margin);
            //@include lib-css(text-align, $form-field-type-label-inline__align);
            white-space: nowrap;
            width: 150px;
            flex: 0 0 150px;
        }

        input {
            //@include lib-form-element-input($_type: input-text);
            border: 2px solid #F5F5F5;
            background-color: #F5F5F54D;

            &.P24_input_error {
                //    .lib-form-element-color(
                //$_border-color: $form-element-validation__border-error,
                //$_background: $form-element-validation__background-error,
                //$_color: $form-element-validation__color-error
                //);
            }

            &#P24_cardCVV {
                text-align: center;
                max-width: 60px;
            }

            &#P24_expMonth,
            &#P24_expYear {
                text-align: center;
                flex: 0 0 60px;
            }

            &#P24_expMonth {
                margin-right: $indent__xs;
            }

            &#P24_expYear {
                margin-left: $indent__xs;
            }
        }

        > button {
            //@include lib-button-primary();
            //@include lib-button-l();
            order: 30;
        }
    }
}

.blik-code-fieldset {
    .control {
        max-width: 95px;
        position: relative;

        > input {
            border: 2px solid #F5F5F5;
            background-color: #F5F5F54D;
            letter-spacing: 5px;
            box-shadow: none;
            padding: 0 9px 0 7px;

            &:focus {
                outline: 0;
            }
        }

        .blik-placeholder {
            &-element {
                width: 10px;
                height: 1px;
                position: absolute;
                bottom: 5px;
                background: $text__color;
            }
        }
    }
}

.blik-token-image {
    vertical-align: middle;
}

.table-credit-cards {
    .card-type--przelewy24,
    .col--przelewy24-blik {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 4px;
            bottom: 4px;
            width: 40px;
            height: 14px;
            background: url('../PayPro_Przelewy24/images/logo.svg') no-repeat center;
            opacity: 0.5;
        }
    }
}



.payment-method-icon-przelewy24 {
    margin-left: 0;
}

.przelewy24-payment-method-description {
    max-width: 170px;
    margin-left: 3px;
}

