.mst-search-in__wrapper {
    max-width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    .mst-search-in__title {
        border: none;
        padding: .6rem 3px .6rem .6rem;
        margin-right: 3px;
        font-weight: 600;
        white-space: nowrap;
    }

    .mst-search-in__item {
        white-space: nowrap;
        border: 1px solid #ccc;
        border-radius: 2px;
        margin: 0 .5rem .5rem 0;
        padding: .6rem 1.2rem;
        color: #494949;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        span.name {
            display: flex;
            flex-wrap: nowrap;
            font-size: 1.3rem;

            i {
                font-style: normal;
                display: flex;
                align-items: center;

                &:after {
                    font-size: .7rem;
                    content: '\e608';
                    font-family: 'luma-icons';
                    margin: 0 .5rem;
                    vertical-align: top;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                    color: #757575;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        span.count {
            color: #757575;
            display: block;
            margin-left: .8rem;
            font-size: 80%;

            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }
        }

        &:hover {
            border-color: #ff5501;
            text-decoration: none;
        }
    }
}

.mst_categorySearchLoader {
    display: none;
    position: absolute;
    top: .6rem;
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
}

.mst_categorySearchLoader .spinner-item {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  margin: 0;
  border: 2px solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #999999 transparent transparent transparent;
}

.mst_categorySearchLoader .spinner-item.spinner-item-1 {
  animation-delay: -0.45s;
}

.mst_categorySearchLoader .spinner-item.spinner-item-2 {
  animation-delay: -0.3s;
}

.mst_categorySearchLoader .spinner-item.spinner-item-3 {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mst_categorySearch {
    display: block;
    position: relative;
}
.mst_categorySearch_searchIcon {
    position: absolute;
    left: 1rem;
    font-size: 2.1rem;
    color: #bbbbbb;
}

span.mst_categorySearch_totals {
    position: absolute;
    right: 0;
    padding: .6rem 1.5rem .6rem 1.5rem;
    color: #999;
    display: none;
}

input#mst_categorySearch {
    padding: 0 0 0 3.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    max-width: 100%;
}

.mst_categorySearchLoader .fa-spinner {
    font-size: 3rem;
}
