//
//  Page components
//  ---------------------------------------------

@import 'checkout/checkout';
@import 'checkout/estimated-total';
@import 'checkout/progress-bar';
@import 'checkout/fields';
@import 'checkout/modals';
@import 'checkout/tooltip';
@import 'checkout/shipping';
@import 'checkout/shipping-policy';

@import 'checkout/sidebar';
@import 'checkout/sidebar-shipping-information';
@import 'checkout/order-summary';
@import 'checkout/authentication';

@import 'checkout/payments';
@import 'checkout/payment-options';
@import 'checkout/checkout-agreements';
