.wishlist-index-index {
    .products-grid {

        .product-item {
            margin: 0;

            .product-item-info {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 1.5rem;
                width: 100%;
                box-sizing: border-box;
                grid-template-areas:
        'name'
        'image'
        'inner'
        'price';

                @media(min-width: $screen__xs_min) {
                    grid-template-columns: 40% 1fr;
                    grid-template-areas:
        'name name'
        'image inner'
        'price price';
                }

                @media(min-width: $screen__m_min) {
                    grid-template-areas:
        'name name'
        'image inner'
        'price price';
                }

                .product-image-wrapper {
                    padding-bottom: 100% !important;

                    .product-item-photo {
                        grid-area: image;
                        margin: 0 !important;

                        .product-image-container {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }

                .product-item-name {
                    grid-area: name;
                    margin: 0;
                }

                .price-box {
                    grid-area: price;
                    margin: 0;
                }

                .product-item-inner {
                    display: block;
                }

                .comment-box {
                    grid-area: comment;
                    margin-bottom: 1.5rem;

                    .product-item-comment {
                        height: 10rem;
                    }
                }

                .box-tocart {
                    grid-area: tocart;

                    * {
                        margin: 0 !important;
                    }

                    .fieldset {
                        display: flex;
                        width: 100%;
                        justify-content: end;
                    }

                    .qty label {
                        display: none;
                    }

                    input.qty {
                        height: 4rem;
                    }
                }

                .product-item-actions {
                    grid-area: actions;
                }
            }
        }
    }
}
