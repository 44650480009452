.wholesale_appautomotive_website {

    @import "modules/Mirasvit_SearchAutocomplete/module";

    .page-main {
        padding-top: 2rem !important;

        .modes {
            display: flex;
            grid-area: modes;
        }
    }

    .page-products .page-main .columns .column.main .toolbar, .page-with-filter .page-main .columns .column.main .toolbar,
    .catalogsearch-result-index .page-main .columns .column.main .toolbar {
        @media(min-width: $screen__s_min) {
            grid-template-areas:
                    'modes modes'
                    'sorter limiter'
                    'pages pages';
            grid-template-columns: max-content auto;
        }

        @media(min-width: $screen__l_min) {
            grid-template-areas: 'modes sorter limiter pages';
            grid-template-columns: max-content max-content max-content 1fr;
        }
    }


    header .header.content .logo {
        margin: 0 !important;
        padding: 0 !important;
        align-self: center;
    }



    &.ambrand-index-index {
        .page-title-wrapper {
            display: flex;
            justify-content: space-between;

            .sorter-options {
                border-color: $bs_green;
            }
        }
    }


    .block.crosssell {
        .stock {
            display: none;
        }

        .dealer-tier-prices {
            display: none;
        }

        .products-grid {
            .product-items {
                grid-auto-flow: unset;

                // supersmall
                $spare-grid-columns: 1;
                @include columns-size($spare-grid-columns, $spare-grid-column-gap);

                // mobile
                @media (min-width: $screen__xxs_min) {
                    $spare-grid-columns: 2;
                    @include columns-size($spare-grid-columns, $spare-grid-column-gap);
                }

                // mobile
                @media (min-width: $screen__xs_min) {
                    $spare-grid-columns: 3;
                    @include columns-size($spare-grid-columns, $spare-grid-column-gap);
                }

                // tablet
                @media (min-width: $screen__m_min) {
                    $spare-grid-columns: 4;
                    @include columns-size( $spare-grid-columns , $spare-grid-column-gap );
                }

                // desktop
                @media (min-width: $screen__l_min) {
                    $spare-grid-columns: 5;
                    @include columns-size( $spare-grid-columns , $spare-grid-column-gap );
                }

            }

            .product-item .price-box {
                align-items: center;

                .price {
                    font-size: 1.5rem;
                }
            }
        }
    }

}


