.headroom {
    will-change: transform;
    transition: transform 200ms linear;
    background: #161415;
    position: relative;
    z-index: 9;
    width: 100%;
}

.headroom--not-top, .headroom--top {
    position: fixed;
    width: 100%;
    z-index: 199;
    top: 0;
}

.headroom--unpinned {
    transform: translateY(-100%);
}

.headroom--pinned {
    transform: translateY(0%);
}

.cms-index-index {
    .headroom--not-top {
        .page-header {
            margin-bottom: 0;
        }
    }
}

//body:not(.cmx-index-index) {
//  .headroom--not-top {
//    .fitments-search-wrapper {
//      padding: .4rem 2rem;
//
//      @media screen and (min-width: $screen__m_min){
//        padding: .0rem 2rem;
//      }
//    }
//
//    .search-fitment {
//      padding: .8rem 1.2rem;
//
//      @media screen and (min-width: $screen__m_min){
//        padding: 0rem 1.2rem;
//      }
//    }
//
//    .fitments-search-inner {
//      @media screen and (min-width: $screen__m_min){
//        min-height: 5.4rem;
//      }
//
//      .fitments-filter-selector > div {
//        @media screen and (min-width: $screen__m_min){
//          padding: .4rem 1.5rem;
//        }
//      }
//
//      .fitments-filter-toolbar {
//        .fitments-filter-button {
//          @media screen and (min-width: $screen__m_min){
//            padding: .9rem 2rem 1rem;
//          }
//        }
//      }
//    }
//  }
//}

.header-wrapper {
    .header-promo {
        &:first-of-type {
            @media(max-width: $screen__s_max) {
                display: none;
            }
        }
        &:last-of-type {
            @media(min-width: $screen__m_min) {
                display: none;
            }
        }
    }
}

.header-promo {
    // display: none;

    //@media(min-width:$screen__m_min) {
        display: flex;
        width: 100%;
        min-height: 4rem;
        background: #1ab67c;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        padding: 1rem;
        box-sizing: border-box;

        p {
            margin: 0;
        }
    }
//}

