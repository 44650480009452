//
//  Promo Banner
//  ____________________________________________

//
//  Common
//  ----------------------------------------------

.ampromo-gift {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style-type: none;
    padding: 0;

    .ampromo-gift-item {
        display: flex;
        margin: 0;
        text-align: center;

        .product-image {
            text-decoration: none;
        }

        .ampromo-product-name {
            font-weight: bold;
            max-width: 100px;
        }
    }
}

