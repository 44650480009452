//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    position: relative;
    box-sizing: border-box;

    @media screen and (min-width: $screen__m_min){
        background-color: $navigation__background;
        border-top: .2rem solid $bs-mlt-gray;
        top: 0 !important;
    }
}

.nav-toggle {
    grid-area: nav-toggle;
    cursor: pointer;
    font-size: 0;
    z-index: 14;
    text-align: center;
    width: 3.2rem;
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto 0;

    @media screen and (min-width: $screen__m_min){
        display: none;
    }

    .nav-toggle-bar {
        display: block;
        content: '';
        width: 100%;
        height: .2rem;
        background-color: #fff;
        transition: transform 600ms;
    }
}

.nav-open {
    .nav-toggle {
        .nav-toggle-bar {
            &1 {
                transform: rotate(45deg);
                transform-origin: left;
            }

            &2 {
                transform: scale(0);
            }

            &3 {
                transform: rotate(-45deg);
                transform-origin: left;
            }
        }

        &:after {
            //background: rgba(0, 0, 0, $overlay__opacity);
            //content: '';
            //display: block;
            //height: 100%;
            //position: fixed;
            //right: 0;
            //top: 0;
            //width: 100%;
            //z-index: 1;

            @media screen and (min-width:$screen__m_min) {
                display: none;
            }
        }
    }
}

[class^='multishipping-'],
.checkout-index-index {
    &:not(.multishipping-checkout-success){
        .nav-sections,
        .nav-toggle {
            display: none;
        }
    }

}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation {
        padding: 0;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, transform 0.3s, 1);
        //height: 100%;
        overflow: auto;
        position: fixed;
        //top: -100%;
        width: 100%;
        z-index: 98;
        transform: translateY(-100%);
        top: 0;

        max-height: calc(100vh - 8rem);

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            padding: 1.2rem 1.2rem 3.2rem 3.2rem;
            width: 100%;
            box-sizing: border-box;
            font-weight: 400;


            @media screen and (min-width: $screen__m_min){
                font-weight: 700;
                margin: 0;
                padding: 0.8rem 3.5rem 0.8rem 2rem;
                width: initial;
            }

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }

            .action.toggle {
                color: #000;

                @media screen and (min-width: $screen__m_min){
                    color: #fff;
                }

                strong.view {
                    font-weight: 400;

                    @media screen and (min-width: $screen__m_min){
                        font-weight: 700;
                    }
                }
            }

            &.active {
                > ul.dropdown {
                    display: block !important;
                    position: initial;
                    box-shadow: none;
                    padding-left: 0;
                }
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        //height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            //left: 80%;
            //left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            top: 6.8rem;
            @include lib-css(transform, top 0.3s, 1);
            transform: translateY(0);
        }
    }

    .nav-sections-items {
        position: relative;
        box-sizing: border-box;
        z-index: 1;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        box-sizing: border-box;
        width: 100%;

        &.active {
            display: block;
        }

    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        z-index: 9;

        .switcher {
            display: none;
        }
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}


.navigation {
    > ul,
    > ul > li,
    > ul > li > ul {
        box-sizing: border-box;
        //width: 100%;
    }

    > ul {
        flex-wrap: wrap;

        @media screen and (min-width: $screen__m_min){
            display: flex;
        }
    }

    .parent {
        .level-top {
            position: relative;
            //@include lib-icon-font(
            //    $_icon-font-content  : $icon-next,
            //    $_icon-font-size     : 42px,
            //    $_icon-font-position : after,
            //    $_icon-font-display  : block
            //);

            @media screen and (min-width: $screen__m_min){
                display: inline-flex;
                align-items: center;
            }

            &:after {
                position: absolute;
                right: 15px;
                max-height: 100%;
                transition: transform 200ms;
                transform-origin: center;
                content: url('../images/chevron-right.svg');
                width: .8rem;

                @media screen and (min-width: $screen__m_min){
                    content: url('../images/chevron-down.svg');
                    position: initial;
                    display: inline-block;
                    width: 1.2rem;
                    right: initial;
                    line-height: 2rem;
                    margin-top: .2rem;
                    margin-left: .8rem;
                }
            }

            &.ui-state-active {
                //@include lib-icon-font-symbol(
                //    $_icon-font-content  : $icon-down,
                //    $_icon-font-position : after
                //);

                &:after {
                    transform: rotate(90deg);

                    @media screen and (min-width: $screen__m_min){
                        transform: none;
                    }
                }
            }
        }
    }

    .level0 {
        &.cms-page {
            @media screen and (min-width: $screen__m_min) {
                display: none;
            }
        }

        &.submenu {
            &:after {
                @media screen and (min-width: $screen__m_min) {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 100%;
                    height: 3rem;
                    left: 0;
                }
            }
        }

        .submenu {
            //display: flex !important;
            min-width: 100%;

            @media screen and (min-width: $screen__m_min) {
                box-sizing: border-box;
                //left: calc(((100vw - 1280px) / 2) * -1) !important;
                left: 0 !important;
            }

            @media screen and (min-width: $screen__l_min) {
                padding-left: calc((100vw - 1280px) / 2);
                padding-right: calc((100vw - 1280px) / 2);
            }

            > li.level1 {
                display: inline-block;
                position: relative;
                margin-bottom: 0;
            }

            .level1.submenu {
                left: 0 !important;
                top: initial !important;
                //width: initial;
                padding: 1.2rem;
                border: none;

                @media(min-width: $screen__m_min) {
                    min-width: 30rem;
                }

                .level2 {
                    margin-bottom: 0;

                    &:hover {
                        background: $bs-red;

                        a {
                            color: white;
                        }
                    }
                }
            }
        }
    }

}
