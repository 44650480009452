h2.subtitle,h3.subtitle,h4.subtitle,
form.contact .fieldset > .legend { /* <<< toegevoegd */
    font-size: 1.8rem; margin-top: 30px; font-weight: 400; border-left: 7px solid #ff0016; padding-left: 5px;
}

/* tbv tabellen in content pagina's, voorbeeld: http://brakesshop.epaflex.nl/support/contact/ */
table.data-table { margin: 0 0 10px 0; width: 100%; box-shadow: 0 1px 3px rgba(0,0,0,0.2); }
table.data-table thead th { font-weight: 900; color: #ffffff; background: #464848; }
table.data-table tbody tr { background: #f6f6f6; }
table.data-table tbody tr:nth-child(odd) { background: #e9e9e9; }
table.data-table tbody th { font-weight:bold; }
table.data-table tbody td { padding: 6px 12px; }

/* cms menu */
.cms-menu h2 { margin:10px 0 20px 0; }
.cms-menu h3 { font-size: 0.8vw; margin-top:30px; font-weight:600 !important;  }

#confirmBox {
    max-width: 500px !important;
}
