.table.grouped {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    > thead > tr > th {
        border-bottom: 0;
    }

    .product-item-name {
        font-weight: $font-weight__regular;
    }

    .price-box {
        align-items: end !important; // override from product.scss
        margin-top: 0 !important;

        .price {
            font-size: $font-size__base;
        }
    }

    .control {
        .qty {
            @extend .abs-input-qty;
        }
    }

    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }
}


// Grouped products
.page-product-grouped {

    .product-info-main {
        max-width: 100%;
    }

    .product-add-form {
        max-width: 100%;

        .product-item-name {
            font-weight: bold;
        }

        .price-final_price .price-wrapper span.price {
            font-size: 1.8rem;
        }

        span.excl-tax {
            display: none;
        }
    }

    .price-box.grouped {
        margin-top: 4rem;

        #product-price-grouped {
            .price {
                font-size: 3.2rem;
            }
            .old-price {
                font-size: 1.8rem;
                text-decoration: line-through;
                margin-right: 1.8rem;
            }
            &:has(.old-price) {
                .price:not(.old-price) {
                    color: $bs-green; // if one or more of the grouped products has a specialprice, show the total price in the specialprice color
                }
            }
        }
    }

}

.table.grouped {

    tbody tr {
        border-bottom: 1px solid #eee;

        td.col.item {
            display: flex;
            flex-direction: column;

            .product-item-name {
                margin: 0;
                padding: 8px 0;
                font-weight: 400;
            }
        }
    }

    .stock-status {
        .stock {
            margin: 0;
            padding: 8px 0;
        }
    }

}


