header {
  .trustpilot-widget--container {
    display: none;

    @media screen and (min-width: $screen__m_min){
        grid-area: trustpilot;
        display: flex;
        align-items: center;
        justify-content: center;

        img.trustpilot-logo {
            display: flex;
            height: 4rem;
        }
    }

    .trust-pilot-wrap {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .header.content {
    display: contents;

      @media screen and (min-width: $screen__m_min) {
          display: grid;
          grid-template-areas: 'logo search trustpilot';
          grid-template-columns: 22rem auto 22rem;
          position: relative;
          padding: 0;
          max-width: 100%;
          padding: {
              left: calc((100vw - 1280px) / 2);
              right: calc((100vw - 1280px) / 2);
          };
          background: #fff;
          align-items: center;

          .logo {
              padding: 2rem;
              box-sizing: border-box;
          }
      }
      @media screen and (min-width: $screen__l_min) {
          grid-template-columns: 35rem auto 20rem;
      }
  }

  .logo {
    box-sizing: border-box;

    @media screen and (min-width: $screen__m_min){
      margin-top: -3rem;
    }

    .bs-logo-desktop {
    display: initial;

      @media screen and (min-width: $screen__m_min){
        //position: absolute;
        //bottom: 0;
      }
    }

    .bs-logo-mobile {
      max-width: 100%;
      overflow: visible;
      display: none;

      @media screen and (min-width: $screen__m_min){
        display: none;
      }
    }
  }

  .block-search {
    margin-top: 0;
    display: flex;
    align-items: center;
  }

  .minicart-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.nav-sections {
  margin-bottom: 0;

  @media screen and (min-width: $screen__m_min){
    background-color: $navigation__background;
    border-top: .2rem solid $bs-mlt-gray;
  }
}

.header-nav-wrapper {
    .header-promo {
        &:first-of-type {
            @media(max-width: $screen__s_max) {
                display: none;
            }
        }
        &:last-of-type {
            @media(min-width: $screen__m_min) {
                display: none;
            }
        }
    }
}

.header-promo {
    // display: none;

    //@media(min-width:$screen__m_min) {
    display: flex;
    width: 100%;
    min-height: 4rem;
    background: #1ab67c;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    padding: 1rem;
    box-sizing: border-box;

    p {
        margin: 0;
    }
}

// currency switcher
.switcher-currency {
    .switcher-label {
        @media (min-width: $screen__m_min) {
            display: none;
        }
    }

    .action.toggle {
        @media (min-width: $screen__m_min) {
            background-color: $bs-xdark-gray;
            border-radius: 2rem;
            padding: .2rem .8rem;
            position: relative;
        }

        .view {
            display: flex;
            align-items: center;
            line-height: 1;
            font-weight: inherit;

            &:after {
                @media (min-width: $screen__m_min) {
                    content: url('../images/chevron-down-white.svg');
                    display: inline-block;
                    width: 1.2rem;
                    right: initial;
                    line-height: 2rem;
                    margin-top: .2rem;
                    margin-left: .8rem;
                }
            }
        }

        &.active {
            &:after {
                display: none;
            }
        }
    }

    .panel.header > & {
        display: none;

        @media (min-width: $screen__m_min) {
            display: block;
            margin-right: 3rem;
        }
    }
}

#store\.settings {
    display: block !important;
    background-color: $color-white;

    .switcher-currency {
        border-bottom: 1px solid #d1d1d1; // as in menu

        .app-dropdown {
            display: block;
        }

        .action.toggle {
            padding: 1.2rem 1.5rem 1.2rem 3.2rem;
            font-size: 1.6rem;
            color: $color-black;

            .view {
                line-height: inherit;
                justify-content: space-between;
                width: 100%;

                &:after {
                    position: static;
                    transition: transform 200ms;
                    transform-origin: center;
                    content: url('../images/chevron-right.svg');
                    width: .8rem;
                    font-size: inherit;
                }
            }

            &.active {
                display: block;

                .view {
                    &:after {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .switcher-dropdown {
            position: static;
            width: 100%;
            background-color: transparent;
            box-shadow: none;
            display: none;
            margin: 0;
            padding: 0;

            li {
                padding: 0;
            }

            a {
                display: block;
                padding: .8rem 1.5rem .8rem 5.2rem;
                font-weight: 400;
            }
        }

        .active {
            .switcher-dropdown {
                display: block;
            }
        }
    }
}
