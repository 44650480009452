.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.app {
    &-row {
        @include generate-reponsive-properties(){
            display: flex;
            font-weight: 400;
            margin: 0 -1rem;
        };
    }

    &-nowrap {
        @include generate-reponsive-properties(){
            flex-wrap: nowrap
        };
    }

    &-wrap {
        @include generate-reponsive-properties(){
            flex-wrap: wrap
        };
    }

    &-order--1 {
        @include generate-reponsive-properties(){
            order: -1;
        }
    }

    &-order-9 {
        @include generate-reponsive-properties(){
            order: 9;
        }
    }
}

.image {
    &-cover {
        object-fit: cover;
        min-height: 100%;
    }

    &-contain {
        object-fit: contain;
        min-height: 100%;
    }
}

// fix for hori scrollbar #app2-270
.banners-widget .app-row {
    margin: 0;
}
.banners {
    .app-col-12\/12 {
        padding-left: 0;
        padding-right: 0;
    }
    .app-col-9\/12-m {
        @media(min-width: $screen__m_min) {
            padding-right: 2rem !important;
        }
    }
}
