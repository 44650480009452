//
//  Inputs Styles
//  ____________________________________________

//
//  Imports
//  ----------------------------------------------

@import '../_mixins';

//
//  Variables
//  ----------------------------------------------

$amqorder-checkbox-icon__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuMDAwMUw0LjIgNy4yMDAxTDkgMS42MDAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
$amqorder-checkbox__border-color: #ccc;
$amqorder-checkbox__active__color: $bs-green;
$amqorder-checkbox__size: 16px;

//
//  Common
//  ----------------------------------------------


    .amqorder-checkbox-container {
        &.-disabled {
            @extend .amqorder-disabled;
        }

        .amqorder-label {

                @include amqorder-flex(center, center);

                position: relative;
                justify-content: flex-start;
                box-sizing: border-box;
                margin: 0 $amqorder__indent / 2;
                width: $amqorder-checkbox__size;
                height: $amqorder-checkbox__size;
                border: 1px solid $amqorder-checkbox__border-color;
                border-radius: 2px;
                background-color: #fff;
                cursor: pointer;


            &:hover .amqorder-mark {
                border-color: $amqorder-checkbox__active__color;
                background: $amqorder-checkbox__active__color;
            }
        }

        .amqorder-input:checked + .amqorder-mark {

                border-color: $amqorder-checkbox__active__color;
                background: $amqorder-checkbox__active__color;
                box-shadow: 0 2px 4px rgba(71, 118, 240, .4);


            &:before {
                opacity: 1;
            }
        }

        .amqorder-input {
            overflow: hidden;
            margin: 0;
            width: 0;
            height: 0;
            opacity: 0;
        }

        .amqorder-mark {

                @include amqorder-flex(center, center);

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                box-sizing: border-box;
                margin: -1px;
                border: 1px solid transparent;
                border-radius: 2px;


            &:before {
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                box-sizing: border-box;
                margin: 0;
                width: 100%;
                height: 100%;
                content: '';
                padding: 2.4rem; // some clicking space
            }

            &:before {
                @include amqorder-icon($amqorder-checkbox-icon__background, $amqorder-checkbox__size, $amqorder-checkbox__size);
                @include amqorder-transition();

                background-size: 9px;
                opacity: 0;
            }
        }
    }

