.widget {

    &.package-deals {
        position: relative;
        margin: 25px 0;
        padding: 20px;
        background: #fff;

        h2, .h2 {
            border-left: 10px solid red;
            padding-left: 10px;
            text-transform: uppercase;
            font-size: 2.6rem;
            margin-top: 25px;
            margin-bottom: 20px;
            margin-block-start: 25px;
            margin-block-end: 20px;
        }

        .more-sale {
            position: absolute;
            right: 20px;
            top: 40px;
            text-transform: uppercase;
            color: #000;
            font-weight: bold;
            text-decoration: underline;

            &:hover {
                color: red;
            }
        }

        .products {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 0;

            @include max-screen($screen__m) {
                flex-direction: column;
            }

            .product {
                display: flex;
                flex-direction: column;
                width: 19%;

                @include max-screen($screen__m) {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .image,
                .stock,
                .name,
                .price {
                    text-align: center;
                }

                .stock {
                    margin-bottom: 0;

                    .stock-bullet {
                        &:before {
                            content: '';
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            border-radius: 100%;
                        }

                        &.in-stock:before {
                            background: #1AB67C;
                        }

                        &.partial-stock:before {
                            background: $bs-color-partial-stock;
                        }

                        &.out-of-stock:before {
                            background: #CCC;
                        }
                    }
                }

                .name {
                    color: #000;
                    text-decoration: none;
                    text-transform: uppercase;
                    margin: 15px 0;
                }

                .price {
                    font-weight: bold;
                    margin-top: auto;
                    margin-bottom: 15px;

                    .price-label {
                        display: none !important;
                    }

                    .price-box {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: center;
                        align-items: center;
                    }

                    .special-price {
                        margin-left: 10px;
                    }
                }

                .button {
                    margin-top: auto;
                    margin-bottom: 0;
                    text-align: center;

                    background: #e0e0e0;
                    color: #000;
                    text-decoration: none;

                    @include max-screen($screen__l) {
                        padding: 12px 10px;
                    }
                }
            }
        }
    }


    &.home-sidebar {
        background: #fff;
        padding: 40px 30px;

        ul.usps {
            list-style: none;

            li {
                position: relative;
                margin: 0 0 20px 0;

                &:before {
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 20px;
                    left: -35px;
                    background-image: url(../images/usp_check.svg);
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }
        }

        h5 {
            font-size: 18px;
            font-weight: normal;
        }

        .login {
            input {
                margin-bottom: 10px;
            }

            .actions {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                a {
                    color: #000;
                    font-size: 11px;
                    text-decoration: underline;
                }
            }
        }

        .separator {
            margin: 30px 0;
            position: relative;
            content: '';
            width: 100%;
            height: 5px;
            background-image: url(../images/separator_bg.svg);
            background-repeat: repeat-x;
            background-size: auto 100%;
            background-position: top left;
        }

        .trustpilot {
            position: relative;
            width: 75%;
            height: 75px;
            background-image: url(../images/trust-pilot.svg);
            background-size: contain;
            background-position: left center;
            background-repeat: no-repeat;
        }
    }

    &.banner {
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        cursor: pointer;

        .banner-image {
            position: relative;
            min-width: 100%;
            float: none;

            background-size: cover;
            background-position: center center;

            transition-property: opacity;
            transition-duration: 0.5s;
            opacity: 0;
            display: none;

            img {

                max-width: 100%;
            }

            .banner-content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                cursor: pointer;
            }

            &.active {
                display: block;
                opacity: 1;
            }
        }

        .bullets {
            position: absolute;
            right: 25px;
            bottom: 20px;
            display: flex;

            .bullet {
                display: block;
                position: relative;
                width: 16px;
                height: 16px;
                content: '';
                background: #fff;
                margin: 0 10px;
                border-radius: 50%;
                cursor: pointer;

                &.active {
                    background: #000;
                }
            }
        }

        .button {
            position: absolute;
            //z-index: 10;
            left: 40px;
            bottom: 30px;

            background: #000;
            color: #fff;

            border: 2px solid #000;

            &:hover {
                border: 2px solid #000;
                background: #fff;
                color: #000;
                text-decoration: none;
            }
        }
    }

    &.banner-blocks {
        display: flex;
        width: 100%;

        margin-top: 20px;

        @include max-screen($screen__m) {
            margin-top: 10px;
        }

        .banner-image {
            position: relative;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;

            a.button {
                height: auto;
            }
        }

        &.blocks-not-equal {
            .banner-image {
                width: 100%;

                img {
                    max-width: 100%;
                }
            }

            .banner-image:first-child {
                width: 25%;
                margin-right: 20px;
            }

            @include max-screen($screen__s) {
                .banner-image {
                    width: 100%;

                    &:first-child {
                        display: none;
                    }

                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                }
                flex-wrap: wrap;
            }

            a {
                position: absolute;
                left: 25px;
                bottom: 25px;
                background: #000;
                color: #fff;

                border: 2px solid #000;

                &:hover {
                    border: 2px solid #000;
                    background: #fff;
                    color: #000;
                    text-decoration: none;
                }
            }
        }

        &.blocks-equal {
            .banner-image {
                width: 50%;
            }

            .banner-image:first-child {
                margin-right: 10px;
            }

            .banner-image:last-child {
                margin-left: 10px;
            }

            @include max-screen($screen__s) {
                .banner-image {
                    margin-left: 0 !important;
                    margin-right: 0 !important;

                    width: 100%;

                    display: flex;
                    justify-content: center;

                    &:not(:first-child) {
                        margin-top: 15px;
                    }
                }
                flex-wrap: wrap;
            }

            a {
                position: absolute;
                right: 25px;
                bottom: 25px;
                background: #000;
                color: #fff;

                border: 2px solid #000;

                &:hover {
                    border: 2px solid #000;
                    background: #fff;
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }

    &.brand-slider {
        width: 100%;
        height: 140px;
        background: #fff;

        .logos {
            .brand {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 140px;
            }
        }
    }

    &.brand-logos {
        .brand {
            padding-bottom: 6.4rem;
            text-align: center;

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    &.brand-slider,
    &.brand-logos {
        .logos {
            .brand {
                img {
                    max-width: 180px;
                    filter: grayscale(100%);
                    transition-property: filter;
                    transition-duration: 0.3s;
                }

                &:hover img {
                    filter: none;
                }
            }
        }
    }

    &.widget-category-lister {
        background: #fff;
        padding: 15px;
        box-sizing: border-box;

        .category-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .category {
                width: 24%;
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include max-screen($screen__m) {
                    width: 50%;
                }

                @include max-screen($screen__s) {
                    width: 100%;
                }


                .image {
                    height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-height: 100%;
                    }
                }

                .cat-name {
                    background-color: #d4d4d4;
                    color: #000;
                    padding: 5px 20px;
                    text-align: center;
                    text-transform: uppercase;
                    border-radius: 4px;
                    margin-top: 20px;
                    max-width: 250px;
                    width: 100%;
                    box-sizing: border-box;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &.new-arrivals-events {
        display: flex;
        align-items: stretch;
        margin: 25px 0;

        @include max-screen($screen__m) {
            flex-direction: column;
        }

        .new-arrivals {
            background: #fff;
            width: 33%;
            margin-right: 25px;
            padding: 20px;
            box-sizing: border-box;

            @include max-screen($screen__m) {
                width: 100%;
                margin-bottom: 15px;
                margin-right: 0;
            }

            ul {
                margin-bottom: 0;

                li {
                    overflow: hidden;
                    text-overflow: ellipsis;

                    a {
                        color: #000;
                        font-size: 12px;
                        text-transform: uppercase;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        span.date {
                            margin-right: 5px;
                        }

                        b {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .hardrace-trustpilot {
            background-color: #fff;
            padding: 25px;
            box-sizing: border-box;
            width: 33%;
            margin-right: 25px;

            @include max-screen($screen__m) {
                width: 100%;
                margin-right: 0;
            }
        }

        .upcoming-events {
            background: #fff;
            width: 33%;
            margin-right: 25px;
            padding: 20px;
            box-sizing: border-box;

            @include max-screen($screen__m) {
                width: 100%;
                margin-right: 0;
            }

            ul {
                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    .date {
                        display: flex;
                        flex-direction: column;
                        width: 65px;
                        height: 65px;

                        align-items: center;
                        justify-content: center;

                        background: #1180AE;
                        color: #fff;

                        margin-right: 15px;

                        .month,
                        .day {
                            display: block;
                        }

                        .month {
                            font-size: 16px;
                        }

                        .day {
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    .event-details {
                        display: flex;
                        flex-direction: column;
                        color: #000;
                        margin-left: 0;
                        margin-right: 0;

                        .name {
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        h5,.h5 {
            border-left: 10px solid red;
            padding-left: 10px;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-size: 26px;
            font-weight: normal;

            span {
                font-weight: bold;
            }
        }

        ul {
            list-style: none;
            padding: 0;
        }

        .banners {
            width: 33%;
            display: flex;
            flex-direction: column;

            .banner {
                width: 100%;
                flex-grow: 1;

                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                &:first-child {
                    margin-bottom: 12.5px;
                }

                &:last-child {
                    margin-top: 12.5px;
                }
            }
        }

        &.new-arrivals-only {
            .banners {
                width: 66%;
                flex-direction: row;
                .banner {
                    margin: 0;
                    &.banner:first-of-type {
                        margin-right: 25px;
                    }
                }

            }

            &.with-trustbox {
                .banners {
                    width: 33%;
                    .banner:first-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &.new-arrivals-lister {
        .new-arrivals {
            width: 100%;
            margin-right: 0;

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            li {
                min-width: 35rem;
                width: 33%;

                @include max-screen($screen__l) {
                    width: 48%;
                }

                @include max-screen($screen__m) {
                    width: 100%;
                }
            }

            .single-lister {
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                padding: 8px;
                margin: 0 10px;
                min-height: 8rem;
                height: 8rem;

                .content-left {
                    height: 8rem;
                    width: 8rem;
                    margin-right: 1.5rem;
                }

                .content-right {
                    max-width: 25rem;
                }

                img {
                    min-height: 8rem;
                    min-width: 8rem;
                }
            }
        }
    }
}

.mage-accordion {
    div[data-role="collapsible"] {
        background: #000;
        color: #fff;
        //text-transform: uppercase;
        cursor: pointer;
        font-size: 16px;
        margin-top: 15px;

        &:hover {
            background: $bs-dark-gray;
        }

        div[data-role="trigger"] {
            position: relative;
            padding: 8px 15px;

            &:after {
                right: 15px;
                position: absolute;
                content: '';
                display: block;
                background-image: url(../images/accordion_indicator.svg);
                width: 18px;
                height: 18px;
                top: 10px;
            }
        }

        &.active {
            div[data-role="trigger"] {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    div[data-role="content"] {
        padding: 15px;
        border: 1px solid #000;
        border-top: 0;
    }
}

// app2-224 changing headers for seo
.h2 {
    font-weight: 300;
    line-height: 1.1;
}
