// Reset default styles with magento-reset
@include lib-magento-reset();

/*
FontAwesome
 */
$fa-font-path: "../fonts";
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/light';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/regular';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/solid';
@import '../../node_modules/@fortawesome/fontawesome-pro/scss/brands';

// Theme blocks
@import 'snowdog_boilerplate/blocks/extends';
@import 'snowdog_boilerplate/blocks/typography';
@import 'snowdog_boilerplate/blocks/layout';
@import 'snowdog_boilerplate/blocks/tables';
@import 'snowdog_boilerplate/blocks/messages';
@import 'snowdog_boilerplate/blocks/navigation';
@import 'snowdog_boilerplate/blocks/tooltips';
@import 'snowdog_boilerplate/blocks/loaders';
@import 'snowdog_boilerplate/blocks/forms';
@import 'snowdog_boilerplate/blocks/icons';
@import 'snowdog_boilerplate/blocks/buttons';
@import 'snowdog_boilerplate/blocks/sections';
@import 'snowdog_boilerplate/blocks/pages'; // Theme pager
@import 'snowdog_boilerplate/blocks/actions-toolbar';
@import 'snowdog_boilerplate/blocks/breadcrumbs';
@import 'snowdog_boilerplate/blocks/popups';
@import 'snowdog_boilerplate/blocks/price';

// Components styles (modal/sliding panel)
//TODO: Optimize these, as just some popups are good for 2kb of css, COMPRESSED.
@import 'snowdog_boilerplate/blocks/components/modals'; // from lib
@import 'brakesshop/components/modals_extend'; // local
@import 'brakesshop/components/dropdowns'; // local
@import 'brakesshop/components/buttons'; // local

// Modules
@import 'modules/Magento_CatalogSearch/styles/module';
@import 'modules/Magento_AdvancedCheckout/styles/module';
@import 'modules/Magento_Braintree/styles/module';
@import 'modules/Magento_Bundle/styles/module';
@import 'modules/Magento_Catalog/styles/module';
@import 'modules/Magento_CatalogEvent/styles/module';
@import 'modules/Magento_Checkout/styles/module'; // Required
@import 'modules/Magento_Customer/styles/module';
@import 'modules/Magento_Downloadable/styles/module';
@import 'modules/Magento_GiftCard/styles/module';
@import 'modules/Magento_GiftCardAccount/styles/module';
@import 'modules/Magento_GiftMessage/styles/module';
@import 'modules/Magento_GiftRegistry/styles/module';
@import 'modules/Magento_GiftWrapping/styles/module';
@import 'modules/Magento_GroupedProduct/styles/module';
@import 'modules/Magento_Invitation/styles/module';
@import 'modules/Magento_LayeredNavigation/styles/module';
@import 'modules/Magento_Msrp/styles/module';
@import 'modules/Magento_MultipleWishlist/styles/module';
@import 'modules/Magento_Multishipping/styles/module';
@import 'modules/Magento_Newsletter/styles/module';
@import 'modules/Magento_Paypal/styles/module';
//@import 'modules/Magento_ProductVideo/styles/module';
@import 'modules/Magento_Review/styles/module';
@import 'modules/Magento_Reward/styles/module';
@import 'modules/Magento_Rma/styles/module';
@import 'modules/Magento_Sales/styles/module';
@import 'modules/Magento_SalesRule/styles/module';
@import 'modules/Magento_SendFriend/styles/module';
@import 'modules/Magento_Swatches/styles/swatches';
@import 'modules/Magento_Theme/styles/module';
@import 'modules/Magento_Vault/styles/module';
@import 'modules/Magento_Weee/styles/module';
@import 'modules/Magento_Wishlist/styles/module';
@import 'modules/Amasty_Shopby/amshopby';
@import 'modules/Amasty_Promo/module';
@import 'modules/Amasty_Brands/ambrands';
@import "modules/Mirasvit_Search/module";
@import "modules/Mirasvit_SearchAutocomplete/module/module";



// Widgets
@import 'modules/Magento_AdvancedCheckout/styles/widgets';
@import 'modules/Magento_Banner/styles/widgets';
@import 'modules/Magento_Catalog/styles/widgets';
@import 'modules/Magento_CatalogEvent/styles/widgets';
@import 'modules/Magento_Cms/styles/widgets';
@import 'modules/Magento_MultipleWishlist/styles/widgets';
@import 'modules/Magento_VersionsCms/styles/widgets';

@import "global";


// Brakes-Shop styling
@import 'brakesshop/base';
@import 'brakesshop/header';
@import 'brakesshop/header-fitments';
@import 'brakesshop/header-headroom';
@import 'brakesshop/footer';
@import 'brakesshop/home';
@import 'brakesshop/listings';
@import 'brakesshop/category';
@import 'brakesshop/productfilters';
@import 'brakesshop/product';
@import 'brakesshop/fitments';
@import 'brakesshop/fitments-search';
@import 'brakesshop/fitments-usps';
@import 'brakesshop/widgets';
@import 'brakesshop/widgets/widgets';
@import 'brakesshop/cart';
@import 'brakesshop/checkout';
@import 'brakesshop/cms';
@import 'brakesshop/account';
@import 'brakesshop/ajaxcart';
@import 'brakesshop/wishlist';
@import "brakesshop/country_popup";
@import "brakesshop/spare-parts";
@import "brakesshop/free-shipping-message";

@import 'brakesshop/plugins/slick/slick';
@import 'brakesshop/plugins/amasty/social_login';
@import 'brakesshop/plugins/amasty/giftcards';
@import 'brakesshop/plugins/amasty/gdpr';
@import 'brakesshop/plugins/amasty/ajax_cart';
@import 'brakesshop/plugins/amasty/x_search';

@import 'brakesshop/plugins/przwlewy24';

// Custom Changes by Brakesshop
// TODO: Merge below file into the correct files
@import 'brakesshop/brakesshop-custom';
@import "brakesshop/freegift";

@import "cms/cms";
