.modal-popup {
  z-index: 1000001;
  left: 0;
  overflow-y: auto;
}

.video-modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  width: 100%;
  z-index: 99999 !important;

  .fotorama__fullscreen-icon {
    position: absolute;
    display: block !important;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    background-position: -80px 0;
    cursor: pointer;
    z-index: 1000;
  }

  iframe {
    max-width: 800px;
    margin: 0 auto;
    display: block;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
  }

  &.hidden {
    display: none;
  }
}