@media only screen and (max-width: 767px) {
    .am-porto-cmtb .filter.active .filter-current.filter-current.amshopby-filter-current + .block-actions {
        top: 187px;
        border: none;
        border-radius: inherit;
        background: #fff;
        box-shadow: none
    }
    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current {
        padding: 17px 30px;
        height: 140px
    }
    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current ~ .filter-options {
        top: 221px
    }
    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current .items {
        padding: 15px;
        border-width: 1px
    }
    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current .filter-current-subtitle {
        display: block;
        padding: 10px 15px
    }
    .am-porto-cmtb .filter.active .block-category-list .block-title, .am-porto-cmtb .filter.active .filter-options-title, .am-porto-cmtb .filter.active .filter .filter-current-subtitle, .am-porto-cmtb .filter.active .block-category-list .block-content, .am-porto-cmtb .filter.active .filter-options-content {
        padding-left: 15px;
        padding-right: 15px
    }
    .am-porto-cmtb .filter.active .filter-options-title:after {
        right: 15px
    }
    .filter-options-content .am-ranges .items.-am-multiselect .item [class*=am-filter-item] {
        padding-left: 35px
    }
    .filter-options .am-labels-folding .item .am-collapse-icon ~ [class*=am-filter-item-], .filter-options .am-labels-folding .item {
        padding-left: 35px
    }
    .filter-options .items:not(.items-children).-am-multiselect > .item.-is-by-click .items-children.-folding .item.-is-expanded, .filter-options .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible), .filter-options .items:not(.items-children) > .item.-is-by-click.-filter-parent ~ .item.-is-expanded {
        margin-left: 35px
    }
    .filter-options-item .filter-options-content .items [class*=am-filter-item] {
        padding: 7px 0
    }
    .am-labels-folding .am-collapse-icon, .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon, .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon {
        margin: 7px 0
    }
    .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ .am-input, .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ .am-input, .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=radio], .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=radio], .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=checkbox], .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=checkbox], .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=checkbox] + .amshopby-choice, .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=checkbox] + .amshopby-choice {
        left: 35px
    }
    .filter-options .items.-am-singleselect .item.-is-by-click .items-children.-folding .item .amshopby-filter-parent, .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .items-children.-folding, .filter-options .am-category-wrapper .items .items-children.-folding, .filter-options .am-category-view .item a.amshopby-filter-parent {
        padding-left: 35px
    }
    .filter-options .am-category-view.-am-multiselect .item > .am-collapse-icon ~ a.amshopby-filter-parent {
        padding-left: 70px
    }
    .filter-options .am-category-view.-am-singleselect .am-collapse-icon {
        margin: 7px 0
    }
    .am-collapse-icon:after, .am-show-more:before {
        width: 20px
    }
    .filter-content .active .am-show-more:before {
        width: 12px
    }
    .filter-content .active .am-show-more.-active {
        margin-bottom: 10px
    }
    .amshopby-flyout-block {
        margin: 10px 0 25px
    }
    .amshopby-flyout-block .items.amshopby-fly-out-view .item > .amshopby-choice {
        padding-left: 35px
    }
    .filter-options input[type=checkbox] {
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        position: absolute
    }
    .filter-options input[type=checkbox] + .amshopby-choice {
        word-wrap: break-word;
        word-break: break-word;
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        margin: 0;
        padding: 0 0 0 35px;
        min-height: 25px;
        line-height: 1.2;
        cursor: pointer
    }
    .ie11 .filter-options input[type=checkbox] + .amshopby-choice {
        word-break: break-all
    }
    .filter-options input[type=checkbox] + .amshopby-choice:before, .filter-options input[type=checkbox] + .amshopby-choice:after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        border-radius: 2px;
        content: ''
    }
    .filter-options input[type=checkbox] + .amshopby-choice:before {
        border: 1px solid #e3e3e3;
        background: #fff
    }
    .filter-options input[type=checkbox] + .amshopby-choice:after {
        background: #1979c3 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center/25px;
        content: none
    }
    .filter-options input[type=checkbox] + .amshopby-choice:hover:before, .filter-options input[type=checkbox]:checked + .amshopby-choice:before {
        border-color: #1979c3
    }
    .filter-options input[type=checkbox]:checked + .amshopby-choice:after {
        content: ''
    }
    ._keyfocus .filter-options input[type=checkbox]:active + .amshopby-choice:before, ._keyfocus .filter-options input[type=checkbox]:focus + .amshopby-choice:before, .filter-options input[type=checkbox]:active + .amshopby-choice:before, .filter-options input[type=checkbox]:focus + .amshopby-choice:before {
        border-color: #1979c3
    }
    .filter-options input[type=checkbox]:not(:checked).mage-error + .amshopby-choice:before {
        border-color: #f23b3b
    }
}

@media all and (min-width: 768px), print {
    .ui-tooltip.ui-widget {
        max-width: 450px
    }
    .filter-options-content a:hover {
        text-decoration: none
    }
    .catalog-topnav {
        display: block
    }
    .page-layout-1column .toolbar-products {
        position: inherit !important
    }
    .filter-options-content .am-filter-price {
        width: 40%
    }
    .sidebar > .block-category-list {
        margin-bottom: 14px
    }
    .amasty-catalog-topnav {
        margin-top: 3px
    }
    .amasty-catalog-topnav.catalog-topnav .filter-options .filter-options-content {
        z-index: 1001;
        min-width: 200px
    }
    .amasty-catalog-topnav .filter-options {
        margin-bottom: 20px;
        padding: 3px 0
    }
    .amasty-catalog-topnav .filter-subtitle {
        display: none
    }
    .amasty-catalog-topnav .amshopby-category-dropdown {
        min-width: 200px
    }
    .amasty-catalog-topnav .filter-options-item {
        margin-bottom: 0
    }
    .amasty-catalog-topnav .filter-options-item:first-child .filter-options-title {
        padding-left: 0
    }
    .amasty-catalog-topnav .filter-options-title {
        padding-right: 5px
    }
    .amasty-catalog-topnav .filter-options-title:after {
        position: static;
        display: inline-block;
        margin: 0 0 0 15px;
        transform: translateY(-2px)
    }
    .amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-flyout-block .items.amshopby-fly-out-view {
        margin: -11px;
        box-shadow: none
    }
    .filter-options input[type=checkbox] {
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        position: absolute
    }
    .filter-options input[type=checkbox] + .amshopby-choice {
        word-wrap: break-word;
        word-break: break-word;
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        margin: 0;
        padding: 0 0 0 22px;
        min-height: 12px;
        line-height: 1.2;
        cursor: pointer
    }
    .ie11 .filter-options input[type=checkbox] + .amshopby-choice {
        word-break: break-all
    }
    .filter-options input[type=checkbox] + .amshopby-choice:before, .filter-options input[type=checkbox] + .amshopby-choice:after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        content: ''
    }
    .filter-options input[type=checkbox] + .amshopby-choice:before {
        border: 1px solid #e3e3e3;
        background: #fff
    }
    .filter-options input[type=checkbox] + .amshopby-choice:after {
        background: #1979c3 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center/12px;
        content: none
    }
    .filter-options input[type=checkbox] + .amshopby-choice:hover:before, .filter-options input[type=checkbox]:checked + .amshopby-choice:before {
        border-color: #1979c3
    }
    .filter-options input[type=checkbox]:checked + .amshopby-choice:after {
        content: ''
    }
    ._keyfocus .filter-options input[type=checkbox]:active + .amshopby-choice:before, ._keyfocus .filter-options input[type=checkbox]:focus + .amshopby-choice:before, .filter-options input[type=checkbox]:active + .amshopby-choice:before, .filter-options input[type=checkbox]:focus + .amshopby-choice:before {
        border-color: #1979c3
    }
    .filter-options input[type=checkbox]:not(:checked).mage-error + .amshopby-choice:before {
        border-color: #f23b3b
    }
    .am_shopby_apply_filters {
        position: absolute;
        bottom: inherit;
        z-index: 212;
        display: none;
        visibility: hidden;
        padding: 0;
        width: inherit
    }
    .am_shopby_apply_filters.visible {
        bottom: inherit;
        visibility: visible
    }
    .am_shopby_apply_filters.-fixed {
        position: fixed
    }
    .am_shopby_apply_filters.-fixed .am-show-button:before {
        display: none
    }
    .am-show-button {
        position: relative;
        justify-content: space-between;
        margin: 0 0 10px;
        padding: 12px 10px;
        min-width: 180px;
        width: inherit;
        border: none;
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(190, 199, 219, .21), 0 6px 25px rgba(190, 199, 219, .28)
    }
    .am-show-button > .am-items {
        margin: 0 auto
    }
    .am-show-button:before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fff;
        content: '';
        transform: rotate(45deg)
    }
    .am-show-button.-vertical:before {
        top: calc(50% - (10px / 2));
        right: calc(100% - (10px / 2))
    }
    .am-show-button.-horizontal:before {
        top: calc(100% - (10px / 2));
        left: calc(50% - (10px / 2))
    }
    #amasty-shopby-product-list .amshopby-overlay-block > .amshopby-loader {
        top: 30%;
        transform: translate(-50%, -30%)
    }
}

@media all and (min-width: 1024px), print {
    .filter-options-content .am-filter-price {
        width: 43%
    }
    .amslider-container.am-swiper-slider {
        width: 85%
    }
}

.am-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0
}

.am-word-break {
    word-wrap: break-word;
    word-break: break-word
}

.ie11 .am-word-break {
    word-break: break-all
}

.ui-tooltip .arrow {
    left: 8px;
    width: 15px
}

.ui-tooltip .arrow:after {
    border-right: 15px solid transparent
}

.ui-tooltip .arrow:before {
    border-right: 14px solid transparent
}

.amslider-container {
    margin: auto;
    margin-bottom: 15px;
    width: 100%
}

.am-rating-up {
    font-size: smaller
}

.amshopby-highlighted {
    background-color: #ff0
}

.amshopby-item .filter-value {
    margin-left: 5px
}

.amshopby-filter-tooltip {
    display: inline-block;
    margin-left: 6px;
    max-width: 16px;
    max-height: 16px;
    vertical-align: top
}

.amshopby-filter-value .swatch-option.image, .amshopby-filter-value .swatch-attribute.size .swatch-option, .amshopby-filter-value .swatch-attribute.manufacturer .swatch-option {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.filter-options-item .filter-options-title:after {
    font-size: 14px
}

.range.am-fromto-widget .am-filter-go {
    margin: 0 10px;
    vertical-align: top
}

.amshopby-button {
    word-wrap: break-word;
    word-break: break-word;
    min-height: 40px;
    border-radius: 3px;
    background: #1979c3;
    color: #fff;
    text-align: center;
    letter-spacing: -.015em;
    font-size: 16px;
    line-height: 22px;
    transition: background .2s ease-in-out, border-color .2s ease-in-out
}

.ie11 .amshopby-button {
    word-break: break-all
}

.amshopby-button:not(.primary) {
    border-radius: 3px
}

.amshopby-button:not(.primary), .amshopby-button:not(.primary):active {
    box-shadow: none
}

.amshopby-button:hover {
    border-color: #399ae6;
    background: #399ae6
}

.amshopby-button:active, .amshopby-button:focus {
    border-color: #135d96;
    background: #135d96
}

.am-filter-go {
    word-wrap: break-word;
    word-break: break-word;
    min-height: 40px;
    border-radius: 3px;
    background: #1979c3;
    color: #fff;
    text-align: center;
    letter-spacing: -.015em;
    font-size: 16px;
    line-height: 22px;
    transition: background .2s ease-in-out, border-color .2s ease-in-out;
    margin: 15px 0 0;
    width: 25%
}

.ie11 .am-filter-go {
    word-break: break-all
}

.am-filter-go:not(.primary) {
    border-radius: 3px
}

.am-filter-go:not(.primary), .am-filter-go:not(.primary):active {
    box-shadow: none
}

.am-filter-go:hover {
    border-color: #399ae6;
    background: #399ae6
}

.am-filter-go:active, .am-filter-go:focus {
    border-color: #135d96;
    background: #135d96
}

input[type=text].am-filter-price.-to.right {
    float: right
}

.am-swatch-options {
    display: flex;
    flex-direction: column
}

.am-swatch-options .swatch-option {
    display: inline-block;
    margin: 0 10px 0 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.am-swatch-options > .item {
    padding-bottom: 4px
}

.am-swatch-options > .item:hover {
    background-color: transparent
}

.am-swatch-options .am-shopby-swatch-label {
    display: inline-block;
    max-width: 70%;
    vertical-align: middle;
    word-wrap: break-word
}

.filter-options .am-swatch-wrapper {
    margin: 0 0 5px !important;
    line-height: initial
}

.filter-options .am-swatch-wrapper.-amshopby-hidden {
    position: absolute;
    visibility: hidden;
    opacity: 0
}

.catalog-topnav .filter-options {
    flex-wrap: wrap;
    min-height: 40px
}

.catalog-topnav .filter-options .filter-options-item {
    display: inline-block;
    border: 0
}

.catalog-topnav .filter-options .filter-options-content {
    position: absolute;
    z-index: 9999;
    margin-top: 12px;
    min-width: 100px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(190, 199, 219, .21), 0 6px 25px rgba(190, 199, 219, .28)
}

.catalog-topnav .filter-options .am-filter-items-rating li {
    margin: 0 0 10px
}

.catalog-topnav .filter-options .am-filter-items-rating li:last-child {
    margin: 0
}

.catalog-topnav .block-actions {
    display: none
}

.catalog-topnav {
    display: none
}

.filter-options .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 5px;
    right: 8px
}

.page-layout-1column .filter-options-item.active .filter-options-content {
    padding: 10px 15px
}

.page-layout-1column .filter-options-content li.item {
    padding: 0
}

.filter [data-role=collapsible]:not([data-collapsible=true]) [data-role=content] {
    display: none
}

div.swatch-option-tooltip {
    z-index: 9999
}

.swatch-option-tooltip .image, .swatch-option-tooltip-layered .image {
    background-size: contain !important
}

.filter-options .am-labels-folding .item {
    position: relative
}

.filter-options .ui-menu {
    display: block;
    margin: 0;
    padding: 0;
    outline: 0;
    list-style: none
}

.filter-options .ui-menu .ui-menu {
    position: absolute
}

.filter-options .ui-menu .ui-menu-item {
    margin: 0;
    list-style: none;
    cursor: pointer
}

.filter-options .ui-menu .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px .4em
}

.filter-options .ui-menu .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    border-width: 1px 0 0;
    font-size: 0;
    line-height: 0
}

.filter-options-content .am-swatch-wrapper {
    display: inline-block
}

.filter-options-content .am-swatch-wrapper:hover .am-swatch-link {
    background: 0 0
}

.filter-options-content .am-swatch-wrapper > .input.-hidden {
    display: none
}

.am-filter-items-attr_price {
    min-width: 130px;
    max-width: 350px
}

.am-filter-items-attr_color .swatch-option.text {
    padding: 1px 2px;
    width: 30px
}

.amasty-catalog-topnav.block.filter {
    flex-basis: auto
}

.amasty-catalog-topnav .filter-options .item:last-child {
    margin-bottom: 0
}

.amshopby-category-dropdown {
    display: block;
    overflow: hidden;
    padding-right: 3.6rem;
    min-height: 3.2rem;
    border: 1px solid #adadad;
    background-color: #fff;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 400;
    cursor: pointer
}

.amshopby-category-dropdown:after {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    box-sizing: border-box;
    margin-top: -.2rem;
    width: 0;
    height: 0;
    border-width: .5rem .4rem 0;
    border-style: solid;
    border-color: #000 transparent transparent;
    content: '';
    transition: all .2s linear
}

.amshopby-category-dropdown:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3.2rem;
    border: 1px solid #adadad;
    background-color: #e3e3e3;
    content: ''
}

.am-category-wrapper {
    position: relative;
    display: block;
    user-select: none
}

.am-category-wrapper.-active .amshopby-category-dropdown:after {
    transform: rotate(180deg)
}

.am-category-wrapper .amshopby-checkbox, .am-category-wrapper .amshopby-radio {
    margin-left: 20px
}

.am-category-wrapper .amshopby-filter-parent {
    padding-left: 0
}

.am-category-wrapper .am-multiselect-crumb {
    position: relative;
    display: inline-block;
    margin: .3rem 0 .3rem .3rem;
    padding: .3rem 2.4rem .4rem 1rem;
    border: 1px solid #a79d95;
    border-radius: 1px;
    background-color: #f5f5f5;
    font-size: 1.2rem;
    transition: border-color .1s linear
}

.am-category-wrapper .am-multiselect-crumb .action-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 2rem;
    height: auto;
    border: none;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    color: #514943;
    font-size: .5em;
    line-height: 1
}

.am-category-wrapper .am-multiselect-crumb .action-close:before {
    content: 'x';
    font-size: 10px;
    transition: color .1s linear
}

.am-category-wrapper .am-multiselect-crumb .action-close > span {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    clip: rect(0, 0, 0, 0)
}

.filter-options .am-labels-folding .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0 0 0 20px
}

.filter-options .am-labels-folding .item .am-input, .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) input[type=radio], .filter-options .amshopby-category-dropdown + form .item input[type=radio], .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) input[type=checkbox], .filter-options .amshopby-category-dropdown + form .item input[type=checkbox] {
    position: absolute;
    top: 4px;
    left: 0;
    margin: 0 5px 0 0
}

.filter-options .am-labels-folding .item .am-collapse-icon ~ .am-input {
    left: 20px
}

.filter-options .am-labels-folding .item .am-collapse-icon ~ [class*=am-filter-item-] {
    padding-left: 20px
}

.filter-options .amshopby-category-dropdown + form .item {
    flex-wrap: wrap
}

.filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered), .filter-options .amshopby-category-dropdown + form .item {
    position: relative;
    display: block
}

.filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ .am-input, .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ .am-input, .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=radio], .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=radio], .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=checkbox], .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=checkbox], .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=checkbox] + .amshopby-choice, .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=checkbox] + .amshopby-choice {
    left: 20px
}

.filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .items-children.-folding {
    padding-left: 20px
}

.am-labels-folding .am-collapse-icon, .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    align-self: flex-start
}

.am-labels-folding .am-collapse-icon:after, .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon:after, .am-labels-folding .am-show-more:before {
    position: static;
    margin: 0
}

.am-collapse-icon {
    position: absolute;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    cursor: pointer;
    -webkit-font-smoothing: antialiased
}

.am-collapse-icon:after, .am-show-more:before {
    position: absolute;
    right: 0;
    width: 12px;
    height: 20px;
    background: url("../Amasty_Shopby/images/svg/arrow-bottom.svg") no-repeat 100%/contain;
    content: ''
}

.am-collapse-icon.-active:after {
    content: '';
    transform: rotate(180deg)
}

.amshopby-option-link {
    display: inline-block;
    clear: both;
    margin-right: 5px
}

.amshopby-option-link img {
    float: left;
    margin: 0 5px 5px 0
}

.amshopby-option-link img:not([src*='/resized']) {
    max-width: 30px;
    max-height: 30px
}

.amshopby-item-removed {
    text-decoration: none
}

.am-category-image {
    vertical-align: middle
}

.amshopby-filter-current .filter-label {
    vertical-align: middle
}

.amshopby-filter-current .amshopby-items {
    padding: 0 0 0 10px
}

.amshopby-filter-current .amshopby-item.item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0 0 0 20px
}

.amshopby-filter-current .amshopby-filter-name {
    display: flex;
    padding: 0 5px 0 0;
    font-weight: 700
}

.amshopby-filter-current .amshopby-filter-name:after {
    content: ':'
}

.ie11 .amshopby-filter-current .amshopby-filter-name:after {
    padding-left: 1px
}

.amshopby-filter-current .amshopby-filter-value {
    flex-grow: 1
}

.amshopby-filter-current .am-swatch-link {
    display: flex;
    align-items: center
}

.amshopby-filter-current .amshopby-filter-value .am-swatch-options .swatch-option {
    margin: 0
}

.amshopby-filter-current .amshopby-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px
}

.ie11 .amshopby-filter-current .amshopby-remove {
    top: calc(50% - (12px / 2))
}

.amshopby-filter-current .amshopby-remove:hover:after, .amshopby-filter-current .amshopby-remove:hover:before {
    background-color: #494949
}

.amshopby-filter-current .amshopby-remove:hover ~ * {
    opacity: .5
}

.amshopby-filter-current .amshopby-remove:after, .amshopby-filter-current .amshopby-remove:before {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #c7c7c7;
    content: ''
}

.amshopby-filter-current .amshopby-remove:after {
    transform: rotate(45deg)
}

.amshopby-filter-current .amshopby-remove:before {
    transform: rotate(-45deg)
}

.amshopby-filter-current .am-shopby-swatch-label {
    margin-left: 10px
}

.amshopby-filter-current .swatch-option-link-layered {
    display: flex;
    align-items: center;
    flex-basis: 100%
}

.amshopby-filter-current .item {
    padding-bottom: 0
}

.am-dropdown .am-select, .am-dropdown .chosen-container .chosen-choices {
    min-height: 32px;
    border-color: #ccc;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif
}

.am-dropdown .chosen-container .chosen-choices {
    background-image: none
}

.am-dropdown .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    min-width: 100px;
    color: #000;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif
}

.am-porto-cmtb .amshopby-filter-current.filter-current .amshopby-item.item {
    display: flex;
    align-items: center;
    padding: 0 0 10px 20px
}

.am-porto-cmtb .amshopby-filter-current.filter-current .items {
    padding: 15px
}

.am-porto-cmtb .amshopby-filter-current.filter-current .am-show-more {
    padding: 12px 25px 0 0
}

.am-porto-cmtb .amshopby-filter-current.filter-current .category-image .image {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto
}

.am-porto-cmtb .filter-options-content .swatch-option {
    min-width: 22px;
    width: 22px;
    height: 22px;
    border: none;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2)
}

.am-porto-cmtb .page-wrapper > .page-header {
    z-index: 900
}

.am-porto-cmtb .page-main {
    z-index: 9
}

.am-porto-cmtb .am-show-more:hover {
    color: #006bb4 !important
}

.filter-options-item .filter-options-content .amshopby-slider-wrapper {
    margin: 0 10px
}

.amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-slider-wrapper, .filter-options-item .filter-options-content .amshopby-slider-wrapper > .items:not(.items-children):not(.am-category-view), .filter-options-item .filter-options-content .amshopby-slider-wrapper .amshopby-fromto-wrap {
    margin: 0
}

.amshopby-slider-container {
    margin: 10px 0 5px
}

.amshopby-slider-container .ui-slider-range {
    position: absolute;
    z-index: 2
}

.amshopby-slider-container .am-slider:not(.-loaded) {
    visibility: hidden;
    opacity: 0
}

.amshopby-slider-container .am-slider:before, .amshopby-slider-container .am-slider:after {
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    content: ''
}

.amshopby-slider-container .am-slider .ui-slider-handle {
    display: flex;
    justify-content: center;
    overflow: inherit;
    margin: 0;
    padding: 0;
    cursor: pointer
}

.amshopby-slider-container .amshopby-slider-display {
    margin: 10px 0 0;
    text-align: center
}

.amshopby-slider-container .ui-slider-handle.ui-state-hover ~ .ui-slider-handle, .amshopby-slider-container .ui-slider-handle.ui-state-active ~ .ui-slider-handle {
    pointer-events: none;
    user-select: none
}

.amshopby-slider-container .amshopby-slider-tooltip {
    position: absolute;
    bottom: calc(100% + 7px);
    z-index: 1;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 3px;
    border: 1px solid #fff;
    border-radius: 2px;
    letter-spacing: .03em;
    font-size: 12px;
    line-height: 1
}

.amshopby-slider-container .amshopby-slider-tooltip:before, .amshopby-slider-container .amshopby-slider-tooltip:after {
    position: absolute;
    top: calc(100% - 4px);
    display: block;
    box-sizing: content-box;
    width: 6px;
    height: 6px;
    border: 1px solid;
    border-color: inherit;
    content: '';
    transform: rotate(45deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container .amshopby-slider-tooltip:after {
    background: inherit
}

.amshopby-slider-container.-default .am-slider {
    margin: 0 8px;
    height: 10px;
    background: #dadada
}

.amshopby-slider-container.-default .am-slider:before, .amshopby-slider-container.-default .am-slider:after {
    width: 16px;
    height: 10px
}

.amshopby-slider-container.-default .am-slider:before {
    left: -8px
}

.amshopby-slider-container.-default .am-slider:after {
    right: -8px
}

.amshopby-slider-container.-default .ui-slider-range {
    height: 10px;
    background: #b6b6b6
}

.amshopby-slider-container.-default .am-slider .ui-slider-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-8px, -3px)
}

.filter-options-item .filter-options-content .amshopby-fromto-wrap .am-fromto-widget {
    flex-wrap: wrap;
    margin-top: 10px
}

.filter-options-item .filter-options-content .amshopby-slider-wrapper .amshopby-fromto-wrap .am-fromto-widget {
    margin-top: 25px
}

.filter-options-item .filter-options-content .amshopby-slider-wrapper.-volumetric .amshopby-fromto-wrap .am-fromto-widget {
    margin-top: 35px
}

.amasty-catalog-topnav .amshopby-fromto-wrap .am-fromto-widget {
    max-width: 210px
}

.amshopby-fromto-wrap .am-fromto-widget > .amshopby-input-wrapper {
    position: relative;
    max-width: 50%;
    width: calc(50% - 7px)
}

.amshopby-fromto-wrap .am-fromto-widget .am-filter-price {
    width: 100%;
    height: 40px;
    border: 1px solid #dfdedd;
    background-clip: padding-box;
    color: #494949;
    text-align: center;
    letter-spacing: .03em;
    font-size: 14px
}

.amshopby-fromto-wrap .am-fromto-widget > .delimiter {
    margin: 0;
    width: 14px;
    line-height: 40px
}

.amshopby-fromto-wrap .am-fromto-widget > .am-filter-go {
    margin: 15px 0 0;
    width: 100%
}

.amshopby-fromto-wrap .am-fromto-widget > .am-filter-go:not(.primary), .amshopby-fromto-wrap .am-fromto-widget > .am-filter-go:active {
    box-shadow: none
}

.amshopby-input-wrapper .amshopby-currency {
    position: absolute;
    bottom: calc(100% - 10px);
    left: 4px;
    padding: 2px;
    background: #fff;
    color: #494949;
    letter-spacing: .03em;
    font-size: 14px;
    line-height: 16px
}

.amshopby-slider-tooltip {
    color: #fff;
    transition: all .2s ease-in-out
}

.ui-slider-handle:not(.ui-state-hover):not(.ui-state-active) .amshopby-slider-tooltip {
    visibility: hidden;
    opacity: 0
}

.amshopby-slider-container.-improved {
    margin-top: 30px
}

.amshopby-slider-container.-improved .am-slider {
    margin: 0 8px;
    height: 4px;
    background: #dfdedd
}

.amshopby-slider-container.-improved .am-slider:before, .amshopby-slider-container.-improved .am-slider:after {
    width: 16px;
    height: 4px;
    background: #dfdedd
}

.amshopby-slider-container.-improved .am-slider:before {
    left: -8px
}

.amshopby-slider-container.-improved .am-slider:after {
    right: -8px
}

.amshopby-slider-container.-improved .ui-slider-range {
    height: 4px
}

.amshopby-slider-container.-improved .am-slider .ui-slider-handle {
    top: -6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translateX(-8px)
}

.amshopby-slider-container.-volumetric {
    margin-top: 20px
}

.amshopby-slider-container.-volumetric .am-slider {
    margin: 0 4px;
    height: 4px;
    border-radius: 20px;
    background: #dfdedd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .25)
}

.amshopby-slider-container.-volumetric .am-slider:before, .amshopby-slider-container.-volumetric .am-slider:after {
    display: none
}

.amshopby-slider-container.-volumetric .ui-slider-range {
    height: 4px;
    border-radius: 20px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .25)
}

.amshopby-slider-container.-volumetric .am-slider .ui-slider-handle {
    width: 8px;
    height: 12px;
    border-radius: 2px;
    transform: translate(-4px, 10px)
}

.amshopby-slider-container.-volumetric .am-slider .ui-slider-handle:before {
    position: absolute;
    bottom: calc(100% - 4px);
    left: calc(50% - 3px);
    display: block;
    width: 6px;
    height: 6px;
    background: inherit;
    content: '';
    transform: rotate(-135deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container.-volumetric .amshopby-slider-tooltip {
    bottom: calc(100% + 17px);
    border-radius: 3px
}

.amshopby-slider-container.-volumetric .amshopby-slider-display {
    margin-top: 20px
}

.amshopby-slider-container.-light {
    margin-top: 30px
}

.amshopby-slider-container.-light .am-slider {
    margin: 0 8px;
    height: 1px;
    background: #dfdedd
}

.amshopby-slider-container.-light .am-slider:before, .amshopby-slider-container.-light .am-slider:after {
    width: 16px;
    height: 1px;
    background: #dfdedd
}

.amshopby-slider-container.-light .am-slider:before {
    left: -8px
}

.amshopby-slider-container.-light .am-slider:after {
    right: -8px
}

.amshopby-slider-container.-light .ui-slider-range {
    height: 1px
}

.amshopby-slider-container.-light .am-slider .ui-slider-handle {
    top: -8px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 1px solid #dfdedd;
    border-radius: 50%;
    background: #fff;
    transform: translateX(-8px)
}

.amshopby-slider-container.-light .amshopby-slider-tooltip {
    border-color: #dfdedd
}

.amshopby-slider-container.-light .amshopby-slider-tooltip:after, .amshopby-slider-container.-light .amshopby-slider-tooltip:before {
    background: #fff
}

.amshopby-slider-container.-dark {
    margin-top: 30px
}

.amshopby-slider-container.-dark .am-slider {
    margin: 0 5px;
    height: 3px;
    background: #4a4948
}

.amshopby-slider-container.-dark .am-slider:before, .amshopby-slider-container.-dark .am-slider:after {
    width: 10px;
    height: 3px
}

.amshopby-slider-container.-dark .am-slider:before {
    left: -5px
}

.amshopby-slider-container.-dark .am-slider:after {
    right: -5px
}

.amshopby-slider-container.-dark .ui-slider-range {
    height: 3px
}

.amshopby-slider-container.-dark .am-slider .ui-slider-handle {
    top: -6px;
    width: 10px;
    height: 15px;
    border-radius: 2px;
    transform: translateX(-5px)
}

.filter-options-item .filter-options-content {
    padding-bottom: 5px
}

.filter-options-item .filter-options-content .am-swatch-wrapper .swatch-option.image, .filter-options-item .filter-options-content .swatch-attribute.size .swatch-option.selected, .filter-options-item .filter-options-content .swatch-attribute.manufacturer .swatch-option.selected {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.filter-options-item .filter-options-content .range {
    display: flex;
    align-items: center;
    margin-top: 15px;
    text-align: center
}

.filter-options-item .filter-options-content .items:not(.items-children):not(.am-category-view), .filter-options-item .filter-options-content .am-category-wrapper, .filter-options-item .filter-options-content .amshopby-fromto-wrap {
    margin: 0 10px
}

.filter-options-item .filter-options-content .am-shopby-form {
    margin: 0 7px
}

.filter-options-item .filter-options-content .items [class*=am-filter-item] {
    margin-left: 0;
    padding: 0
}

.filter-options-item .filter-options-content .count {
    display: inline-flex;
    padding-left: 0
}

.filter-options-item .filter-options-content .am-shopby-swatch-label .count {
    margin: 0;
    padding: 0
}

.filter-options-item .filter-options-content .am-shopby-swatch-label .label {
    margin-right: 5px
}

.filter-options-item .filter-options-content .am-shopby-form {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: -15px;
    padding: 3px
}

.filter-options-item .filter-options-content .am-shopby-form .swatch-attribute .swatch-option-link-layered:hover {
    background-color: unset
}

.amasty-catalog-topnav .filter-options-item .filter-options-content {
    padding-bottom: 10px
}

.amasty-catalog-topnav .filter-options-item .filter-options-content .items:not(.items-children):not(.am-category-view), .amasty-catalog-topnav .filter-options-item .filter-options-content .am-shopby-form, .amasty-catalog-topnav .filter-options-item .filter-options-content .am-category-wrapper, .amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-fromto-wrap {
    margin: 0
}

.filter-options .items.-am-overflow {
    overflow-x: hidden;
    overflow-y: auto
}

.filter-options .items .items-children.-folding {
    padding-left: 20px;
    width: 100%;
    list-style: none
}

.filter-options .items.-am-singleselect .item.-is-by-click .items-children.-folding .item .amshopby-filter-parent {
    padding-left: 20px
}

.filter-options .items.-am-multiselect .item.-is-expanded .items-children.-folding {
    padding-left: 0
}

.filter-options .items .items-children.-folding .item {
    margin: 5px 0
}

.filter-options .item.-empty-value {
    opacity: .5;
    pointer-events: none;
    user-select: none
}

.filter-options .ui-widget-overlay {
    background: #aaa;
    opacity: .3
}

.filter-options .ui-widget-shadow {
    box-shadow: 0 0 5px #666
}

.filter-options .am-category-view {
    position: absolute;
    z-index: 9;
    display: none;
    overflow-y: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    max-height: 300px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    white-space: nowrap
}

.filter-options .items.am-category-view, .filter-options .am-category-view ol.items {
    margin: 0
}

.filter-options .am-category-view.-am-singleselect .am-collapse-icon {
    top: 0
}

.filter-options .am-category-view.-am-singleselect .am-collapse-icon:after, .filter-options .am-category-view.-am-singleselect .am-show-more:before {
    right: inherit;
    left: 0
}

.filter-options .am-category-view .item a.amshopby-filter-parent {
    padding-left: 20px
}

.filter-options .am-category-view.-am-multiselect .item > .am-collapse-icon ~ a.amshopby-filter-parent {
    padding-left: 40px
}

.filter-options .am-category-view [class*=am-filter-item] {
    display: inline-block;
    white-space: initial
}

.filter-options .filter-options-content .rating-summary {
    display: inline-block;
    vertical-align: sub
}

.filter-options .amshopby-search-box {
    margin: 0 0 15px
}

.filter-options .am-labels-folding.-am-singleselect .item .am-input, .filter-options [class*=am-filter-items-].am-labels-folding.-am-singleselect .item:not(.swatch-option-link-layered) input[type=radio], .filter-options .amshopby-category-dropdown + form .-am-singleselect .item input[type=radio], .filter-options .am-category-view input[type=radio] {
    display: none
}

.filter-options .am-labels-folding.-am-singleselect .item {
    padding: 0
}

.filter-options .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible), .filter-options .items:not(.items-children) > .item.-is-by-click.-filter-parent ~ .item.-is-expanded {
    margin-left: 20px
}

.filter-options-content .am-ranges .items.-am-multiselect .item {
    position: relative
}

.filter-options-content .am-ranges .items.-am-multiselect .item [class*=am-filter-item] {
    padding-left: 20px
}

.filter-actions .filter-clear {
    color: #1979c3;
    font-size: 14px;
    line-height: 19px
}

.filter-actions .filter-clear:hover {
    color: #399ae6;
    text-decoration: none
}

.filter-actions .filter-clear:focus, .filter-actions .filter-clear:active {
    color: #135d96
}

.filter-options-content .item a:hover {
    background-color: transparent;
    color: #1979c3
}

.filter-options-content .item a:hover .count {
    color: #1979c3
}

.filter-options-content .item a:active, .filter-options-content .item a:active .count {
    color: #135d96;
    text-decoration: none
}

.filter-options-content .item:first-child {
    margin-top: 0
}

.filter-options-content .item:last-child {
    margin-bottom: 0
}

.filter-options-content .item > a {
    display: block
}

.filter-options-content .items.-am-singleselect .item > input[type=radio] {
    display: none
}

.filter-options-content .items.-am-singleselect .amshopby-link-selected, .filter-options-content .swatch-option.selected + .am-shopby-swatch-label {
    color: #1979c3
}

.filter-options-content .items.-am-singleselect .amshopby-link-selected .count, .filter-options-content .swatch-option.selected + .am-shopby-swatch-label .count {
    color: #1979c3
}

.am-show-more, .filter-options-content .am-show-more {
    position: relative;
    display: none;
    margin: 10px 0 0;
    padding: 0 22px 0 0;
    color: #070605;
    letter-spacing: .03em;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer
}

.amasty-catalog-topnav .filter-options-content .am-show-more {
    padding-left: 0
}

.filter-options-content .am-show-more {
    padding-left: 10px
}

.am-show-more:hover, .filter-options-content .am-show-more:hover {
    background-color: transparent;
    color: #838382;
    text-decoration: none
}

.am-show-more:active, .filter-options-content .am-show-more:active {
    color: #070605
}

.am-show-more:before {
    top: 0
}

.am-show-more.-active {
    display: inline-flex;
    align-items: center
}

.am-show-more .am-counter {
    display: inline-block;
    margin: 0 2px;
    opacity: .5
}

.am-show-more[data-is-hide=false]:before {
    transform: rotate(180deg)
}

.amshopby-link-selected {
    color: #1979c3
}

.items[class*=category_ids]:not(.items-children) > .item > a {
    font-weight: 700
}

.filter-options .items:not(.items-children).-am-multiselect > .item.-is-by-click .items-children.-folding {
    padding-left: 0
}

.filter-options .items:not(.items-children).-am-multiselect > .item.-is-by-click .items-children.-folding .item.-is-expanded {
    margin-left: 20px
}

.am-filter-items-stock .amshopby-link-selected, .am-filter-items-rating .amshopby-link-selected {
    text-decoration: none
}

.filter-content [class*=am-filter-item]:hover ~ .amshopby-choice:before {
    border-color: #1979c3
}

.filter-content .items.-am-multiselect [class*=am-filter-item]:hover {
    color: inherit
}

.filter-content .items.-am-multiselect [class*=am-filter-item]:hover .count {
    color: #757575
}

.amshopby-fly-out-view.ui-menu, .amshopby-flyout-block .items.amshopby-fly-out-view .item .items-children {
    position: relative;
    padding: 13px 0;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(190, 199, 219, .21), 0 6px 25px rgba(190, 199, 219, .28)
}

.amshopby-fly-out-view .ui-menu {
    width: 100%
}

.amshopby-fly-out-view .items-children:not(.ui-menu) {
    display: none
}

.amshopby-fly-out-view .am-input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none; // firefox
}

.amshopby-fly-out-view .am-input[type=radio] + .amshopby-choice {
    display: none
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin: 0;
    padding: 4px 20px;
    font-size: 14px;
    line-height: 18px
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item:hover {
    background-color: #f8f8f9
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item .items-children {
    position: absolute;
    z-index: 995
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item > .amshopby-choice {
    position: relative;
    top: inherit;
    left: inherit;
    margin: 3px 0;
    padding-left: 22px
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*=am-filter-item-] {
    display: inline-block;
    width: 100%
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*=am-filter-item-]:hover {
    background-color: transparent;
    color: inherit
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*=am-filter-item-]:hover > .count {
    color: #757575
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item .amshopby-choice:hover:before, .amshopby-flyout-block .items.amshopby-fly-out-view .item [class*=am-filter-item-]:hover ~ .amshopby-choice:before {
    border-color: #e3e3e3
}

.am_shopby_apply_filters {
    position: fixed;
    bottom: -80px;
    z-index: 999;
    width: 100%;
    transition: left .2s ease-in-out
}

.am_shopby_apply_filters.visible {
    bottom: 0
}

.am-show-button {
    z-index: 211;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 15px 0;
    width: 100%;
    border-top: 1px solid #ccc;
    background: #fff;
    text-align: center
}

.am-show-button > .am-items {
    margin: 0;
    padding: 0 10px;
    color: #070605;
    font-size: 14px;
    line-height: 22px
}

.am-show-button > .am-items.-loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url('../Amasty_Shopby/images/svg/loader.svg') center/30px no-repeat;
    vertical-align: middle
}

.am-show-button > .amshopby-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    min-width: 90px;
    line-height: 1
}

.ie11 .am-show-button > .amshopby-button {
    padding: 10px
}

#amasty-shopby-product-list {
    z-index: 1
}

#amasty-shopby-product-list .products.wrapper {
    position: relative
}

.amshopby-overlay-block {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 230;
    display: none;
    width: 100%;
    background: rgba(255, 255, 255, .5)
}

.amshopby-overlay-block > .amshopby-loader {
    position: absolute;
    top: 10%;
    left: 50%;
    display: block;
    width: 100px;
    height: 100px;
    background: url('../Amasty_Shopby/images/svg/loader.svg') center/100px no-repeat;
    transform: translate(-50%, -10%)
}

.amshopby-slider-settings {
    list-style: none
}

.amshopby-slider-settings.-style {
    max-width: 214px
}

.amshopby-slider-settings.-style .amshopby-slider-container.-default .amshopby-label {
    padding-bottom: 15px
}

.amshopby-slider-settings.-style .am-slider {
    z-index: 0;
    margin: 0
}

.amshopby-slider-settings.-style .amshopby-label {
    display: flex;
    align-items: center;
    padding-bottom: 45px
}

.amshopby-slider-settings.-style .amshopby-input {
    display: flex;
    align-items: center;
    justify-content: center
}

.amshopby-slider-settings.-style .amshopby-input:checked {
    background: #007bdb
}

.amshopby-slider-settings.-style .amshopby-input:checked:after {
    position: initial;
    margin: 0;
    width: 6px;
    height: 6px;
    background: #fff
}

.amshopby-slider-settings.-style .amshopby-title {
    padding-left: 7px
}

.amshopby-slider-settings.-style .amshopby-slider-tooltip {
    visibility: visible;
    opacity: 1;
    transition: none
}


// hide alt text on missing tooltip image
.amshopby-filter-tooltip img {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: none; // hide placeholder anyway, it's not on live now either
}
