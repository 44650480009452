//chevron fix fitments selector
.search-fitments {
    svg {
        path {
            fill: white;
        }
    }
}

.fitments-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 20px 0;

    @include max-screen($screen__m) {
        flex-wrap: wrap;
        justify-content: center;

        .fitment-logo {
            order: 2;
        }

        .button {
            order: 3;
            width: 50%;
        }

        .title {
            order: 1;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .fitment-logo {
        width: 70px;
        height: 70px;

        background-color: transparent;
        background-position: center center;
        background-size: contain;

        margin: 0 15px 0 35px;

        @include max-screen($screen__m) {
            margin: 0 15px;
        }
    }

    span {
        font-size: 13px;
    }

    .button {
        color: #fff;
        text-decoration: none;
        margin-right: 15px;

        @include max-screen($screen__m) {
            white-space: nowrap;
        }
    }

    .title {
        flex-grow: 1;
        text-transform: uppercase;

        span {
            color: $bs-red;
        }

        h1 {
            color: #000;
            font-size: 26px;
            font-weight: normal;
            margin: 0;

            @include max-screen($screen__l) {
                font-size: 20px;
            }

            @include max-screen($screen__m) {
                font-size: 16px;
            }
        }
    }

    .title-bottom {
        display: flex;
        align-items: center;
        margin-top: 5px;

        .fitment-title {
            margin-left: 15px;

            h1 {
                margin-bottom: 10px;
            }

            p {
                text-transform: none;

                a {
                    color: #000;
                    text-decoration: underline;
                }
            }

            .modal-oem {
                display: none;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, .1);
                z-index: 10000;

                &.active {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .modal-content {
                    position: relative;
                    box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, .25);
                    background: #FFF;
                    width: 40vw;
                    padding: 35px;

                    .close-modal-oem {
                        position: absolute;
                        font-size: 22px;
                        color: white;
                        top: 0;
                        right: 0;
                        background: red;
                        width: 50px;
                        height: 50px;
                        vertical-align: middle;
                        line-height: 50px;
                        text-align: center;
                        cursor: pointer;
                        z-index: 400;
                    }

                    h1 {
                        color: red;
                        margin-bottom: 15px;
                    }

                    p {
                        margin-bottom: 25px;
                    }

                    .models {
                        max-height: 50vh;
                        overflow-y: auto;
                    }

                    .tree {
                        position: relative;
                        max-height: 44px;
                        overflow: hidden;
                        transition: none;

                        .oem-tree-toggle {
                            position: absolute;
                            left: 0;
                            top: 0;
                            line-height: 40px;
                            color: #000;
                            cursor: pointer;
                        }

                        &.opened {
                            max-height: 999px;
                            overflow: visible;
                            transition: all 1.34s ease-in-out;
                        }
                    }

                    .oem-system {
                        background: $bs-light-gray;
                        color: $bs-dark-gray;
                        padding: 10px 15px;
                        border-radius: 3px;
                        display: inline-block;
                        margin-bottom: 5px;
                        margin-right: 5px;

                        &:hover {
                            text-decoration: none;
                            background: $bs-dark-gray;
                            color: #fff;
                            cursor: pointer;
                        }
                    }

                    @include max-screen($screen__m) {
                        width: 95%;
                        box-sizing: border-box;

                        .oem-system {
                            display: block;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}

.fitments-fitments-index {
    .fitments-tree {
        h1 {
            color: red;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 25px;
        }

        .tree {
            padding-left: 20px;
            position: relative;
            max-height: 44px;
            overflow: hidden;
            transition: none;

            .oem-tree-toggle {
                position: absolute;
                left: 0;
                top: 0;
                line-height: 40px;
                color: #000;
                cursor: pointer;
            }

            &.opened {
                max-height: 999px;
                overflow: visible;
                transition: all 1.34s ease-in-out;
            }
        }

        .oem-system {
            background: $bs-light-gray;
            color: $bs-dark-gray;
            padding: 10px 15px;
            border-radius: 3px;
            display: inline-block;
            margin-bottom: 5px;
            margin-right: 5px;

            &:hover {
                text-decoration: none;
                background: $bs-dark-gray;
                color: #fff;
                cursor: pointer;
            }
        }
    }

    .fitment-cat {
        background-color: #DCDBDC;
        margin-bottom: 50px;
        // max-height: 17.5rem;
        // overflow: hidden;

        @include max-screen($screen__s) {
            max-height: inherit;
        }

        .fitment-cat-header {
            width: 100%;
            //height: 17.5rem;
            display: flex;
            position: relative;
            overflow: visible;

            @include max-screen($screen__s) {
                flex-direction: column;
                height: auto;
            }

            .fitment-cat-image {
                width: 40%;
                height: inherit;
                background-size: cover;
                max-width: 825px;

                @include max-screen($screen__s) {
                    width: 100%;
                    height: 100px;
                }
            }

            .fitment-cat-content {
                padding: 20px;
                max-width: 54rem;

                @include max-screen($screen__s) {
                    min-height: 194px;
                }

                .fitment-cat-name {
                    font-weight: 600;
                    color: #000;
                    font-size: 36px;
                    margin: 0 0 10px;
                }

                .fitment-cat-desc {
                    padding-bottom: 40px;

                    h1, h2, h3, h4, h5, h6 {
                        font-size: 18px;
                        font-weight: normal;
                        margin: 0 0 20px;
                    }
                }

                .fitment-cat-toggle {
                    display: block;
                    cursor: pointer;
                    color: #fff;
                    background-color: #FD0021;
                    height: 50px;
                    line-height: 50px;
                    position: absolute;
                    padding: 0 60px 0 10px;
                    right: 0;
                    bottom: 0;

                    span.hide-products {
                        display: none;
                    }

                    span.icon {
                        position: absolute;
                        background-color: #0a0a0a;
                        display: inline-block;
                        height: 50px;
                        width: 50px;
                        text-align: center;
                        vertical-align: middle;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }

        .fitment-categories {

            .button.details-buy {
                border-radius: 3px;
                color: #fff;
                background: #1AB67C;
                line-height: 18px;
                font-size: 12px;
            }

        }

        &.opened {

            .fitment-categories {

            }

            .fitment-cat-header {
                span.hide-products {
                    display: inherit !important;
                }

                span.show-products {
                    display: none;
                }


            }

            .fitment-cat-toggle {
                span.icon {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .fitment-categories {

        .fitment-category {

            .icon {
                max-width: 25px;
                max-height: 25px;
                fill: #fff;


            }

            > div {
                position: relative;
                cursor: pointer;

                .filters {
                    position: absolute;
                    background-color: #000;
                    padding: 20px;
                    z-index: 5;
                    min-width: 200px;
                    left: -20px;
                    display: none;

                    .filter {
                        input[type=checkbox] {
                            margin-right: 10px;
                        }

                        label {
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &:hover .filters, .filters:hover {
                    display: block;
                }
            }

            span {
                &:not(:first-child) {
                    padding-left: 10px;
                    font-size: 12px;
                    height: 100%;
                }

                i {
                    margin-left: 5px;
                }
            }
        }

        .fitment-product {
            .fitment-row {

                &:hover {
                    background: #ececec;
                }

                &.open {
                    .expander {
                        .icon-chevron-down {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }

            .fitment-details {
                display: flex;

                @include max-screen($screen__s) {
                    flex-direction: column;
                }

                .product-images {
                    width: 50%;
                    margin-right: 30px;
                    margin-top: 25px;

                    @include max-screen($screen__s) {
                        width: 100%;
                        margin-top: 5px;
                        margin-right: 0;
                    }
                }

                .right-details {
                    width: 50%;
                    margin-top: 25px;

                    @include max-screen($screen__s) {
                        width: 100%;
                        margin-top: 0;
                        margin-right: 0;
                    }

                    a.button.configure-now {
                        border-radius: 3px;
                        color: #fff;
                        background: #1AB67C;

                        @include max-screen($screen__s) {
                            display: block;
                        }
                    }
                }
            }

            &.hidden {
                display: none;
            }
        }

        // reset base.scss values
        span.stock {
            font-weight: inherit;
            font-size: inherit;
            font-style: inherit;
            text-transform: inherit;
        }

        .fitment-category,
        .fitment-product {
            span {

                &.stock {
                    * {
                        margin: 0;
                        padding: 0;
                    }

                    @include lib-tooltip(bottom, ".tooltip-toggle", ".tooltip-content", #000, #000, 0, 2px, #fff, help, 12px, inherit, inherit, 1.4, inherit, 0 0 0 20px, 1rem 2rem, 20rem, 40rem, 8px, inherit, 99);
                    margin: 0;

                    &:before {
                        display: none;
                    }

                    .tooltip-content {
                        transform: translateX(-50%);
                        text-align: center;

                        &:before,
                        &:after {
                            left: 50%;
                        }
                    }

                    .stock-bullet {
                        width: 2.2rem;
                        height: 2.2rem;
                        max-width: 2.2rem;
                        border-radius: 9999rem;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0;

                        &.in-stock {
                            background: #1AB67C;
                        }

                        &.out-of-stock {
                            background: $bs-light-gray;
                        }

                        &.partial-stock {
                            background: $bs-color-partial-stock;
                        }

                        .stock-indicator {
                            font-weight: 700;
                            color: #fff;
                            display: flex !important;
                            padding-right: 0;
                            justify-content: center;
                            margin-left: 0;
                        }
                    }
                }

                &.price {
                    .price-label {
                        display: none !important;
                    }

                    .dealer-pricing {
                        span {
                            width: auto;
                            min-width: 0;
                        }

                        > .price {
                            display: flex;

                            .price-wrapper {
                                padding: 0 10px 0 0;

                                .price {
                                    font-size: 1.6rem;
                                    font-weight: bold;
                                }
                            }

                            .excl-tax {
                                padding: 0;
                                margin: 0;
                                font-size: 12px;
                                font-weight: 400;
                                display: none;
                            }
                        }

                        .retail-price {
                            display: flex;
                            flex-flow: row wrap;
                            align-items: baseline;
                            margin: 0;
                            padding: 0;

                            span:not(.price) {
                                font-weight: 400;
                                margin-right: .5rem;
                            }
                            b {
                                margin-right: .5rem;
                                font-size: 1.3rem;
                                font-weight: normal;
                            }

                            span.price {
                                font-size: 1rem;
                            }
                        }
                    }
                }


                &.expander {
                    content: '';
                    min-width: 110px;

                    .icon-chevron-down {
                        width: 18px;
                        height: 18px;
                        overflow: hidden;
                        transition: 0.5s all;
                    }
                }
            }
        }
    }

    .block {
        &.filter {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            width: 240px;
            background: #fff;
            overflow-x: hidden;
            padding: 25px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            z-index: 11000;
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;

            transition-property: background;
            transition-duration: 0.5s;

            .filterwrapper {
                width: 250px;
                background: #fff;
                padding: 40px;
                flex-basis: 100%;
                z-index: 12000;
                position: relative;
                left: -250px;

                transition-property: left;
                transition-duration: 0.5s;

                .filterclose {
                    position: absolute;
                    right: 30px;
                    top: 30px;
                    width: 16px;
                    height: 16px;
                    overflow: hidden;
                    cursor: pointer;
                }
            }

            &.open {
                left: 0;

                .filterwrapper {
                    left: 0;
                }
            }
        }
    }

    .fitment-modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        display: none;
        z-index: 99999999999;
        background-color: rgba(0, 0, 0, .5);


        .fitment-modal-block {
            width: 75%;
            padding: 0px;
            max-width: 1000px;
            height: 80vh;
            left: 0;
            top: 0;
            background-color: #fff;
            overflow: hidden;
            box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.75);
            position: relative;

            .catalog-product-view {
                height: 100%;

                .fitment-modal-top {
                    height: 100%;

                    .loader {
                        margin: 0 auto;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .fitment-modal-top-wrap {
                display: flex;

                .product-images {
                    width: 50%;
                }

            }

            .close-modal-fitments {
                position: absolute;
                font-size: 22px;
                color: white;
                top: 0;
                right: 0;
                background: red;
                width: 50px;
                height: 50px;
                vertical-align: middle;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                z-index: 400;
            }
        }


        &.visible {
            display: flex;
        }
    }
}

// TODO RECOMBINE THIS WITH ABOVE

// TABLE LAYOUT
.fitment-categories {
    // reset all
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    * {
        list-style: none;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        * {
            list-style: none;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
    }

    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-gap: 0; // using display contents
    background: white;
    border: 4rem solid $bs-light-gray; // margin fix

    // Reset table rows
    .fitment-category, .fitment-product, .fitment-row {
        display: contents;
    }

    // MOBILE / TABLET
    .fitment-row {
        > span:not(.has-filters):not(.product_image):not(.name):not(.manufacturer):not(.stock):not(.price):not(.details_buy) {
            @media (max-width: $screen__m_max) {
                display: none;
            }
        }
    }

    .fitment-category {
        > div:not(.has-filters) {
            display: none;
        }

        @media (min-width: $screen__s_min) {
            > div:not(.has-filters) {
                display: block;
            }
            > div:not(.product_image):not(.name):not(.manufacturer):not(.stock):not(.price):not(.expander):not(.details_buy) {
                display: none;
            }
        }
        @media (min-width: $screen__l_min) {
            > div {
                display: block !important;
            }
        }
    }


    // 'th'-'td' COMBINED styling
    .fitment-category > div, .fitment-row > span {
        text-align: start;
        height: 100%;
        padding: 1rem;

        // force new table row(s)
        &:first-of-type {
            grid-column-start: 1;
        }
    }


    // Individual styling

    // TABLE HEADER
    .fitment-category > div {
        background: black;
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;

        &:before {
            display: none;
        }
    }

    // TABLE (BODY) ROWS
    .fitment-row {
        font-size: 13px;

        > span {
            border-bottom: 1px solid #e4e4e4;
            display: flex;
            align-items: center;
            justify-content: start;
        }


        // MODAL ON IMAGE HOVER
        span {
            position: relative;
        }


        // per column / attribute
        .product_image {
            .modal {
                display: none;
                position: absolute;
                background-color: #fff;
                box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.75);
                padding: 10px;
                left: 100%;
                top: -20rem;
                z-index: 10;
                max-width: 500px;
                box-sizing: content-box;

                > img {
                    min-width: 500px;
                    min-height: 500px;
                }
            }

            &:hover {
                .modal {
                    display: block;
                }
            }
        }

        .price {
            .price-label {
                display: none;
            }

            .price-box {
            }

            span.excl-tax, .retail-price {
                font-size: 1rem;
            }
        }

        .button.details-buy {
            width: 10rem;
            max-width: 10rem;
            padding: 1.1rem;
            text-align: center;
            //grid-area: -1;
        }
    }


    // MODAL
    .fitment-modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        display: none;
        z-index: 99999999999;
        background-color: rgba(0, 0, 0, .5);

        .fitment-modal-block {
            box-sizing: content-box;
            width: 75%;
            padding: 0px;
            max-width: 1000px;
            height: 80vh;
            left: 0;
            top: 0;
            background-color: #fff;
            overflow: hidden;
            box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.75);
            position: relative;

            .catalog-product-view {
                height: 100%;

                .fitment-modal-top {
                    height: 100%;

                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .fitment-modal-top-wrap {
                display: flex;

                .product-images {
                    width: 50%;
                }

            }

            .close-modal-fitments {
                position: absolute;
                font-size: 22px;
                color: white;
                top: 0;
                right: 0;
                background: red;
                width: 50px;
                height: 50px;
                vertical-align: middle;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                z-index: 400;
            }
        }


        &.visible {
            display: flex;
        }
    }
}

// FITMENT-ROW-MOBILE styling
.fitment-categories {
    @media(max-width: $screen__s_max) {
        display: block;
        border-width: 1rem;
    }

    .fitment-category {
        @media(max-width: $screen__s_max) {
            display: grid;
            width: 100%;
            grid-auto-flow: column;
        }
    }

    .fitment-product {
        @media(max-width: $screen__s_max) {
            display: flex;
        }
    }

    .fitment-row:not(.fitment-row-mobile) {
        @media(max-width: $screen__s_max) {
            display: none;
        }
    }

    .fitment-row-mobile {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        border-bottom: 1px solid #ececec;

        @media(min-width: $screen__m_min) {
            display: none;
        }

        .image {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .details {
            display: grid;
            padding: 1rem 2rem;

            > span {
                display: block;
                font-weight: bold;
            }

            .details-price {
                display: grid;
                grid-template-columns: 70% 30%;
            }

            a.details-buy {
                width: unset;
                max-width: unset;
                margin-top: 1rem;
            }

            > .price {
                font-size: 16px;
                font-weight: bold;
            }

        }
    }
}
