.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inset-0 {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}