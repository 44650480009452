@mixin amqorder-flex(
    $horiz-pos: none,
    $vert-pos: none,
    $wrap: none,
) {
    display: flex;


    @if ($horiz-pos = none) {
        justify-content: $horiz-pos;
    }

    @if ($vert-pos != none) {
        align-items: $vert-pos;
    }

    @if ($wrap != none) {
        flex-wrap: $wrap;
    }
}

@mixin amqorder-transition($del: .2) {
    transition: '#{$del}s all ease-in';
}

@mixin amqorder-icon($icon-url: none, $width: 20px, $height: 20px) {
    @include amqorder-flex(center, center);
    @include amqorder-transition();

    display: inline-flex;
    width: $width;
    height: $height;
    background-image: $icon-url;
    background-position: center center;
    background-size: 95% 95%;
    background-repeat: no-repeat;
    content: '';
}

@mixin amqorder-scrollbar(
    $color: #adadad,
    $second-color: #f0f0f0,
    $direction: y,
    $width: 4px,
    $shadow-color: #fff
) {

    overflow: hidden;
    overflow-#{$direction}: auto;
    scrollbar-color: $color rgba(255, 255, 255, .2);
    scrollbar-width: thin;


    &::-webkit-scrollbar {
        background: $second-color;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $width;
        background: $color;
        cursor: pointer;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $shadow-color;
    }

    @if ($direction != x) {
        &::-webkit-scrollbar {
            width: $width;
        }

        &::-webkit-scrollbar-thumb {
            width: $width;
        }

        ::-webkit-scrollbar-track {
            width: $width;
        }
    }

    @if ($direction != y) {
        &::-webkit-scrollbar {
            height: $width;
        }

        &::-webkit-scrollbar-thumb {
            height: $width;
        }

        ::-webkit-scrollbar-track {
            height: $width;
        }
    }
}

.amqorder-disabled {
        opacity: .8;
        pointer-events: none;

    > * {
        pointer-events: none;
    }
}
