.wholesale_appautomotive_website {
    .page-header {
        background-color: #fff;
    }

    .header {
        .minicart-wrapper {
            display: flex;
            align-items: center;

            @media screen and (min-width: $screen__m_min) {
                grid-area: minicart;
            }

            .action.showcart {
                display: flex;
                align-items: center;
                gap: 1rem;

                &:before {
                    display: none;
                }

                .cart-icon {
                    position: relative;
                }

                svg {
                    display: block;
                    width: 3.2rem;
                    height: 3.2rem;

                    @media screen and (min-width: $screen__m_min) {
                        width: 2.4rem;
                        height: 2.4rem;
                    }

                    path {
                        fill: #000;
                    }
                }

                .counter.qty {
                    padding: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: $bs-red;
                    border-radius: 100%;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1;
                    width: 1.8rem;
                    height: 1.8rem;
                    box-sizing: border-box;
                    transform: translate(50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .cart-info {
                    display: none;

                    @media screen and (min-width: $screen__m_min) {
                        display: block;
                        padding-top: .4rem;
                        font-weight: 400;
                    }
                }
            }
        }

        &.content {
            @media screen and (min-width: $screen__m_min) {
                grid-template-areas: 'logo search myaccount minicart';
                gap: 0 2rem;
                grid-template-columns: 22rem 1fr auto auto;
                padding: 0 2rem;
                max-width: 1280px;
                margin: 0 auto;
            }

            @media screen and (min-width: $screen__l_min) {
                gap: 0 7rem;
            }
        }

        .block-minicart {
            @media screen and (min-width: $screen__m_min) {
                margin-top: 1.5rem;

                &:before {
                    right: 40px;
                }

                &:after {
                    right: 39px;
                }
            }
        }

        .app-dropdown {
            .action.toggle {
                color: #000;

                .icon-user {
                    fill: currentColor;
                }

                .text {
                    color: inherit;
                    padding-top: .4rem;
                    margin: 0;
                }

                .user-text {
                    min-width: 6rem;
                }
            }
        }
    }

    .nav-toggle {
        .nav-toggle-bar {
            background-color: #000;
        }
    }

    .header.content {
        .block-search {
            .search-toggle {
                color: #000;

                .search-icon {
                    fill: currentColor;
                }
            }

            @media screen and (min-width: $screen__m_min) {
                display: flex;
                justify-content: flex-end;
                padding: 3rem 0;

                .block-content {
                    width: 100%;
                    max-width: 350px;
                }

                input[type="text"] {
                    border: 0;
                    border-bottom: 1px solid currentColor;
                    padding-left: 0;

                    &::placeholder {
                        color: #000;
                    }
                }

                .action.search {
                    right: 0;
                    transform: none !important;

                    &:before {
                        color: #000;
                    }
                }
            }
        }
    }

    .nav-sections {
        @media screen and (min-width: $screen__m_min) {
            border-top: 0;
            background-color: $bs-xlt-gray;
        }

        .nav-open & {
            @media screen and (max-width: $screen__s_max) {
                top: 5.9rem;
            }
        }
    }

    .navigation {
        @media screen and (min-width: $screen__m_min) {
            background-color: transparent;
        }

        .level0 {
            @media screen and (min-width: $screen__m_min) {
                margin-right: 1.5rem;
            }

            > .level-top {
                @media screen and (min-width: $screen__m_min) {
                    text-transform: none;
                }
            }
        }

        > ul {
            @media screen and (min-width: $screen__m_min) {
                padding: 0;
            }
        }

        .parent {
            .level-top {
                &:after {
                    @media screen and (min-width: $screen__m_min) {
                        display: none;
                    }
                }
            }
        }
    }
}
