
.action.primary.toggle-layered-navigation {
    display: none;
    background-color: $bs-red;
    border: 0px solid $bs-red;
    color: #FFF;
    text-transform: uppercase;
    padding: 10px 20px;
    height: 38px;
    border-radius: 2px;
    font-weight: normal;
    line-height: 15px;
    font-size: 15px;
    width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: 0;

    @include max-screen($screen__m) {
        display: block;
        margin-bottom: 15px;
        margin-right: auto;
        width: 100%;
    }

    &:hover {
        background: #e2e2e2;
        border: 1px solid #cdcdcd;
        color: #555;
    }
}

.block.filter {
    @include max-screen($screen__m) {
        display: none;
    }
}

.sidebar-left {
    .category-description-title, .category-description {
        @include max-screen($screen__m) {
            display: none;
        }
    }
}
