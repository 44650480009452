.customer-account-login, .customer-account-create, .account, .checkout-index-index
{
    .page-title-wrapper {
        padding-top: 25px;
    }

    .actions-toolbar {
        .action {
            &.primary {
                background-color: $bs-red;
                border: 0px solid $bs-red;
                color: #FFF;
                text-transform: uppercase;
                padding: 10px 20px;
                height: 38px;
                border-radius: 2px;
                font-weight: normal;
                line-height: 15px;
                font-size: 15px;

                &:hover {
                    background: #e2e2e2;
                    border: 1px solid #cdcdcd;
                    color: #555;
                }
            }
        }

        .secondary {
            .action {
                margin-top: 0;
                background-color: #e2e2e2;
                border: 1px solid #e2e2e2;
                color: #555;
                text-transform: uppercase;
                padding: 10px 20px;
                border-radius: 2px;
                font-weight: normal;
                line-height: 15px;
                font-size: 15px;

                &:hover {
                    background: #e2e2e2;
                    border: 1px solid #cdcdcd;
                    color: #555;
                }

                @include max-screen($screen__s) {
                    width: 100%;
                    display: inline-block;
                    box-sizing: border-box;
                    margin-top: inherit;
                }
            }
        }
    }
}

.account.sales-order-view {
    .page-main {
        .page-title-wrapper {
            .order-status {
                font-size: 2.8rem;
                font-weight: 600;
            }
        }
    }
    .block.block-order-details-comments {
        .block-content {
            .order-comments {
                .comment-date {
                    font-weight: 400;
                }
                .comment-content {
                    background: $color_black;
                    color: $color-white;
                    font-weight: 700;
                    padding: 0.5rem;
                }
            }
        }
    }
}
