@mixin btn-primary(
  $size: 'lg',
  $color: 'red',
){
  @if($size == 'lg') {
    padding: 1.2rem 2.4rem;
  } @else if($size == 'wide') {
    padding: 1.2rem 2.4rem;
    width: 100%;
  } @else {
    padding: .8rem 1.6rem;
  }

  @if($color == 'red'){
    background-color: $bs-red;
    color: white;
  } @else {
    background-color: $bs-green;
    color: white;
  }


  border-radius: .3rem;
  border: none;
  transition: background-color, box-shadow 300ms;

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    box-shadow: .1rem .1rem .2rem $bs-mlt-gray;

    @if($color == 'red'){
      background-color: darken($bs-red, 5);
      color: white;
    } @else {
      background-color: darken($bs-green, 5);
      color: white;
    }
  }

}

@mixin btn-primary-lg(){
  @include btn-primary($size: 'lg', $color: 'red');
}

@mixin btn-primary-wide(){
  @include btn-primary($size: 'wide', $color: 'red');
}

//@mixin btn-primary(){
//  @include btn-primary('lg');
//}

.btn-primary-lg {
  @include btn-primary-lg();
}
