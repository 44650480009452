.account.sales-order-view {
    .page-main {
        .page-title-wrapper {
            .order-status {
                font-size: 2.8rem;
                font-weight: 600;
            }
        }
    }
    .block.block-order-details-comments {
        .block-content {
            .order-comments {
                .comment-date {
                    font-weight: 400;
                }
                .comment-content {
                    background: $color_black;
                    color: $color-white;
                    font-weight: 700;
                    padding: 0.5rem;
                }
            }
        }
    }
}
