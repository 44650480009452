//
//  Product Grid Component
//  _____________________________________

//
//  Imports
//  -------------------------------------

@import '../_mixins';

//
//  Common
//  -------------------------------------

.amqorder-wrapper {
    @include amqorder-flex(space-between, flex-start, wrap);

    position: relative;
    z-index: 0;
    padding: 0 $amqorder__indent__m $amqorder__indent__l * 2;
}

.product-options-wrapper {
    flex-basis: 100%;
}

.amqorder-grid-block {

    @include amqorder-transition();

    flex-basis: 100%;
    order: 2;


    .amqorder-content {
        margin: 0 0 35px;
        background: #fff;
    }

    .amqorder-msg.-error {
        position: inherit;
        margin: 0 0 0 $amqorder__indent;
    }

    .amqorder-message {
        @include amqorder-flex(center, center);

        padding: $amqorder__indent__xl * 2 $amqorder__indent;
    }

    .amqorder-price {
        flex-basis: 50%;
        font-weight: 700;
    }

    // Magento blocks override
    .price-box .price-as-configured {
        margin: 0;
    }

    // END Magento blocks override

    .amqorder-sku {

        @include amqorder-flex();

        box-sizing: border-box;
        margin: 0 0 $amqorder__indent / 2;


        &:before {
            margin: 0 $amqorder__indent / 2 0 0;
            content: attr(data-label) ':';
            font-weight: 700;
        }
    }

    .amqorder-sku .amqorder-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .amqorder-messages-list {
        padding: 0 0 $amqorder__indent / 2;
    }

    .amqorder-header {
        @include amqorder-flex(none, center);

        //display: inline-flex;
        box-sizing: border-box;
        list-style: none;
        padding: 1rem;
        border-bottom: 1px solid $bs-light-gray;
        margin: 2rem 0 0 0;
    }

    .amqorder-header .amqorder-item {
        @media(max-width: $screen__m_max) {
            display: none;
        }

        flex-basis: 220px;
        flex-grow: 1;

        &.-rating {
            display: none;
        }
    }

    .amqorder-header .amqorder-item.-selectall {
        float: right;
        position: absolute;
        top: 0;
        right: 0;

        height: 4rem;
        border: 1px solid $bs-green;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        background: $bs-green;
        font-weight: bold;
        color: white;

        &:hover {
            background: white;
            color: $bs-green;
            border-color: $bs-green;
        }

        @media (min-width: $screen__l_min) {
            float: left;
            position: absolute;
            top: 0;
            right: unset;
            left: 1rem;
        }
    }

    .amqorder-items-wrapper .amqorder-button.-action {
        display: none;
    }

    .amqorder-button.-options {
        flex-basis: 100%;
        justify-content: flex-start;
        padding: 0 0 $amqorder__indent__m * 2;
        white-space: nowrap;
    }
}

.amqorder-grid-block .amqorder-items-block {
    margin: 0;
    padding: 0 15px;
    list-style: none;


    > .amqorder-item {
        position: relative;
        margin: 0;
        padding: $amqorder__indent__m 0;
        width: auto;


        &:not(:last-child) {
            border-bottom: 1px solid #ebeef5;
        }
    }

    > .amqorder-item .amqorder-item:not(.amqorder-image) {
        flex-basis: 150px;
        flex-grow: 1;

        &.-checkbox {
            flex-basis: unset;

            @media(max-width: $screen__m_max) {
                position: absolute;
                right: 0;
                width: fit-content;
                bottom: 40px;
            }

            &:before {
                @media(max-width: $screen__m_max) {
                    content: attr(title);
                    position: absolute;
                    bottom: -25px;
                    right: 0;
                    width: max-content;
                    font-size: 1.1rem;
                }
            }
        }

        &.amqorder-stockindicator {
            @media(max-width: $screen__m_max) {
                display: inline;
            }
        }

        &.amqorder-price .old-price .price-label {
            @media(max-width: $screen__m_max) {
                display: none;
            }
        }

    }

    > .amqorder-item .amqorder-item.-name {
        align-self: stretch;
    }

    > .amqorder-item.-smart .amqorder-item.-name {
        align-content: space-between;
    }

    > .amqorder-item.-errors {
        border: 1px solid $amqorder__color__error;
    }

    > .amqorder-item:not(.-smart) .amqorder-item.-name {
        align-content: center;
    }

    .amqorder-item.-name .amqorder-title {

        color: #000;


        &.-disabled {
            pointer-events: none;
        }
    }

    > .amqorder-item.-weee {
        padding: $amqorder__indent__m;
    }

    .amqorder-image {
        position: inherit;
        z-index: 1;
        display: inline-block;
        flex-grow: inherit;
        float: left;
        margin: 0 $amqorder__indent__m 0 0;
        min-width: 90px;
        width: 90px;
        height: 90px;
        background: #fff;
        object-fit: contain;
    }
}

.amqorder-grid-block .amqorder-items-wrapper {

    align-items: flex-start;
    flex-basis: 100%;


    .amqorder-qty-block {
        flex-basis: 100%;
        float: none;
        padding: $amqorder__indent__m * 2 0 $amqorder__indent__l;

        @media(min-width: $screen__l_min) {
            clear: both;
        }


        &:before {
            margin: 0 $amqorder__indent__m 0 0;
            content: attr(data-label);
            font-weight: 700;
        }
    }

    .amqorder-price,
    .amqorder-button.-options {
        display: none;
    }
}

.amqorder-grid-block .amqorder-toolbar-block {

    @include amqorder-flex(space-between, center, wrap);

    padding: $amqorder__indent__xl $amqorder__indent__m $amqorder__indent__m;
    border-top: 1px solid #ebeef5;


    .amqorder-button.-clear-list {
        justify-content: flex-end;
    }

    .amqorder-button.-download {
        margin-right: $amqorder__indent;
    }

    .amqorder-pager-block {
        flex-basis: 100%;
        justify-content: space-between;
        order: 1;
        margin: 0;
        padding: $amqorder__indent__xl 0 0 0;
    }
}

.amqorder-item-info > .amqorder-button.-action {
    margin: 0 0 0 auto;
}

.amqorder-selected-toolbar {

    @include amqorder-flex(none, center);


    .amqorder-button.-remove {
        margin: 0 0 0 $amqorder__indent;
        color: $bs-red;
    }
}

.amqorder-massaction-toolbar {
    @include amqorder-flex(none, center);

    display: inline-flex;
    width: 100%;
}

.amqorder-massaction-toolbar:first-child {

    .amqorder-buttons-block {
        display: none;
    }
}

.amqorder-massaction-toolbar:last-child {

    flex-wrap: wrap;
    justify-content: flex-start;


    .amqorder-selected-toolbar {
        // margin: 0 0 $amqorder__indent__m;
    }

    .amqorder-buttons-block {
        margin: 0;
        width: 100%;
    }

    .amqorder-button.-cart {
        margin: 0;
    }
}

.amqorder-buttons-block {

    @include amqorder-flex(flex-end, none, wrap);


    &.-qty-1 .amqorder-button {
        @extend .amqorder-button.-fill.-primary:not(.-disabled all) !optional;
        @extend .amqorder-button.-fill;
    }

    &.-qty-3 .amqorder-button.-checkout {
        flex-basis: 100%;
        margin-top: $amqorder__indent__m;
    }

    .amqorder-button {
        flex-grow: 1;
        margin: 0 $amqorder__indent;
        white-space: nowrap;
    }
}

.amqorder-options-block {
    @include amqorder-flex(none, none, wrap);

    flex-basis: 100%;
    margin: 0;
    list-style: none;
}


//
//  Mobile
//  --------------------------------------------
@media(min-width: $screen__s_min) {
    .amqorder-buttons-block.-qty-2,
    .amqorder-buttons-block.-qty-3 {
        .amqorder-button {
            flex-grow: inherit;
        }

        .amqorder-button.-checkout {
            margin-top: 0;
        }
    }

    .amqorder-grid-block .amqorder-toolbar-block {

        padding: $amqorder__indent__xl $amqorder__indent__m;


        &.-disabled .amqorder-button {
            pointer-events: none;
        }

        &.-pagination .amqorder-pager-block {
            order: -1;
            margin: 0 auto 0 0;
        }

        &.-pagination .amqorder-button:not(.-arrow) {
            margin: 0 0 0 $amqorder__indent__l;
        }

        &:not(.-pagination) .amqorder-button.-clear-list {
            flex-basis: auto;
            margin: 0 0 0 auto;
        }

        .amqorder-pager-block {
            flex-basis: auto;
            order: inherit;
            margin: 0 $amqorder__indent 0 0;
            padding: 0;
        }
    }
}


//
//  Tablet
//  --------------------------------------------


@media(min-width: $screen__l_min) {
    .amqorder-grid-block {
        .amqorder-items-wrapper .amqorder-price {
            @include amqorder-flex();
        }

        .amqorder-header {

            .amqorder-item {
                box-sizing: border-box;
                margin: 0;

                &.-subtotal {
                    display: none;
                }
            }

            .amqorder-item:not(:last-child) {
                margin: 0;
            }

            .amqorder-item.-item {
                flex-grow: 2;
                text-align: left;
                max-width: 265px;
            }

            .amqorder-item.-action {
                text-align: right;
            }

            .amqorder-item.-sku {
                max-width: 130px;
            }
        }

        .amqorder-options-block {
            margin: 0;
            padding: 0;
        }

        .amqorder-msg.-error {
            position: absolute;
            margin: 0;
        }

        .amqorder-sku {

            margin: 0;
            max-width: 130px;


            &:before {
                display: none;
            }
        }

        .amqorder-button.-action {
            justify-content: flex-end;
            min-width: 70px;
        }

        .amqorder-messages-list {
            padding: $amqorder__indent 0 $amqorder__indent / 2;
        }

        .amqorder-button.-options {
            padding: 0;
        }
    }

    .amqorder-grid-block .amqorder-items-wrapper {

        @include amqorder-flex(space-between, center, wrap);


        .amqorder-item {

            @include amqorder-flex(none, center);

            box-sizing: border-box;
            padding: 0;


            &:not(:last-child) {
                margin: 0 $amqorder__indent__m 0 0;
            }

            &.-qty {

                flex-basis: auto;


                &:before {
                    display: none;
                }
            }

            &.-checkbox {
                @include amqorder-flex(flex-end, center);
            }

            &.-name {
                @include amqorder-flex(none, none, wrap);

                padding: 0 $amqorder__indent__m 0 $amqorder__indent;
            }
        }

        .amqorder-button.-action,
        .amqorder-button.-options {
            @include amqorder-flex();
        }
    }

    .amqorder-grid-block .amqorder-items-block {
        .amqorder-items-wrapper {
            flex-wrap: nowrap;
        }

        > .amqorder-item,
        > .amqorder-item.-weee {
            padding: $amqorder__indent__m 0;
        }

        .amqorder-image {
            float: none;
            margin: 0;
            max-width: inherit;
            width: auto;
        }

        .amqorder-price {
            flex-basis: auto;
            font-weight: 400;
        }

        // Magento options block override
        .swatch-attribute,
        .field {
            width: auto;
        }

        .product-options-wrapper {
            padding: $amqorder__indent__xl 0 0;
        }

        // END Magento options block override
    }

    .amqorder-item-info {
        > .amqorder-price,
        > .amqorder-button.-action,
        > .amqorder-button.-options {
            display: none;
        }
    }

    .amqorder-massaction-toolbar .amqorder-selected-toolbar {
        margin: 0 $amqorder__indent__l 0 0;
        height: 4rem;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $bs-light-gray;

    }

    .amqorder-massaction-toolbar:first-child {

        justify-content: flex-end;
        width: 100%;


        .amqorder-buttons-block {
            display: flex;
        }
    }

    .amqorder-massaction-toolbar:last-child {
        justify-content: flex-end;
        column-gap: 2rem;
        justify-items: center;
        margin-bottom: 2rem;


        .amqorder-buttons-block {
            width: auto;
        }
    }
}



