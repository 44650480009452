.checkout-index-index {
    .page-wrapper {
        padding-top: 0;

        .header-wrapper {
            display: none;
        }

        @include max-screen($screen__s) {
            padding-top: 5px;
        }

        .page-main {
            max-width: 1280px;
        }
    }

    .header.content {
        background: transparent;
        display: flex;
        justify-content: center;

        .logo {
            margin: 50px 0;
        }
    }

    .field-tooltip {
        .label {
            display: none;
        }
    }
}

.checkout-logo {
    display: flex;
    justify-content: center;
    max-width: 375px;
    margin: 0 auto 50px;
}

.checkout-container {
    padding: 15px;

    @include min-screen($screen__xs) {
        padding: 30px;
    }

    .fieldset > .field {
        margin-bottom: 15px;
    }

    position: relative;
    margin: 50px 0 0 0;

    .authentication-wrapper {
        position: absolute;
        top: -68px;
        right: 0;

        .action-auth-toggle {
            span {
                color: #fff;
                font-size: 18px;
            }
        }

        .block {
            &.block-customer-login {
                .block-title {
                    strong {
                        text-transform: uppercase;
                        font-weight: normal;
                        font-size: 24px;
                    }
                }

                .actions-toolbar {
                    margin-left: 0;

                    .primary {
                        .action-login {
                            background: $bs-red;
                            border: none;
                            color: #fff;
                            border-radius: 3px;
                            font-size: 14px;
                            font-weight: normal;
                            padding: 7px 15px;
                        }
                    }

                    .secondary {
                        margin-left: 0;
                        margin-right: auto;

                        .action-remind {
                            color: #000;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .opc-progress-bar {
        display: flex;
        justify-content: center;
        width: 100%;

        .opc-progress-bar-item {
            &._active {
                &:before {
                    background: $bs-dark-gray;
                }

                span {
                    &:before {
                        background: $bs-dark-gray;
                    }
                }
            }

            &._complete {
                &:before {
                    background: #1AB67C;
                }

                span {
                    &:before {
                        background: #1AB67C;
                    }

                    &:after {
                        color: #1AB67C;
                    }
                }
            }

            span {
                font-size: 16px;
                font-weight: normal;
                text-transform: uppercase;
            }
        }
    }

    .opc-wrapper {
        width: 65%;
        padding: 0 20px;

        @media screen and (max-width: 767px) {
            width: 100%;
        }

        .step-title {
            font-family: Roboto, sans-serif;
            font-size: 26px;
            text-transform: uppercase;
            font-weight: normal;
        }

        .checkout-billing-address {
            .actions-toolbar {
                .primary {
                    display: flex;
                    align-items: stretch;
                    flex-direction: row-reverse;

                    .action {
                        color: #fff;
                        background: $bs-dark-gray;
                        padding: 7px 15px;

                        &.action-cancel {
                            margin: 0;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .action.primary.checkout {
            color: #fff;
            background: #1AB67C;
            border: 2px solid #1AB67C;

            &:hover {
                color: #1AB67C;
                background: #fff;
            }
        }

        .form-discount {
            .actions-toolbar {
                .action-apply {
                    color: #fff;
                    background: $bs-dark-gray;
                    padding: 7px 15px;
                }
            }
        }
    }

    .opc-sidebar {

        .title,
        .shipping-information-title span {
            font-family: Roboto, sans-serif;
            font-size: 22px;
            text-transform: uppercase;
            font-weight: normal;
        }
    }
}

.checkout-index-index {

    .amasty-extrafee-opc-block-fees {
        .amasty-extrafee-option {
            display: flex;
            flex-direction: column;
        }

        .field {
            .label {
                margin: -0.2rem 0 1rem 0 !important;
            }

            .radio {
                margin: 0 0.5rem 0 0.1rem;
            }
        }
    }

    .amexfee-item-element {
        padding: 25px;
        background: lightyellow;
        margin-bottom: 15px;
    }

    .amexfee-title, .amexfee-label {
        font-weight: bold;
    }

    .amasty-extrafee-option {
        display: flex;
        flex-direction: column;
    }

    .opc-block-summary .minicart-items-wrapper {
        margin: -15px -15px 0 0
    }

    .logo {
        max-width: 100%;
        width: 100%;

        img {
            width: 300px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .page-main {
        background: none;
    }

    .checkout-container, #checkout {
        background: #FFF;
    }

    .form-login .hidden-fields > .field {
        width: 100%;
    }

    .actions-toolbar {
        margin-left: unset !important;
        margin-right: unset !important;
    }

    .form-login .hidden-fields .actions-toolbar .action.remind {
    }

    #email .actions-toolbar .primary {
        line-height: 15px;
    }

    #email .actions-toolbar .primary.continue {
        padding: 10px 20px;
    }

    .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
        line-height: 15px;
        padding: 10px 20px;
    }

    .opc-payment-additional.discount-code {
        margin-top: 15px;
    }

    .payment-method-content {
        padding-left: 0;
    }

    .tig_buckaroo_ideal .payment-method-billing-address,
    .tig_buckaroo_idealprocessing .payment-method-billing-address,
    .tig_buckaroo_creditcard .payment-method-billing-address,
    .tig_buckaroo_transfer .payment-method-billing-address,
    .tig_buckaroo_afterpay .payment-method-billing-address,
    .tig_buckaroo_afterpay2 .payment-method-billing-address,
    .tig_buckaroo_sepadirectdebit .payment-method-billing-address,
    .tig_buckaroo_giropay .payment-method-billing-address,
    .tig_buckaroo_payperemail .payment-method-billing-address,
    .tig_buckaroo_paymentguarantee .payment-method-billing-address {
        float: none;
        width: 100%;
        padding-right: 0%;
        box-sizing: border-box;
        clear: both;
    }

    .tig_buckaroo_ideal .payment-method-second-col,
    .tig_buckaroo_idealprocessing .payment-method-second-col,
    .tig_buckaroo_creditcard .payment-method-second-col,
    .tig_buckaroo_transfer .payment-method-second-col,
    .tig_buckaroo_afterpay .payment-method-second-col,
    .tig_buckaroo_afterpay2 .payment-method-second-col,
    .tig_buckaroo_sepadirectdebit .payment-method-second-col,
    .tig_buckaroo_giropay .payment-method-second-col,
    .tig_buckaroo_payperemail .payment-method-second-col,
    .tig_buckaroo_paymentguarantee .payment-method-second-col {
        float: none;
        width: 100%;
        padding-right: 0%;
        box-sizing: border-box;

        .fieldset.payment legend.label {
            width: 100%;
            float: none;
            text-align: left;
        }
    }
}

.checkout-onepage-success, .checkout-cart-index, .customer-account-index {
    .page-main {
        margin-top: 40px; // compensate breadcrumbs
    }

    .page-title-wrapper {
        padding: 4rem 0;

        h1 {
            margin: 0;
        }
    }
}


.checkout-index-index {
    .actions-toolbar {
        .action {
            border-radius: 3px;

            font-weight: normal;
            padding: 0 15px;
            font-size: 14px;
        }

        .secondary {
            .action, .action:hover {
                text-transform: none !important;
                background-color: unset !important;
                background: none !important;
                border: none !important;
                color: $bs-green;
            }
        }
    }
}

// Firecheckout edits
.firecheckout {

    .swissup-checkout-fields {
        .step-title {
            display: none !important;
        }
    }

    .field.swissup-checkout-fields__field {
        display: flex;
        flex-direction: column;

        label {
            width: 100% !important;
            text-align: left !important;
            margin-bottom: 0.5rem;

            span {
                width: 100% !important;
            }
        }

        .control {
            width: 100% !important;
        }
    }

    #checkout .opc-wrapper {
        margin: 0;
        padding: 0;
    }

    .checkout-billing-address {
        div.billing-address-details {
            padding: 0;
        }
    }

    .opc-block-summary {
        .minicart-items-wrapper {
            max-height: 30vh !important;
            margin: 0 -15px 0 0 !important;
            padding: 0 20px 0 0 !important;
        }

        .product-item .price {
            font-size: 1.4rem !important;
            font-weight: 400;
        }

        // Fix for dropshipment multiplying when moved in custom.js swissup (see custom.js file)
        .table-totals {
            tbody {
                display: flex;
                flex-direction: column;

                tr {
                    display: flex;
                    justify-content: space-between;
                }


                .tr.totals.shipping {
                    order: 4;
                }

                .amexfee-totals-details {
                    order: 5;
                }

                .grand.totals {
                    order: 6;
                }

                .totals.charge {
                    order: 7;
                }
            }
        }
        // end dropshioment fix swissup

    }

    .opc-sidebar {
        .minicart-items .product-item-details {
            padding-left: 55px;

            .subtotal {
                min-width: 80px;
            }
        }
    }

    .amexfee-collapsible-block {
        display: none !important;
    }

    tr.totals.sub {
        border-bottom: 1px solid #f4f4f4;
        border-top: 1px solid #f4f4f4;
    }

    tr.grand.totals {
        border-top: 1px solid #f4f4f4;

        .mark, .amount {
            padding-top: 15px;
        }
    }
}
