header {
    .trustpilot-widget--container {
        display: none;

        @media screen and (min-width: $screen__m_min) {
            grid-area: trustpilot;
            display: flex;
            align-items: center;
            justify-content: center;

            img.trustpilot-logo {
                display: flex;
                height: 4rem;
            }

            iframe {
                height: 4.2rem !important; // override trust pilot height 20px
            }
        }
    }



    .toggle-mobile-menu {
        color: #fff;
        font-size: 30px;
        display: none;
        margin: 0 0 0 25px;
        cursor: pointer;

        @include max-screen($screen__s) {
            display: block;
            order: 3;
        }
    }

    &.page-header {
        position: relative;
        display: grid;
        grid-template-areas: 'nav-toggle search logo myaccount minicart';
        grid-template-columns: 5rem 5rem auto 5rem 5rem;
        padding: 1.2rem 1.6rem;
        z-index: 99;
        background: $bs-black;

        @media screen and (min-width: $screen__m_min) {
            display: block;
            padding: 0;
            background: initial;
        }

        .panel.wrapper {
            display: contents;

            @media screen and (min-width: $screen__m_min) {
                display: block;
                background: #161415;
            }
        }
    }

    .panel.header {
        display: contents;

        @media screen and (min-width: $screen__m_min) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .minicart-wrapper {
            z-index: 5;
            order: 2;
            grid-area: minicart;

            @include min-screen($screen__m) {
                order: 9;
            }

            .showcart {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                padding: {
                    top: $header-panel-padding-y;
                    bottom: $header-panel-padding-y;
                }

                @include min-screen($screen__m) {
                    margin-left: 2.4rem;
                    background-color: $bs-red;
                }

                &:before {
                    display: none;
                }

                .icon-orderlist {
                    width: 3.2rem;
                    height: 3.2rem;

                    @include min-screen($screen__m) {
                        margin-right: 15px;
                        margin-left: 10px;
                        width: 3rem;
                        height: 3rem;
                    }

                    use {
                        fill: #fff;
                    }

                    @include max-screen($screen__m) {
                        //width: 25px;
                        //height: 28px;
                        //margin-right: 0;
                        //margin-left: 10px;
                    }
                }

                .cart-info {
                    position: absolute;
                    left: initial;
                    right: 0;
                    background-color: $bs-green;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 99rem;
                    transform: translateY(-25%) translateX(50%);
                    top: .4rem;
                    padding: .1rem;

                    @media screen and (min-width: $screen__m_min) {
                        transform: translateY(-35%) translateX(-25%);
                        top: 1rem;
                        padding: .2rem;
                    }

                    .counter.qty {
                        display: block;
                        background: none;
                        height: auto;
                        line-height: inherit;
                        font-size: 12px;
                        margin: 0;
                        padding: 0;

                        @media screen and (min-width: $screen__m_min) {

                        }
                    }
                }
            }
        }

        @include max-screen($screen__m) {
            .login-register-block {
                margin-right: 0;
                margin-left: auto;
                z-index: 5;
                order: 3;
                display: flex;
                align-items: center;
            }

            .toggle-mobile-menu {
                display: block;
                order: 2;
                cursor: pointer;
                justify-self: flex-end;
                width: 20%;
                text-align: left;
                margin: 0;
                z-index: 5;
            }

            .logo {
                margin: 0;
                display: flex;
                justify-content: center;
                order: 1;

                @include max-screen($screen__s) {
                    flex-grow: 1;
                    max-width: 100%;
                }
            }
        }
    }

    .header.content {
        display: contents;

        @media screen and (min-width: $screen__m_min) {
            display: grid;
            grid-template-areas: 'logo search trustpilot';
            grid-template-columns: 22rem auto 22rem;
            position: relative;
            padding: 0;
            max-width: 100%;
            padding: {
                left: calc((100vw - 1280px) / 2);
                right: calc((100vw - 1280px) / 2);
            };
            background: #fff;
            align-items: center;

            .logo {
                padding: 2rem;
                box-sizing: border-box;
                // margin-top: -3rem; only on hardrace
            }
        }
        @media screen and (min-width: $screen__l_min) {
            grid-template-columns: 35rem auto 20rem;
        }


        .navigation {
            display: flex;
            width: auto;
            background: none;
            margin-left: 0;
            height: 65px;
            flex-basis: 100px;
            flex-grow: 1;
            z-index: 1;

            @include max-screen($screen__s) {
                display: none;
                height: auto;

                &.open {
                    display: flex;
                }
            }

            ul {
                display: flex;
                align-items: stretch;
                padding: 0;

                @include max-screen($screen__l) {
                    width: 100%;
                }

                @include max-screen($screen__s) {
                    flex-direction: column;
                }
            }

            li.level0 {
                display: flex;
                align-items: center;
                margin: 0 -2px 0 0;
                border: none;

                @include max-screen($screen__s) {
                    margin: 0;
                    min-height: 50px;
                    border-bottom: 1px solid #d1d1d1;
                    border-top: 0;
                }

                &:before {
                    content: '';
                    height: 50px;
                    width: 2px;
                    margin-right: -2px;
                    background: $bs-light-gray;

                    @include max-screen($screen__m) {
                        display: none;
                    }
                }

                @include max-screen($screen__m) {
                    align-items: flex-start;

                    > a {
                        justify-content: flex-start !important;
                    }
                }

                &.has-active,
                &.active {
                    > .level-top {
                        border-bottom: 0;
                    }
                }

                > a {
                    display: flex;
                    align-items: center;
                    height: 65px;
                    padding: 0 30px;
                    line-height: 20px;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: #000;
                    font-weight: normal;
                    border: none;

                    &.ui-state-active,
                    &.ui-state-focus {
                        background: $bs-light-gray;
                    }

                    .icon-wrapper {
                        margin-right: 10px;
                        max-width: 38px;
                        height: 38px;
                        display: flex;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                    }

                    @include max-screen(1180px) {
                        padding: 0 18px;
                        font-size: 14px;
                    }

                    @include max-screen($screen__l) {
                        padding: 0 20px;
                        font-size: 14px;
                    }

                    @include max-screen($screen__m) {
                        padding: 0 15px;
                        font-size: 12px;
                    }

                    @include max-screen($screen__s) {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        font-size: 16px;
                    }
                }

                ul.submenu {
                    display: none;
                    flex-direction: column;
                    align-items: stretch;
                    padding: 0;
                    position: absolute;
                    z-index: 1000;

                    @include max-screen($screen__m) {
                        display: none !important;
                    }

                    li {
                        padding: 0;
                        margin: 0;

                        &.active > a {
                            border: none;
                        }

                        &:not(:last-of-type) {
                            border-bottom: 2px solid $bs-light-gray;
                        }
                    }

                    a {
                        font-size: 14px;
                        white-space: nowrap;
                        height: 56px;
                        line-height: 56px;
                        padding: 0 20px;
                        text-transform: uppercase;
                        font-weight: normal;
                    }
                }
            }
        }

        @include max-screen($screen__m) {
            .navigation .parent .level-top:after {
                display: none;
            }
        }


        .search-switcher {
            margin-left: auto;
            display: flex;

            @include max-screen($screen__l) {
                display: none;
            }

            a {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                margin-top: 7px;
                padding: 15px 20px 0 20px;
                background: $bs-light-gray;
                color: #333;
                margin-left: 6px;
                font-size: 10px;
                text-transform: uppercase;

                .icon-wrapper {
                    margin: 0 auto;
                    max-width: 55px;
                    height: 20px;
                    margin-bottom: 3px;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    use {
                        fill: #333;
                    }
                }

                &.active {
                    background: $bs-dark-gray;
                    color: #fff;

                    svg use {
                        fill: #fff;
                    }
                }
            }
        }

        .search-switcher-mobile {
            display: none;
        }

        @include max-screen($screen__l) {
            .search-switcher-mobile {
                display: block;

                .search-switcher {
                    display: flex;

                    a {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 8px;
                        width: 50%;
                        border-radius: 0;
                    }
                }
            }
        }

        .header-contents-wrapper {
            background: #fff;
            flex-basis: 100%;
            padding: 0 20px;
            z-index: 99;

            @include max-screen($screen__m) {
                padding: 0;
            }

            .header-contents-inner {
                max-width: 1280px;
                margin: 0 auto;
                display: flex;
            }
        }
    }

}

//.searchautocomplete__autocomplete {
//    .index-title {
//        display: none;
//    }
//
//    .searchautocomplete__index-magento_catalog_product {
//        ul {
//            padding: 0;
//            list-style: none;
//
//            li {
//                padding: 20px;
//                display: flex;
//                align-items: center;
//
//                &:hover {
//                    background: #cccbca;
//                }
//
//                .description {
//                    display: none;
//                }
//
//                img {
//                    margin-right: 20px;
//                }
//
//                .title {
//                    margin-left: 0;
//                    margin-right: auto;
//                }
//
//
//            }
//        }
//    }
//}

.block-minicart {
    @include max-screen($screen__s) {
        right: -20px !important;
    }
}

//.searchautocomplete__autocomplete {
//    display: none;
//    box-shadow: 0 2px 2px 2px #CCC;
//
//    &._active {
//        display: block;
//    }
//
//    .index-title {
//        display: none;
//    }
//
//    ul {
//        padding: 0;
//        list-style: none;
//
//        li {
//            padding: 20px;
//            display: flex;
//            align-items: center;
//            margin: 0;
//
//            &:hover {
//                background: #cccbca;
//            }
//
//            cursor: pointer;
//
//            .title {
//                margin-bottom: 0;
//            }
//        }
//    }
//
//    .searchautocomplete__index-magento_catalog_product {
//        ul {
//            padding: 0;
//            list-style: none;
//
//            li {
//                padding: 20px;
//                display: flex;
//                align-items: center;
//                margin: 0;
//
//                &:hover {
//                    background: #cccbca;
//                }
//
//                .description {
//                    display: none;
//                }
//
//                img {
//                    margin: -10px;
//                    margin-right: 20px;
//                    max-height: 50px;
//                    max-width: 50px;
//                }
//
//                .title {
//                    margin-left: 0;
//                    margin-right: auto;
//                    color: #000;
//                    text-transform: uppercase;
//                    font-size: 16px;
//                    margin-bottom: 0;
//                }
//            }
//        }
//    }
//}

/* IE11 */
@media all and (-ms-high-contrast: none) {
    header .header {
        &.content {
            .search-switcher {
                a {
                    &.active {
                        fill: #fff;
                    }
                }
            }

            .navigation {
                li.level0 {
                    > a {
                        .icon-wrapper {
                            min-width: 38px;

                            svg {
                                min-width: 38px;
                                min-height: 30px;
                            }
                        }
                    }
                }
            }

            //.fitments-search-wrapper {
            //    .search-fitments, #search_mini_form {
            //        margin-top: 20px;
            //
            //        .fitments-container {
            //            .fitment-menus {
            //                .fitment-menu {
            //                    &.show {
            //                        flex-direction: row;
            //                    }
            //                }
            //            }
            //        }
            //    }
            //}
        }
    }

    .ie11 {
        .page-wrapper {
            height: auto;
        }
    }
}

@media (min-width: $screen__m_min) {
    .navigation {
        ul.level0 {
            //display: block !important;

            li.level1.parent {
                > a:after {
                    position: absolute;
                    right: 15px;
                    max-height: 100%;
                    transition: transform 200ms;
                    transform-origin: center;
                    content: url('../images/chevron-right.svg');
                    width: .8rem;

                    @media screen and (min-width: $screen__m_min) {
                        content: url('../images/chevron-down.svg');
                        position: initial;
                        display: inline-block;
                        width: 1rem;
                        right: initial;
                        line-height: 2rem;
                        margin-top: .2rem;
                        margin-left: .8rem;
                    }
                }
            }
        }
    }
}


.navigation {
    ul.level2 {
        @media(min-width: $screen__m_min) {
            display: none !important;
        }
    }
}
