$prefix: 'app-';

$columns: 12;
$column-gap: 2rem;

$app-grid-breakpoints: (
    min-xxs : $screen__xxs_min,
    min-xs  : $screen__xs_min,
    min-s   : $screen__s_min,
    min-m   : $screen__m_min,
    min-l   : $screen__l_min
);

$spacing-properties: (
    p  : 'padding',
    pl : 'padding-left',
    pr : 'padding-right',
    pt : 'padding-top',
    pb : 'padding-bottom',
    m  : 'margin',
    ml : 'margin-left',
    mr : 'margin-right',
    mt : 'margin-top',
    mb : 'margin-bottom',
);

$spacing-steps: (
    0,
    1,
    2,
    4,
    6,
    8,
    10,
    12,
    16,
    20,
);

$border-widths: (
    0,
    1,
    2
);

$border-directions: (
    border  : 'border',
    border-l : 'border-left',
    border-r : 'border-right',
    border-t : 'border-top',
    border-b : 'border-bottom',
);

$display-properties: (
    'none',
    'block',
    'inline-block',
    'inline',
    'flex'
);

$colors: (
    yellow  : $bs-yellow,
    green   : $bs-green,
    lt-green: $bs-lt-green,
    red     : $bs-red,
    xlt-grey: $bs-xlt-gray,
    lt-grey : $bs-light-gray,
    mlt-grey: $bs-mlt-gray,
    md-grey : $bs-md-gray,
    dk-grey : $bs-dark-gray,
    xdk-grey: $bs-xdark-gray,
    black   : $bs-black,
    white   : #fff
)