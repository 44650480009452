//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

.block-search {
    grid-area: search;
    margin-top: $indent__s;
    z-index: 99;
    box-sizing: border-box;
    width: 100%;

    @include min-screen($screen__m) {
        margin-top: 0;
        padding: 1.6rem 1.2rem 1.6rem 2.4rem;
        z-index: 4;
    }

    @include min-screen($screen__l) {
        padding: 1.6rem 3.2rem 1.6rem 6.4rem;
    }

    .search-toggle {
        width: 2.4rem;
        height: 2.4rem;
        color: white;

        @include min-screen($screen__m) {
            display: none;
        }

        .search-icon {
            fill: white;
        }
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content      : $icon-search,
            $_icon-font-size         : 3.5rem,
            $_icon-font-line-height  : 3.3rem,
            $_icon-font-color        : $minicart-icons-color,
            $_icon-font-color-hover  : $minicart-icons-color-hover,
            $_icon-font-color-active : $minicart-icons-color-hover,
            $_icon-font-text-hide    : true
        );
        //display: inline-block;
        float: right;

        display: none;
        @include min-screen($screen__m) {
        }

        &.active {
            + .control {
                input {
                    //position: static;
                }
            }
        }
    }

    .block-content {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        transform: translateY(100%);

        @include min-screen($screen__m) {
            display: block;
            position: initial;
            left: initial;
            bottom: initial;
            width: 100%;
            background-color: initial;
            transform: none;
        }
    }

    &.active {
        > .block-content {
            display: block;
        }
    }

    .search-toggle-container {
        &.active {
            .ui-dialog,
            .block-content {
                display: block !important;
            }
        }

        .ui-dialog,
        .block-content {
            @media screen and (min-width: $screen__m_min){
                display: block !important;
            }
        }
    }

    .field.search {
        position: relative;
    }

    .action.search {
        display: none;

        @include min-screen($screen__m) {
            @include lib-button-icon(
                    $_icon-font-content   : $icon-search,
                    $_icon-font-text-hide : true,
                    $_icon-font-color     : $header-icons-color
            );
            @include lib-button-reset();
            padding: .7rem 0;
            position: absolute;
            right: 1rem;
            top: 0;
            z-index: 1;
        }

        &:focus {
            &:before {
                @include min-screen($screen__m) {
                    @include lib-css(color, $color-gray20);
                }
            }
        }
    }

    .control {
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
        position: relative;

        @include min-screen($screen__m) {
            border-top: 0;
            margin: 0;
            padding: 0;
        }

        > .actions {
            position: absolute;
            top: 0;
            right: 0;
            // help
        }

        #search+.actions {
            > .action.search {
                transform: translateY(-100%);
            }
        }
    }

    input {
        margin: 0 0;

        @include min-screen($screen__m) {
            @include lib-input-placeholder($form-element-input-placeholder__color);
            margin: 0;
            padding-right: 3.5rem;
            position: static;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .nested {
        display: none;

        @include min-screen($screen__m) {
            display: block;
            padding-top: .5rem;
            position: absolute;
        }
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    top: 6.4rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    overflow-y: auto;
    max-height: 90vh;
    scrollbar-color: #adadad rgba(255, 255, 255, 0.2);
    scrollbar-width: thin;
    background-color: white;
    border: 1px solid $bs-mlt-gray;


    @include min-screen($screen__m_min) {
        margin-top: 0;
    }

    &::-webkit-scrollbar {
        background: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: .4rem;
        background: #adadad;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 .5rem #ffffff;
    }

    &::-webkit-scrollbar {
        width: .4rem;
    }

    &::-webkit-scrollbar-thumb {
        width: .4rem;
    }

    &::-webkit-scrollbar-track {
        width: .4rem;
    }

    &.amsearch-clone-position {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 999;
        box-shadow: 0 .4rem .6rem rgba(61, 62, 66, 0.05), 0 .4rem 2.5rem rgba(75, 76, 83, 0.15);
    }

    .amsearch-results {
        padding: 1.6rem;
        box-sizing: border-box;

        @media (min-width: $screen__m_min) {
            width: 100%;
            overflow: auto;
        }
    }
}

#search {
    //box-shadow: none;
    //border-color: $bs-red;
    //border-width: .1rem;
    transform: scale(1);
    transition: transform, box-shadow, border 750ms;

    &.focus {
        transform: scale(1.01);
        //box-shadow: 0 0 .5rem $bs-red;
        border-color: $bs-red;
        border-width: .1rem;
        transition: transform, box-shadow, border 750ms;
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 2.5rem;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: .6rem;
                        width: 2.5rem;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 3.2rem;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 4.5rem;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: .6rem;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}
