
    .mst-search__misc-recommendations {
        display:   flex;
        flex-wrap: wrap;

        dt {
            margin-right: 2.0rem;
        }

        dd {
            margin-right: 2.0rem;

            .count {
                color: #7d7d7d;
            }
        }
    }

