.widget.content-with-background {
  overflow: hidden;

  h1 {
    margin: 15px 0 0 0;
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }

  h2, .h2 {
    margin: 5px 0 10px 0;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
  }

  h3 {
    margin: 20px 0 10px 0;
    font-size: 17px;
    color: #fff;
    font-weight: 300;
  }

  a {
    color: #ccc;
    text-decoration: underline;
  }
}
