//
//  Amasty Search Component Styles
//  ____________________________________________

//
//  Imports
//  --------------------------------------------

@import '../_mixins';

//
//  Common
//  --------------------------------------------

@media all {
    .amqorder-search-block {

        @include amqorder-flex(none, center);

        position: relative;
        z-index: 1;
        flex-basis: 100%;
        padding: $amqorder__indent__l $amqorder__indent__m 35px;
        background: #fff;


        .amqorder-search {
            position: relative;
            z-index: 9;
            flex-basis: 100%;
        }

        .amqorder-input.-search {
            padding: 0 $amqorder__indent__l * 2;
            border: 1px solid $bs-md-gray;
            border-bottom: 0;
        }

        .amqorder-button.-remove {
            right: 0;
            padding: 0 $amqorder__indent;
            height: 100%;
        }

        .amqorder-button.-search {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 $amqorder__indent;
            height: 100%;
        }

        .amqorder-button.-remove {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 $amqorder__indent;
            height: 100%;
        }

        .amqorder-button.-empty {
            position: absolute;
            right: 0;
            bottom: 0;
            display: inline-block;
            margin: 16px;
            padding: 0 $amqorder__indent__m;
            min-width: auto;
            height: 34px;
            font-size: 12px;
        }

        .amqorder-message-block {
            @include amqorder-flex(center, center);

            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            z-index: 9;
            padding: $amqorder__indent__xl * 2 $amqorder__indent;
            background: #fff;
            color: #000;
            list-style: none;
        }

        .amqorder-items-block {
            @include amqorder-scrollbar();
            @include amqorder-transition();

            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            z-index: 9;
            display: block;
            margin: 0;
            padding: 0;
            max-height: 605px;
            border-radius: 0 0 6px 6px;
            background: #fff;
            box-shadow: 0 45px 25px -25px rgba(0,0,0,0.5);
            color: #000;
            list-style: none;
            border: 1px solid $bs-md-gray;
            border-top: none;
        }

        .amqorder-item {
            @include amqorder-transition();

            position: relative;
            display: block;
            box-sizing: border-box;
            margin: 0;
            padding: $amqorder__indent__m;
            clear: both;


            &:not(:last-child) {
                border-bottom: 1px solid #ebeef5;
            }

            &:hover {
                background: $bs-light-gray;
            }
        }

        .amqorder-name {
            display: inline-block;
            overflow: hidden;
            margin: 0 0 0 $amqorder__indent__m;
            max-height: $amqorder__indent__l * 2;
            width: 65%;
            word-break: break-all;
        }

        .amqorder-image {
            display: inline-block;
            float: left;
            max-width: 29%;
            max-width: 70px;
            height: 70px;
            background: #fff;
            object-fit: contain;
        }

        .amqorder-sku {
            display: inline-block;
            overflow: hidden;
            margin: $amqorder__indent / 2 0 0 $amqorder__indent__m;
            width: 65%;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .amqorder-price {
            display: inline-block;
            overflow: hidden;
            margin: $amqorder__indent 0 0 $amqorder__indent__m;
            text-overflow: ellipsis;
        }

        .amqorder-qty-block {
            display: none;
            justify-content: center;
        }

        .amqorder-qty-block .amqorder-msg.-error {
            top: 100%;
        }
    }
}

//
//  Tablet
//  --------------------------------------------

@mixin media-width($extremum, $break) {
    @if ($extremum = 'min') and ($break = $screen__m) {
        .amqorder-search-block {

            padding: $amqorder__indent__m * 2 $amqorder__indent__m 35px;


            .amqorder-qty-block {
                @include amqorder-flex();
            }
        }
    }
}

//
//  Mobile
//  --------------------------------------------

@mixin media-width($extremum, $break) {
    @if ($extremum = 'min') and ($break = $screen__s) {
        .amqorder-search-block .amqorder-items-block {
            .amqorder-item {
                @include amqorder-flex(space-between, center);

                padding: $amqorder__indent__m 36px;
                max-height: none;
            }

            .amqorder-button.-empty {
                position: initial;
                flex-grow: 1;
                margin: 0 0 0 $amqorder__indent;
            }

            .amqorder-image {
                float: none;
                width: 70px;
                height: 70px;
            }

            .amqorder-name {
                @include amqorder-flex();

                margin: 0 $amqorder__indent__l;
                max-height: 60px;
                width: 30%;
            }

            .amqorder-qty-block {
                margin: 0 $amqorder__indent;
                width: 20%;
            }

            .amqorder-sku {
                overflow: hidden;
                margin: 0 $amqorder__indent;
                width: 378px;
            }

            .amqorder-price {
                flex-basis: auto;
                overflow: initial;
                margin: 0 $amqorder__indent;
                width: 25%;
            }
        }
    }
}
