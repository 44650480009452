//
//  Product Grids
//  _____________________________________________

// brakesshop/listings as base!
// only hardrace adjustments here!

.products-grid {
    .product-items {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-inline-start: 0; // wishlist
        overflow: hidden; // added hardrace
        box-sizing: border-box; // added hardrace

        @media(min-width: $screen__xs_min) {
            display: grid;
            grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
            grid-gap: 1rem;
        }

        @media(min-width: $screen__m_min) {
            display: grid;
            grid-template-columns: calc(33% - 0.66rem) calc(33% - 0.66rem) calc(33% - 0.66rem);
            grid-gap: 1rem;
        }

        @media(min-width: 849px) { // didn't add variable here to avoid major breakpoint issues
            display: grid;
            grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
            grid-gap: 1rem;
        }

        @media(min-width: $screen__l_min) {
            display: grid;
            grid-template-columns: calc(33% - 0.66rem) calc(33% - 0.66rem) calc(33% - 0.66rem);
            grid-gap: 1rem;
        }

        @media(min-width: 1200px) { // didn't add variable here to avoid major breakpoint issues
            display: grid;
            grid-template-columns: calc(25% - 0.75rem) calc(25% - 0.75rem) calc(25% - 0.75rem) calc(25% - 0.75rem);
            grid-gap: 1rem;
        }

        .product-item {

            .product-wrapper {
                min-height: 5rem;

                .product-item-details {
                    display: none;
                    margin: 0;
                }

                .price-final_price .price {
                    font-size: 1.6rem;
                }

                .old-price .price {
                    font-size: 1.4rem;
                }

                .action.primary {
                    padding: 7px 15px;
                }

                .product-image-container {

                    .product-image-wrapper {

                        .product-image-photo {
                            width: 100%;
                        }
                    }
                }

                &.media-description {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .product.details.product-item-details {

                        .product-item-inner {
                        }
                    }
                }

                &.price-action {
                    .price-box {
                        display: flex;
                        flex-direction: column;

                        .old-price {
                            margin-right: 0;
                        }
                    }
                }
            }

            .stock-addto {
                min-height: 3rem;
                position: relative;
                align-items: center;
                justify-content: space-between;

                .product-stock-addto {
                    position: relative;
                }
            }
        }
    }

    .action.tocompare {
        display: none !important;
    }

    label.discount-label {
        display: block;
        z-index: 1;
        background: red;
        color: white;
        padding: 0.5rem 1rem;
        font-weight: 600;
        font-size: 1.6rem;
        right: 0;
        position: absolute;
    }
}

