$spare-grid-column-gap: 1.5rem;
$spare-grid-row-gap: 1.5rem;
// set number of columns below in $spare-grid-columns variable per screen size


@mixin columns-size( $spare-grid-columns , $spare-grid-column-gap ) {
    display: grid;
    grid-column-gap: $spare-grid-column-gap;
    grid-row-gap: $spare-grid-row-gap;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    $grid-compensation: ($spare-grid-columns - 1) * $spare-grid-column-gap  / $spare-grid-columns;
    $number-of-column-gaps: calc(#{$spare-grid-columns} - 1);
    $column-size: calc(calc(100% / #{$spare-grid-columns}) - #{$grid-compensation});
    grid-template-columns: repeat( #{$spare-grid-columns}, #{$column-size} );
}

.spare-parts-carousel-items {

    // supersmall
    $spare-grid-columns: 1;
    @include columns-size($spare-grid-columns, $spare-grid-column-gap);

    // mobile
    @media (min-width: $screen__xs_min) {
    $spare-grid-columns: 2;
    @include columns-size($spare-grid-columns, $spare-grid-column-gap);
}

    // tablet
    @media (min-width: $screen__m_min) {
        $spare-grid-columns: 3;
        @include columns-size( $spare-grid-columns , $spare-grid-column-gap );
    }

    // desktop
    @media (min-width: $screen__l_min) {
        $spare-grid-columns: 4;
        @include columns-size( $spare-grid-columns , $spare-grid-column-gap );
    }

    .carousel-item {
        // layout
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: white;
        padding: 1.5rem;
        text-align: center;

        box-sizing: border-box;
        transition-duration: 0.1s;
        border: 1px solid transparent;


        &:hover {
            box-shadow: 0 4px 10px -9px #000;
            background: white;
            border: 1px solid #ccc;
        }

        .stock {
            margin: 1.5rem 0;

            &, * {
                font-size: 1.2rem;
            }
        }

        // typography
        &, h3 {
            color: #000;
            font-size: 1.4rem;
            font-weight: 400;
            text-decoration: none;
            text-transform: uppercase;
        }

        .price {
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        .button {
            height: unset;
            background: $bs-light-gray;
            color: black;
            font-size: 1.5rem;
        }

    }
}

